.b-theme-info:before {
  content: '{"name":"Stockholm"}'; }

.b-popup-close:before {
  content: '\f00d' !important; }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(fonts/fa-solid-900.eot);
  src: url(fonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(fonts/fa-solid-900.woff2) format("woff2"), url(fonts/fa-solid-900.woff) format("woff"), url(fonts/fa-solid-900.ttf) format("truetype"), url(fonts/fa-solid-900.svg#fontawesome) format("svg"); }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.b-fa-500px:before {
  content: "\f26e"; }

.b-fa-accessible-icon:before {
  content: "\f368"; }

.b-fa-accusoft:before {
  content: "\f369"; }

.b-fa-acquisitions-incorporated:before {
  content: "\f6af"; }

.b-fa-ad:before {
  content: "\f641"; }

.b-fa-address-book:before {
  content: "\f2b9"; }

.b-fa-address-card:before {
  content: "\f2bb"; }

.b-fa-adjust:before {
  content: "\f042"; }

.b-fa-adn:before {
  content: "\f170"; }

.b-fa-adobe:before {
  content: "\f778"; }

.b-fa-adversal:before {
  content: "\f36a"; }

.b-fa-affiliatetheme:before {
  content: "\f36b"; }

.b-fa-air-freshener:before {
  content: "\f5d0"; }

.b-fa-airbnb:before {
  content: "\f834"; }

.b-fa-algolia:before {
  content: "\f36c"; }

.b-fa-align-center:before {
  content: "\f037"; }

.b-fa-align-justify:before {
  content: "\f039"; }

.b-fa-align-left:before {
  content: "\f036"; }

.b-fa-align-right:before {
  content: "\f038"; }

.b-fa-alipay:before {
  content: "\f642"; }

.b-fa-allergies:before {
  content: "\f461"; }

.b-fa-amazon:before {
  content: "\f270"; }

.b-fa-amazon-pay:before {
  content: "\f42c"; }

.b-fa-ambulance:before {
  content: "\f0f9"; }

.b-fa-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.b-fa-amilia:before {
  content: "\f36d"; }

.b-fa-anchor:before {
  content: "\f13d"; }

.b-fa-android:before {
  content: "\f17b"; }

.b-fa-angellist:before {
  content: "\f209"; }

.b-fa-angle-double-down:before {
  content: "\f103"; }

.b-fa-angle-double-left:before {
  content: "\f100"; }

.b-fa-angle-double-right:before {
  content: "\f101"; }

.b-fa-angle-double-up:before {
  content: "\f102"; }

.b-fa-angle-down:before, .b-icon-tree-collapse:before {
  content: "\f107"; }

.b-fa-angle-left:before, .b-icon-angle-left:before, .b-icon-prev:before {
  content: "\f104"; }

.b-fa-angle-right:before, .b-icon-angle-right:before, .b-icon-tree-expand:before, .b-icon-next:before {
  content: "\f105"; }

.b-fa-angle-up:before {
  content: "\f106"; }

.b-fa-angry:before {
  content: "\f556"; }

.b-fa-angrycreative:before {
  content: "\f36e"; }

.b-fa-angular:before {
  content: "\f420"; }

.b-fa-ankh:before {
  content: "\f644"; }

.b-fa-app-store:before {
  content: "\f36f"; }

.b-fa-app-store-ios:before {
  content: "\f370"; }

.b-fa-apper:before {
  content: "\f371"; }

.b-fa-apple:before {
  content: "\f179"; }

.b-fa-apple-alt:before {
  content: "\f5d1"; }

.b-fa-apple-pay:before {
  content: "\f415"; }

.b-fa-archive:before {
  content: "\f187"; }

.b-fa-archway:before {
  content: "\f557"; }

.b-fa-arrow-alt-circle-down:before {
  content: "\f358"; }

.b-fa-arrow-alt-circle-left:before {
  content: "\f359"; }

.b-fa-arrow-alt-circle-right:before {
  content: "\f35a"; }

.b-fa-arrow-alt-circle-up:before {
  content: "\f35b"; }

.b-fa-arrow-circle-down:before {
  content: "\f0ab"; }

.b-fa-arrow-circle-left:before, .b-icon-column-move-left:before {
  content: "\f0a8"; }

.b-fa-arrow-circle-right:before, .b-icon-column-move-right:before {
  content: "\f0a9"; }

.b-fa-arrow-circle-up:before {
  content: "\f0aa"; }

.b-fa-arrow-down:before, .b-icon-down:before, .b-icon-sort-desc:before {
  content: "\f063"; }

.b-fa-arrow-left:before {
  content: "\f060"; }

.b-fa-arrow-right:before, .b-icon-right:before, .b-row-reordering .b-row-reordering-target-parent .b-tree-cell::before {
  content: "\f061"; }

.b-fa-arrow-up:before, .b-icon-up:before, .b-icon-sort-asc:before, .b-gridbase.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header.b-sortable.b-depth-0:hover:not(.b-sort):not(.b-group) .b-grid-header-text::after,
.b-gridbase.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header.b-sortable.b-depth-0:focus:not(.b-sort):not(.b-group) .b-grid-header-text::after, .b-icon-sorted-asc:before, .b-gridbase.b-sort .b-grid-header.b-sort .b-grid-header-text::after {
  content: "\f062"; }

.b-fa-arrows-alt:before, .b-icon-fullscreen:before {
  content: "\f0b2"; }

.b-fa-arrows-alt-h:before, .b-icon-resize-horizontal:before, .b-gridbase.b-columnresize.b-touch.b-column-resizing .b-grid-header.b-resizing::before, .b-touch .b-moving .b-grid-splitter-main::before {
  content: "\f337"; }

.b-fa-arrows-alt-v:before {
  content: "\f338"; }

.b-fa-artstation:before {
  content: "\f77a"; }

.b-fa-assistive-listening-systems:before {
  content: "\f2a2"; }

.b-fa-asterisk:before {
  content: "\f069"; }

.b-fa-asymmetrik:before {
  content: "\f372"; }

.b-fa-at:before {
  content: "\f1fa"; }

.b-fa-atlas:before {
  content: "\f558"; }

.b-fa-atlassian:before {
  content: "\f77b"; }

.b-fa-atom:before {
  content: "\f5d2"; }

.b-fa-audible:before {
  content: "\f373"; }

.b-fa-audio-description:before {
  content: "\f29e"; }

.b-fa-autoprefixer:before {
  content: "\f41c"; }

.b-fa-avianex:before {
  content: "\f374"; }

.b-fa-aviato:before {
  content: "\f421"; }

.b-fa-award:before {
  content: "\f559"; }

.b-fa-aws:before {
  content: "\f375"; }

.b-fa-baby:before {
  content: "\f77c"; }

.b-fa-baby-carriage:before {
  content: "\f77d"; }

.b-fa-backspace:before {
  content: "\f55a"; }

.b-fa-backward:before {
  content: "\f04a"; }

.b-fa-bacon:before {
  content: "\f7e5"; }

.b-fa-bahai:before {
  content: "\f666"; }

.b-fa-balance-scale:before {
  content: "\f24e"; }

.b-fa-balance-scale-left:before {
  content: "\f515"; }

.b-fa-balance-scale-right:before {
  content: "\f516"; }

.b-fa-ban:before, .b-icon-invalid:before {
  content: "\f05e"; }

.b-fa-band-aid:before {
  content: "\f462"; }

.b-fa-bandcamp:before {
  content: "\f2d5"; }

.b-fa-barcode:before {
  content: "\f02a"; }

.b-fa-bars:before {
  content: "\f0c9"; }

.b-fa-baseball-ball:before {
  content: "\f433"; }

.b-fa-basketball-ball:before {
  content: "\f434"; }

.b-fa-bath:before {
  content: "\f2cd"; }

.b-fa-battery-empty:before {
  content: "\f244"; }

.b-fa-battery-full:before {
  content: "\f240"; }

.b-fa-battery-half:before {
  content: "\f242"; }

.b-fa-battery-quarter:before {
  content: "\f243"; }

.b-fa-battery-three-quarters:before {
  content: "\f241"; }

.b-fa-battle-net:before {
  content: "\f835"; }

.b-fa-bed:before {
  content: "\f236"; }

.b-fa-beer:before {
  content: "\f0fc"; }

.b-fa-behance:before {
  content: "\f1b4"; }

.b-fa-behance-square:before {
  content: "\f1b5"; }

.b-fa-bell:before {
  content: "\f0f3"; }

.b-fa-bell-slash:before {
  content: "\f1f6"; }

.b-fa-bezier-curve:before {
  content: "\f55b"; }

.b-fa-bible:before {
  content: "\f647"; }

.b-fa-bicycle:before {
  content: "\f206"; }

.b-fa-biking:before {
  content: "\f84a"; }

.b-fa-bimobject:before {
  content: "\f378"; }

.b-fa-binoculars:before {
  content: "\f1e5"; }

.b-fa-biohazard:before {
  content: "\f780"; }

.b-fa-birthday-cake:before {
  content: "\f1fd"; }

.b-fa-bitbucket:before {
  content: "\f171"; }

.b-fa-bitcoin:before {
  content: "\f379"; }

.b-fa-bity:before {
  content: "\f37a"; }

.b-fa-black-tie:before {
  content: "\f27e"; }

.b-fa-blackberry:before {
  content: "\f37b"; }

.b-fa-blender:before {
  content: "\f517"; }

.b-fa-blender-phone:before {
  content: "\f6b6"; }

.b-fa-blind:before {
  content: "\f29d"; }

.b-fa-blog:before {
  content: "\f781"; }

.b-fa-blogger:before {
  content: "\f37c"; }

.b-fa-blogger-b:before {
  content: "\f37d"; }

.b-fa-bluetooth:before {
  content: "\f293"; }

.b-fa-bluetooth-b:before {
  content: "\f294"; }

.b-fa-bold:before {
  content: "\f032"; }

.b-fa-bolt:before {
  content: "\f0e7"; }

.b-fa-bomb:before {
  content: "\f1e2"; }

.b-fa-bone:before {
  content: "\f5d7"; }

.b-fa-bong:before {
  content: "\f55c"; }

.b-fa-book:before {
  content: "\f02d"; }

.b-fa-book-dead:before {
  content: "\f6b7"; }

.b-fa-book-medical:before {
  content: "\f7e6"; }

.b-fa-book-open:before {
  content: "\f518"; }

.b-fa-book-reader:before {
  content: "\f5da"; }

.b-fa-bookmark:before {
  content: "\f02e"; }

.b-fa-bootstrap:before {
  content: "\f836"; }

.b-fa-border-all:before {
  content: "\f84c"; }

.b-fa-border-none:before {
  content: "\f850"; }

.b-fa-border-style:before {
  content: "\f853"; }

.b-fa-bowling-ball:before {
  content: "\f436"; }

.b-fa-box:before {
  content: "\f466"; }

.b-fa-box-open:before {
  content: "\f49e"; }

.b-fa-box-tissue:before {
  content: "\f95b"; }

.b-fa-boxes:before {
  content: "\f468"; }

.b-fa-braille:before {
  content: "\f2a1"; }

.b-fa-brain:before {
  content: "\f5dc"; }

.b-fa-bread-slice:before {
  content: "\f7ec"; }

.b-fa-briefcase:before {
  content: "\f0b1"; }

.b-fa-briefcase-medical:before {
  content: "\f469"; }

.b-fa-broadcast-tower:before {
  content: "\f519"; }

.b-fa-broom:before {
  content: "\f51a"; }

.b-fa-brush:before {
  content: "\f55d"; }

.b-fa-btc:before {
  content: "\f15a"; }

.b-fa-buffer:before {
  content: "\f837"; }

.b-fa-bug:before {
  content: "\f188"; }

.b-fa-building:before {
  content: "\f1ad"; }

.b-fa-bullhorn:before {
  content: "\f0a1"; }

.b-fa-bullseye:before {
  content: "\f140"; }

.b-fa-burn:before {
  content: "\f46a"; }

.b-fa-buromobelexperte:before {
  content: "\f37f"; }

.b-fa-bus:before {
  content: "\f207"; }

.b-fa-bus-alt:before {
  content: "\f55e"; }

.b-fa-business-time:before {
  content: "\f64a"; }

.b-fa-buy-n-large:before {
  content: "\f8a6"; }

.b-fa-buysellads:before {
  content: "\f20d"; }

.b-fa-calculator:before {
  content: "\f1ec"; }

.b-fa-calendar:before, .b-icon-calendar:before {
  content: "\f133"; }

.b-fa-calendar-alt:before {
  content: "\f073"; }

.b-fa-calendar-check:before {
  content: "\f274"; }

.b-fa-calendar-day:before {
  content: "\f783"; }

.b-fa-calendar-minus:before {
  content: "\f272"; }

.b-fa-calendar-plus:before {
  content: "\f271"; }

.b-fa-calendar-times:before {
  content: "\f273"; }

.b-fa-calendar-week:before {
  content: "\f784"; }

.b-fa-camera:before {
  content: "\f030"; }

.b-fa-camera-retro:before {
  content: "\f083"; }

.b-fa-campground:before {
  content: "\f6bb"; }

.b-fa-canadian-maple-leaf:before {
  content: "\f785"; }

.b-fa-candy-cane:before {
  content: "\f786"; }

.b-fa-cannabis:before {
  content: "\f55f"; }

.b-fa-capsules:before {
  content: "\f46b"; }

.b-fa-car:before {
  content: "\f1b9"; }

.b-fa-car-alt:before {
  content: "\f5de"; }

.b-fa-car-battery:before {
  content: "\f5df"; }

.b-fa-car-crash:before {
  content: "\f5e1"; }

.b-fa-car-side:before {
  content: "\f5e4"; }

.b-fa-caravan:before {
  content: "\f8ff"; }

.b-fa-caret-down:before, .b-icon-picker:before {
  content: "\f0d7"; }

.b-fa-caret-left:before, .b-icon-collapse-gridregion:before {
  content: "\f0d9"; }

.b-fa-caret-right:before, .b-icon-expand-gridregion:before {
  content: "\f0da"; }

.b-fa-caret-square-down:before {
  content: "\f150"; }

.b-fa-caret-square-left:before {
  content: "\f191"; }

.b-fa-caret-square-right:before {
  content: "\f152"; }

.b-fa-caret-square-up:before {
  content: "\f151"; }

.b-fa-caret-up:before {
  content: "\f0d8"; }

.b-fa-carrot:before {
  content: "\f787"; }

.b-fa-cart-arrow-down:before {
  content: "\f218"; }

.b-fa-cart-plus:before {
  content: "\f217"; }

.b-fa-cash-register:before {
  content: "\f788"; }

.b-fa-cat:before {
  content: "\f6be"; }

.b-fa-cc-amazon-pay:before {
  content: "\f42d"; }

.b-fa-cc-amex:before {
  content: "\f1f3"; }

.b-fa-cc-apple-pay:before {
  content: "\f416"; }

.b-fa-cc-diners-club:before {
  content: "\f24c"; }

.b-fa-cc-discover:before {
  content: "\f1f2"; }

.b-fa-cc-jcb:before {
  content: "\f24b"; }

.b-fa-cc-mastercard:before {
  content: "\f1f1"; }

.b-fa-cc-paypal:before {
  content: "\f1f4"; }

.b-fa-cc-stripe:before {
  content: "\f1f5"; }

.b-fa-cc-visa:before {
  content: "\f1f0"; }

.b-fa-centercode:before {
  content: "\f380"; }

.b-fa-centos:before {
  content: "\f789"; }

.b-fa-certificate:before {
  content: "\f0a3"; }

.b-fa-chair:before {
  content: "\f6c0"; }

.b-fa-chalkboard:before {
  content: "\f51b"; }

.b-fa-chalkboard-teacher:before {
  content: "\f51c"; }

.b-fa-charging-station:before {
  content: "\f5e7"; }

.b-fa-chart-area:before {
  content: "\f1fe"; }

.b-fa-chart-bar:before {
  content: "\f080"; }

.b-fa-chart-line:before {
  content: "\f201"; }

.b-fa-chart-pie:before {
  content: "\f200"; }

.b-fa-check:before, .b-icon-check:before, .b-checkbox .b-checkbox-label:before, .b-icon-valid:before {
  content: "\f00c"; }

.b-fa-check-circle:before {
  content: "\f058"; }

.b-fa-check-double:before {
  content: "\f560"; }

.b-fa-check-square:before, .b-icon-checked:before {
  content: "\f14a"; }

.b-fa-cheese:before {
  content: "\f7ef"; }

.b-fa-chess:before {
  content: "\f439"; }

.b-fa-chess-bishop:before {
  content: "\f43a"; }

.b-fa-chess-board:before {
  content: "\f43c"; }

.b-fa-chess-king:before {
  content: "\f43f"; }

.b-fa-chess-knight:before {
  content: "\f441"; }

.b-fa-chess-pawn:before {
  content: "\f443"; }

.b-fa-chess-queen:before {
  content: "\f445"; }

.b-fa-chess-rook:before {
  content: "\f447"; }

.b-fa-chevron-circle-down:before {
  content: "\f13a"; }

.b-fa-chevron-circle-left:before {
  content: "\f137"; }

.b-fa-chevron-circle-right:before {
  content: "\f138"; }

.b-fa-chevron-circle-up:before {
  content: "\f139"; }

.b-fa-chevron-down:before {
  content: "\f078"; }

.b-fa-chevron-left:before, .b-icon-filter-less:before,
.b-icon-filter-before:before {
  content: "\f053"; }

.b-fa-chevron-right:before, .b-icon-filter-more:before,
.b-icon-filter-after:before, .b-icon-sub-menu:before {
  content: "\f054"; }

.b-fa-chevron-up:before {
  content: "\f077"; }

.b-fa-child:before {
  content: "\f1ae"; }

.b-fa-chrome:before {
  content: "\f268"; }

.b-fa-chromecast:before {
  content: "\f838"; }

.b-fa-church:before {
  content: "\f51d"; }

.b-fa-circle:before, .b-icon-circle:before, .b-icon-tree-leaf:before {
  content: "\f111"; }

.b-fa-circle-notch:before {
  content: "\f1ce"; }

.b-fa-city:before {
  content: "\f64f"; }

.b-fa-clinic-medical:before {
  content: "\f7f2"; }

.b-fa-clipboard:before, .b-icon-clipboard:before {
  content: "\f328"; }

.b-fa-clipboard-check:before {
  content: "\f46c"; }

.b-fa-clipboard-list:before {
  content: "\f46d"; }

.b-fa-clock:before, .b-icon-clock:before {
  content: "\f017"; }

.b-fa-clone:before {
  content: "\f24d"; }

.b-fa-closed-captioning:before {
  content: "\f20a"; }

.b-fa-cloud:before {
  content: "\f0c2"; }

.b-fa-cloud-download-alt:before {
  content: "\f381"; }

.b-fa-cloud-meatball:before {
  content: "\f73b"; }

.b-fa-cloud-moon:before {
  content: "\f6c3"; }

.b-fa-cloud-moon-rain:before {
  content: "\f73c"; }

.b-fa-cloud-rain:before {
  content: "\f73d"; }

.b-fa-cloud-showers-heavy:before {
  content: "\f740"; }

.b-fa-cloud-sun:before {
  content: "\f6c4"; }

.b-fa-cloud-sun-rain:before {
  content: "\f743"; }

.b-fa-cloud-upload-alt:before {
  content: "\f382"; }

.b-fa-cloudscale:before {
  content: "\f383"; }

.b-fa-cloudsmith:before {
  content: "\f384"; }

.b-fa-cloudversify:before {
  content: "\f385"; }

.b-fa-cocktail:before {
  content: "\f561"; }

.b-fa-code:before, .b-icon-code:before {
  content: "\f121"; }

.b-fa-code-branch:before {
  content: "\f126"; }

.b-fa-codepen:before {
  content: "\f1cb"; }

.b-fa-codiepie:before {
  content: "\f284"; }

.b-fa-coffee:before {
  content: "\f0f4"; }

.b-fa-cog:before {
  content: "\f013"; }

.b-fa-cogs:before {
  content: "\f085"; }

.b-fa-coins:before {
  content: "\f51e"; }

.b-fa-columns:before, .b-icon-columns:before {
  content: "\f0db"; }

.b-fa-comment:before {
  content: "\f075"; }

.b-fa-comment-alt:before {
  content: "\f27a"; }

.b-fa-comment-dollar:before {
  content: "\f651"; }

.b-fa-comment-dots:before {
  content: "\f4ad"; }

.b-fa-comment-medical:before {
  content: "\f7f5"; }

.b-fa-comment-slash:before {
  content: "\f4b3"; }

.b-fa-comments:before {
  content: "\f086"; }

.b-fa-comments-dollar:before {
  content: "\f653"; }

.b-fa-compact-disc:before {
  content: "\f51f"; }

.b-fa-compass:before {
  content: "\f14e"; }

.b-fa-compress:before {
  content: "\f066"; }

.b-fa-compress-alt:before {
  content: "\f422"; }

.b-fa-compress-arrows-alt:before {
  content: "\f78c"; }

.b-fa-concierge-bell:before {
  content: "\f562"; }

.b-fa-confluence:before {
  content: "\f78d"; }

.b-fa-connectdevelop:before {
  content: "\f20e"; }

.b-fa-contao:before {
  content: "\f26d"; }

.b-fa-cookie:before {
  content: "\f563"; }

.b-fa-cookie-bite:before {
  content: "\f564"; }

.b-fa-copy:before {
  content: "\f0c5"; }

.b-fa-copyright:before {
  content: "\f1f9"; }

.b-fa-cotton-bureau:before {
  content: "\f89e"; }

.b-fa-couch:before {
  content: "\f4b8"; }

.b-fa-cpanel:before {
  content: "\f388"; }

.b-fa-creative-commons:before {
  content: "\f25e"; }

.b-fa-creative-commons-by:before {
  content: "\f4e7"; }

.b-fa-creative-commons-nc:before {
  content: "\f4e8"; }

.b-fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.b-fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.b-fa-creative-commons-nd:before {
  content: "\f4eb"; }

.b-fa-creative-commons-pd:before {
  content: "\f4ec"; }

.b-fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.b-fa-creative-commons-remix:before {
  content: "\f4ee"; }

.b-fa-creative-commons-sa:before {
  content: "\f4ef"; }

.b-fa-creative-commons-sampling:before {
  content: "\f4f0"; }

.b-fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.b-fa-creative-commons-share:before {
  content: "\f4f2"; }

.b-fa-creative-commons-zero:before {
  content: "\f4f3"; }

.b-fa-credit-card:before {
  content: "\f09d"; }

.b-fa-critical-role:before {
  content: "\f6c9"; }

.b-fa-crop:before {
  content: "\f125"; }

.b-fa-crop-alt:before {
  content: "\f565"; }

.b-fa-cross:before {
  content: "\f654"; }

.b-fa-crosshairs:before {
  content: "\f05b"; }

.b-fa-crow:before {
  content: "\f520"; }

.b-fa-crown:before {
  content: "\f521"; }

.b-fa-crutch:before {
  content: "\f7f7"; }

.b-fa-css3:before {
  content: "\f13c"; }

.b-fa-css3-alt:before {
  content: "\f38b"; }

.b-fa-cube:before {
  content: "\f1b2"; }

.b-fa-cubes:before {
  content: "\f1b3"; }

.b-fa-cut:before {
  content: "\f0c4"; }

.b-fa-cuttlefish:before {
  content: "\f38c"; }

.b-fa-d-and-d:before {
  content: "\f38d"; }

.b-fa-d-and-d-beyond:before {
  content: "\f6ca"; }

.b-fa-dailymotion:before {
  content: "\f952"; }

.b-fa-dashcube:before {
  content: "\f210"; }

.b-fa-database:before {
  content: "\f1c0"; }

.b-fa-deaf:before {
  content: "\f2a4"; }

.b-fa-delicious:before {
  content: "\f1a5"; }

.b-fa-democrat:before {
  content: "\f747"; }

.b-fa-deploydog:before {
  content: "\f38e"; }

.b-fa-deskpro:before {
  content: "\f38f"; }

.b-fa-desktop:before {
  content: "\f108"; }

.b-fa-dev:before {
  content: "\f6cc"; }

.b-fa-deviantart:before {
  content: "\f1bd"; }

.b-fa-dharmachakra:before {
  content: "\f655"; }

.b-fa-dhl:before {
  content: "\f790"; }

.b-fa-diagnoses:before {
  content: "\f470"; }

.b-fa-diaspora:before {
  content: "\f791"; }

.b-fa-dice:before {
  content: "\f522"; }

.b-fa-dice-d20:before {
  content: "\f6cf"; }

.b-fa-dice-d6:before {
  content: "\f6d1"; }

.b-fa-dice-five:before {
  content: "\f523"; }

.b-fa-dice-four:before {
  content: "\f524"; }

.b-fa-dice-one:before {
  content: "\f525"; }

.b-fa-dice-six:before {
  content: "\f526"; }

.b-fa-dice-three:before {
  content: "\f527"; }

.b-fa-dice-two:before {
  content: "\f528"; }

.b-fa-digg:before {
  content: "\f1a6"; }

.b-fa-digital-ocean:before {
  content: "\f391"; }

.b-fa-digital-tachograph:before {
  content: "\f566"; }

.b-fa-directions:before {
  content: "\f5eb"; }

.b-fa-discord:before {
  content: "\f392"; }

.b-fa-discourse:before {
  content: "\f393"; }

.b-fa-disease:before {
  content: "\f7fa"; }

.b-fa-divide:before {
  content: "\f529"; }

.b-fa-dizzy:before {
  content: "\f567"; }

.b-fa-dna:before {
  content: "\f471"; }

.b-fa-dochub:before {
  content: "\f394"; }

.b-fa-docker:before {
  content: "\f395"; }

.b-fa-dog:before {
  content: "\f6d3"; }

.b-fa-dollar-sign:before {
  content: "\f155"; }

.b-fa-dolly:before {
  content: "\f472"; }

.b-fa-dolly-flatbed:before {
  content: "\f474"; }

.b-fa-donate:before {
  content: "\f4b9"; }

.b-fa-door-closed:before {
  content: "\f52a"; }

.b-fa-door-open:before {
  content: "\f52b"; }

.b-fa-dot-circle:before {
  content: "\f192"; }

.b-fa-dove:before {
  content: "\f4ba"; }

.b-fa-download:before {
  content: "\f019"; }

.b-fa-draft2digital:before {
  content: "\f396"; }

.b-fa-drafting-compass:before {
  content: "\f568"; }

.b-fa-dragon:before {
  content: "\f6d5"; }

.b-fa-draw-polygon:before {
  content: "\f5ee"; }

.b-fa-dribbble:before {
  content: "\f17d"; }

.b-fa-dribbble-square:before {
  content: "\f397"; }

.b-fa-dropbox:before {
  content: "\f16b"; }

.b-fa-drum:before {
  content: "\f569"; }

.b-fa-drum-steelpan:before {
  content: "\f56a"; }

.b-fa-drumstick-bite:before {
  content: "\f6d7"; }

.b-fa-drupal:before {
  content: "\f1a9"; }

.b-fa-dumbbell:before {
  content: "\f44b"; }

.b-fa-dumpster:before {
  content: "\f793"; }

.b-fa-dumpster-fire:before {
  content: "\f794"; }

.b-fa-dungeon:before {
  content: "\f6d9"; }

.b-fa-dyalog:before {
  content: "\f399"; }

.b-fa-earlybirds:before {
  content: "\f39a"; }

.b-fa-ebay:before {
  content: "\f4f4"; }

.b-fa-edge:before {
  content: "\f282"; }

.b-fa-edit:before {
  content: "\f044"; }

.b-fa-egg:before {
  content: "\f7fb"; }

.b-fa-eject:before {
  content: "\f052"; }

.b-fa-elementor:before {
  content: "\f430"; }

.b-fa-ellipsis-h:before {
  content: "\f141"; }

.b-fa-ellipsis-v:before {
  content: "\f142"; }

.b-fa-ello:before {
  content: "\f5f1"; }

.b-fa-ember:before {
  content: "\f423"; }

.b-fa-empire:before {
  content: "\f1d1"; }

.b-fa-envelope:before {
  content: "\f0e0"; }

.b-fa-envelope-open:before {
  content: "\f2b6"; }

.b-fa-envelope-open-text:before {
  content: "\f658"; }

.b-fa-envelope-square:before {
  content: "\f199"; }

.b-fa-envira:before {
  content: "\f299"; }

.b-fa-equals:before {
  content: "\f52c"; }

.b-fa-eraser:before {
  content: "\f12d"; }

.b-fa-erlang:before {
  content: "\f39d"; }

.b-fa-ethereum:before {
  content: "\f42e"; }

.b-fa-ethernet:before {
  content: "\f796"; }

.b-fa-etsy:before {
  content: "\f2d7"; }

.b-fa-euro-sign:before {
  content: "\f153"; }

.b-fa-evernote:before {
  content: "\f839"; }

.b-fa-exchange-alt:before {
  content: "\f362"; }

.b-fa-exclamation:before {
  content: "\f12a"; }

.b-fa-exclamation-circle:before {
  content: "\f06a"; }

.b-fa-exclamation-triangle:before, .b-icon-warning:before {
  content: "\f071"; }

.b-fa-expand:before {
  content: "\f065"; }

.b-fa-expand-alt:before {
  content: "\f424"; }

.b-fa-expand-arrows-alt:before {
  content: "\f31e"; }

.b-fa-expeditedssl:before {
  content: "\f23e"; }

.b-fa-external-link-alt:before {
  content: "\f35d"; }

.b-fa-external-link-square-alt:before {
  content: "\f360"; }

.b-fa-eye:before {
  content: "\f06e"; }

.b-fa-eye-dropper:before {
  content: "\f1fb"; }

.b-fa-eye-slash:before {
  content: "\f070"; }

.b-fa-facebook:before {
  content: "\f09a"; }

.b-fa-facebook-f:before {
  content: "\f39e"; }

.b-fa-facebook-messenger:before {
  content: "\f39f"; }

.b-fa-facebook-square:before {
  content: "\f082"; }

.b-fa-fan:before {
  content: "\f863"; }

.b-fa-fantasy-flight-games:before {
  content: "\f6dc"; }

.b-fa-fast-backward:before {
  content: "\f049"; }

.b-fa-fast-forward:before {
  content: "\f050"; }

.b-fa-faucet:before {
  content: "\f905"; }

.b-fa-fax:before {
  content: "\f1ac"; }

.b-fa-feather:before {
  content: "\f52d"; }

.b-fa-feather-alt:before {
  content: "\f56b"; }

.b-fa-fedex:before {
  content: "\f797"; }

.b-fa-fedora:before {
  content: "\f798"; }

.b-fa-female:before {
  content: "\f182"; }

.b-fa-fighter-jet:before {
  content: "\f0fb"; }

.b-fa-figma:before {
  content: "\f799"; }

.b-fa-file:before {
  content: "\f15b"; }

.b-fa-file-alt:before {
  content: "\f15c"; }

.b-fa-file-archive:before {
  content: "\f1c6"; }

.b-fa-file-audio:before {
  content: "\f1c7"; }

.b-fa-file-code:before {
  content: "\f1c9"; }

.b-fa-file-contract:before {
  content: "\f56c"; }

.b-fa-file-csv:before {
  content: "\f6dd"; }

.b-fa-file-download:before {
  content: "\f56d"; }

.b-fa-file-excel:before {
  content: "\f1c3"; }

.b-fa-file-export:before {
  content: "\f56e"; }

.b-fa-file-image:before {
  content: "\f1c5"; }

.b-fa-file-import:before {
  content: "\f56f"; }

.b-fa-file-invoice:before {
  content: "\f570"; }

.b-fa-file-invoice-dollar:before {
  content: "\f571"; }

.b-fa-file-medical:before {
  content: "\f477"; }

.b-fa-file-medical-alt:before {
  content: "\f478"; }

.b-fa-file-pdf:before {
  content: "\f1c1"; }

.b-fa-file-powerpoint:before {
  content: "\f1c4"; }

.b-fa-file-prescription:before {
  content: "\f572"; }

.b-fa-file-signature:before {
  content: "\f573"; }

.b-fa-file-upload:before {
  content: "\f574"; }

.b-fa-file-video:before {
  content: "\f1c8"; }

.b-fa-file-word:before {
  content: "\f1c2"; }

.b-fa-fill:before {
  content: "\f575"; }

.b-fa-fill-drip:before {
  content: "\f576"; }

.b-fa-film:before {
  content: "\f008"; }

.b-fa-filter:before, .b-icon-filter:before, .b-grid-header .b-filter-icon::after,
.b-icon-filter-equal:before {
  content: "\f0b0"; }

.b-fa-fingerprint:before {
  content: "\f577"; }

.b-fa-fire:before {
  content: "\f06d"; }

.b-fa-fire-alt:before {
  content: "\f7e4"; }

.b-fa-fire-extinguisher:before {
  content: "\f134"; }

.b-fa-firefox:before {
  content: "\f269"; }

.b-fa-firefox-browser:before {
  content: "\f907"; }

.b-fa-first-aid:before {
  content: "\f479"; }

.b-fa-first-order:before {
  content: "\f2b0"; }

.b-fa-first-order-alt:before {
  content: "\f50a"; }

.b-fa-firstdraft:before {
  content: "\f3a1"; }

.b-fa-fish:before {
  content: "\f578"; }

.b-fa-fist-raised:before {
  content: "\f6de"; }

.b-fa-flag:before {
  content: "\f024"; }

.b-fa-flag-checkered:before {
  content: "\f11e"; }

.b-fa-flag-usa:before {
  content: "\f74d"; }

.b-fa-flask:before {
  content: "\f0c3"; }

.b-fa-flickr:before {
  content: "\f16e"; }

.b-fa-flipboard:before {
  content: "\f44d"; }

.b-fa-flushed:before {
  content: "\f579"; }

.b-fa-fly:before {
  content: "\f417"; }

.b-fa-folder:before, .b-icon-tree-folder:before {
  content: "\f07b"; }

.b-fa-folder-minus:before {
  content: "\f65d"; }

.b-fa-folder-open:before, .b-icon-tree-folder-open:before {
  content: "\f07c"; }

.b-fa-folder-plus:before {
  content: "\f65e"; }

.b-fa-font:before {
  content: "\f031"; }

.b-fa-font-awesome:before {
  content: "\f2b4"; }

.b-fa-font-awesome-alt:before {
  content: "\f35c"; }

.b-fa-font-awesome-flag:before {
  content: "\f425"; }

.b-fa-font-awesome-logo-full:before {
  content: "\f4e6"; }

.b-fa-fonticons:before {
  content: "\f280"; }

.b-fa-fonticons-fi:before {
  content: "\f3a2"; }

.b-fa-football-ball:before {
  content: "\f44e"; }

.b-fa-fort-awesome:before {
  content: "\f286"; }

.b-fa-fort-awesome-alt:before {
  content: "\f3a3"; }

.b-fa-forumbee:before {
  content: "\f211"; }

.b-fa-forward:before {
  content: "\f04e"; }

.b-fa-foursquare:before {
  content: "\f180"; }

.b-fa-free-code-camp:before {
  content: "\f2c5"; }

.b-fa-freebsd:before {
  content: "\f3a4"; }

.b-fa-frog:before {
  content: "\f52e"; }

.b-fa-frown:before {
  content: "\f119"; }

.b-fa-frown-open:before {
  content: "\f57a"; }

.b-fa-fulcrum:before {
  content: "\f50b"; }

.b-fa-funnel-dollar:before {
  content: "\f662"; }

.b-fa-futbol:before {
  content: "\f1e3"; }

.b-fa-galactic-republic:before {
  content: "\f50c"; }

.b-fa-galactic-senate:before {
  content: "\f50d"; }

.b-fa-gamepad:before {
  content: "\f11b"; }

.b-fa-gas-pump:before {
  content: "\f52f"; }

.b-fa-gavel:before {
  content: "\f0e3"; }

.b-fa-gem:before {
  content: "\f3a5"; }

.b-fa-genderless:before {
  content: "\f22d"; }

.b-fa-get-pocket:before {
  content: "\f265"; }

.b-fa-gg:before {
  content: "\f260"; }

.b-fa-gg-circle:before {
  content: "\f261"; }

.b-fa-ghost:before {
  content: "\f6e2"; }

.b-fa-gift:before {
  content: "\f06b"; }

.b-fa-gifts:before {
  content: "\f79c"; }

.b-fa-git:before {
  content: "\f1d3"; }

.b-fa-git-alt:before {
  content: "\f841"; }

.b-fa-git-square:before {
  content: "\f1d2"; }

.b-fa-github:before {
  content: "\f09b"; }

.b-fa-github-alt:before {
  content: "\f113"; }

.b-fa-github-square:before {
  content: "\f092"; }

.b-fa-gitkraken:before {
  content: "\f3a6"; }

.b-fa-gitlab:before {
  content: "\f296"; }

.b-fa-gitter:before {
  content: "\f426"; }

.b-fa-glass-cheers:before {
  content: "\f79f"; }

.b-fa-glass-martini:before {
  content: "\f000"; }

.b-fa-glass-martini-alt:before {
  content: "\f57b"; }

.b-fa-glass-whiskey:before {
  content: "\f7a0"; }

.b-fa-glasses:before {
  content: "\f530"; }

.b-fa-glide:before {
  content: "\f2a5"; }

.b-fa-glide-g:before {
  content: "\f2a6"; }

.b-fa-globe:before {
  content: "\f0ac"; }

.b-fa-globe-africa:before {
  content: "\f57c"; }

.b-fa-globe-americas:before {
  content: "\f57d"; }

.b-fa-globe-asia:before {
  content: "\f57e"; }

.b-fa-globe-europe:before {
  content: "\f7a2"; }

.b-fa-gofore:before {
  content: "\f3a7"; }

.b-fa-golf-ball:before {
  content: "\f450"; }

.b-fa-goodreads:before {
  content: "\f3a8"; }

.b-fa-goodreads-g:before {
  content: "\f3a9"; }

.b-fa-google:before {
  content: "\f1a0"; }

.b-fa-google-drive:before {
  content: "\f3aa"; }

.b-fa-google-play:before {
  content: "\f3ab"; }

.b-fa-google-plus:before {
  content: "\f2b3"; }

.b-fa-google-plus-g:before {
  content: "\f0d5"; }

.b-fa-google-plus-square:before {
  content: "\f0d4"; }

.b-fa-google-wallet:before {
  content: "\f1ee"; }

.b-fa-gopuram:before {
  content: "\f664"; }

.b-fa-graduation-cap:before {
  content: "\f19d"; }

.b-fa-gratipay:before {
  content: "\f184"; }

.b-fa-grav:before {
  content: "\f2d6"; }

.b-fa-greater-than:before {
  content: "\f531"; }

.b-fa-greater-than-equal:before {
  content: "\f532"; }

.b-fa-grimace:before {
  content: "\f57f"; }

.b-fa-grin:before {
  content: "\f580"; }

.b-fa-grin-alt:before {
  content: "\f581"; }

.b-fa-grin-beam:before {
  content: "\f582"; }

.b-fa-grin-beam-sweat:before {
  content: "\f583"; }

.b-fa-grin-hearts:before {
  content: "\f584"; }

.b-fa-grin-squint:before {
  content: "\f585"; }

.b-fa-grin-squint-tears:before {
  content: "\f586"; }

.b-fa-grin-stars:before {
  content: "\f587"; }

.b-fa-grin-tears:before {
  content: "\f588"; }

.b-fa-grin-tongue:before {
  content: "\f589"; }

.b-fa-grin-tongue-squint:before {
  content: "\f58a"; }

.b-fa-grin-tongue-wink:before {
  content: "\f58b"; }

.b-fa-grin-wink:before {
  content: "\f58c"; }

.b-fa-grip-horizontal:before {
  content: "\f58d"; }

.b-fa-grip-lines:before {
  content: "\f7a4"; }

.b-fa-grip-lines-vertical:before {
  content: "\f7a5"; }

.b-fa-grip-vertical:before {
  content: "\f58e"; }

.b-fa-gripfire:before {
  content: "\f3ac"; }

.b-fa-grunt:before {
  content: "\f3ad"; }

.b-fa-guitar:before {
  content: "\f7a6"; }

.b-fa-gulp:before {
  content: "\f3ae"; }

.b-fa-h-square:before {
  content: "\f0fd"; }

.b-fa-hacker-news:before {
  content: "\f1d4"; }

.b-fa-hacker-news-square:before {
  content: "\f3af"; }

.b-fa-hackerrank:before {
  content: "\f5f7"; }

.b-fa-hamburger:before {
  content: "\f805"; }

.b-fa-hammer:before {
  content: "\f6e3"; }

.b-fa-hamsa:before {
  content: "\f665"; }

.b-fa-hand-holding:before {
  content: "\f4bd"; }

.b-fa-hand-holding-heart:before {
  content: "\f4be"; }

.b-fa-hand-holding-medical:before {
  content: "\f95c"; }

.b-fa-hand-holding-usd:before {
  content: "\f4c0"; }

.b-fa-hand-holding-water:before {
  content: "\f4c1"; }

.b-fa-hand-lizard:before {
  content: "\f258"; }

.b-fa-hand-middle-finger:before {
  content: "\f806"; }

.b-fa-hand-paper:before {
  content: "\f256"; }

.b-fa-hand-peace:before {
  content: "\f25b"; }

.b-fa-hand-point-down:before {
  content: "\f0a7"; }

.b-fa-hand-point-left:before {
  content: "\f0a5"; }

.b-fa-hand-point-right:before {
  content: "\f0a4"; }

.b-fa-hand-point-up:before {
  content: "\f0a6"; }

.b-fa-hand-pointer:before {
  content: "\f25a"; }

.b-fa-hand-rock:before {
  content: "\f255"; }

.b-fa-hand-scissors:before {
  content: "\f257"; }

.b-fa-hand-sparkles:before {
  content: "\f95d"; }

.b-fa-hand-spock:before {
  content: "\f259"; }

.b-fa-hands:before {
  content: "\f4c2"; }

.b-fa-hands-helping:before {
  content: "\f4c4"; }

.b-fa-hands-wash:before {
  content: "\f95e"; }

.b-fa-handshake:before {
  content: "\f2b5"; }

.b-fa-handshake-alt-slash:before {
  content: "\f95f"; }

.b-fa-handshake-slash:before {
  content: "\f960"; }

.b-fa-hanukiah:before {
  content: "\f6e6"; }

.b-fa-hard-hat:before {
  content: "\f807"; }

.b-fa-hashtag:before {
  content: "\f292"; }

.b-fa-hat-cowboy:before {
  content: "\f8c0"; }

.b-fa-hat-cowboy-side:before {
  content: "\f8c1"; }

.b-fa-hat-wizard:before {
  content: "\f6e8"; }

.b-fa-hdd:before {
  content: "\f0a0"; }

.b-fa-head-side-cough:before {
  content: "\f961"; }

.b-fa-head-side-cough-slash:before {
  content: "\f962"; }

.b-fa-head-side-mask:before {
  content: "\f963"; }

.b-fa-head-side-virus:before {
  content: "\f964"; }

.b-fa-heading:before {
  content: "\f1dc"; }

.b-fa-headphones:before {
  content: "\f025"; }

.b-fa-headphones-alt:before {
  content: "\f58f"; }

.b-fa-headset:before {
  content: "\f590"; }

.b-fa-heart:before {
  content: "\f004"; }

.b-fa-heart-broken:before {
  content: "\f7a9"; }

.b-fa-heartbeat:before {
  content: "\f21e"; }

.b-fa-helicopter:before {
  content: "\f533"; }

.b-fa-highlighter:before {
  content: "\f591"; }

.b-fa-hiking:before {
  content: "\f6ec"; }

.b-fa-hippo:before {
  content: "\f6ed"; }

.b-fa-hips:before {
  content: "\f452"; }

.b-fa-hire-a-helper:before {
  content: "\f3b0"; }

.b-fa-history:before {
  content: "\f1da"; }

.b-fa-hockey-puck:before {
  content: "\f453"; }

.b-fa-holly-berry:before {
  content: "\f7aa"; }

.b-fa-home:before {
  content: "\f015"; }

.b-fa-hooli:before {
  content: "\f427"; }

.b-fa-hornbill:before {
  content: "\f592"; }

.b-fa-horse:before {
  content: "\f6f0"; }

.b-fa-horse-head:before {
  content: "\f7ab"; }

.b-fa-hospital:before {
  content: "\f0f8"; }

.b-fa-hospital-alt:before {
  content: "\f47d"; }

.b-fa-hospital-symbol:before {
  content: "\f47e"; }

.b-fa-hospital-user:before {
  content: "\f80d"; }

.b-fa-hot-tub:before {
  content: "\f593"; }

.b-fa-hotdog:before {
  content: "\f80f"; }

.b-fa-hotel:before {
  content: "\f594"; }

.b-fa-hotjar:before {
  content: "\f3b1"; }

.b-fa-hourglass:before {
  content: "\f254"; }

.b-fa-hourglass-end:before {
  content: "\f253"; }

.b-fa-hourglass-half:before {
  content: "\f252"; }

.b-fa-hourglass-start:before {
  content: "\f251"; }

.b-fa-house-damage:before {
  content: "\f6f1"; }

.b-fa-house-user:before {
  content: "\f965"; }

.b-fa-houzz:before {
  content: "\f27c"; }

.b-fa-hryvnia:before {
  content: "\f6f2"; }

.b-fa-html5:before {
  content: "\f13b"; }

.b-fa-hubspot:before {
  content: "\f3b2"; }

.b-fa-i-cursor:before {
  content: "\f246"; }

.b-fa-ice-cream:before {
  content: "\f810"; }

.b-fa-icicles:before {
  content: "\f7ad"; }

.b-fa-icons:before {
  content: "\f86d"; }

.b-fa-id-badge:before {
  content: "\f2c1"; }

.b-fa-id-card:before {
  content: "\f2c2"; }

.b-fa-id-card-alt:before {
  content: "\f47f"; }

.b-fa-ideal:before {
  content: "\f913"; }

.b-fa-igloo:before {
  content: "\f7ae"; }

.b-fa-image:before {
  content: "\f03e"; }

.b-fa-images:before {
  content: "\f302"; }

.b-fa-imdb:before {
  content: "\f2d8"; }

.b-fa-inbox:before {
  content: "\f01c"; }

.b-fa-indent:before {
  content: "\f03c"; }

.b-fa-industry:before {
  content: "\f275"; }

.b-fa-infinity:before {
  content: "\f534"; }

.b-fa-info:before, .b-icon-info:before {
  content: "\f129"; }

.b-fa-info-circle:before {
  content: "\f05a"; }

.b-fa-instagram:before {
  content: "\f16d"; }

.b-fa-instagram-square:before {
  content: "\f955"; }

.b-fa-intercom:before {
  content: "\f7af"; }

.b-fa-internet-explorer:before {
  content: "\f26b"; }

.b-fa-invision:before {
  content: "\f7b0"; }

.b-fa-ioxhost:before {
  content: "\f208"; }

.b-fa-italic:before {
  content: "\f033"; }

.b-fa-itch-io:before {
  content: "\f83a"; }

.b-fa-itunes:before {
  content: "\f3b4"; }

.b-fa-itunes-note:before {
  content: "\f3b5"; }

.b-fa-java:before {
  content: "\f4e4"; }

.b-fa-jedi:before {
  content: "\f669"; }

.b-fa-jedi-order:before {
  content: "\f50e"; }

.b-fa-jenkins:before {
  content: "\f3b6"; }

.b-fa-jira:before {
  content: "\f7b1"; }

.b-fa-joget:before {
  content: "\f3b7"; }

.b-fa-joint:before {
  content: "\f595"; }

.b-fa-joomla:before {
  content: "\f1aa"; }

.b-fa-journal-whills:before {
  content: "\f66a"; }

.b-fa-js:before {
  content: "\f3b8"; }

.b-fa-js-square:before {
  content: "\f3b9"; }

.b-fa-jsfiddle:before {
  content: "\f1cc"; }

.b-fa-kaaba:before {
  content: "\f66b"; }

.b-fa-kaggle:before {
  content: "\f5fa"; }

.b-fa-key:before {
  content: "\f084"; }

.b-fa-keybase:before {
  content: "\f4f5"; }

.b-fa-keyboard:before {
  content: "\f11c"; }

.b-fa-keycdn:before {
  content: "\f3ba"; }

.b-fa-khanda:before {
  content: "\f66d"; }

.b-fa-kickstarter:before {
  content: "\f3bb"; }

.b-fa-kickstarter-k:before {
  content: "\f3bc"; }

.b-fa-kiss:before {
  content: "\f596"; }

.b-fa-kiss-beam:before {
  content: "\f597"; }

.b-fa-kiss-wink-heart:before {
  content: "\f598"; }

.b-fa-kiwi-bird:before {
  content: "\f535"; }

.b-fa-korvue:before {
  content: "\f42f"; }

.b-fa-landmark:before {
  content: "\f66f"; }

.b-fa-language:before {
  content: "\f1ab"; }

.b-fa-laptop:before {
  content: "\f109"; }

.b-fa-laptop-code:before {
  content: "\f5fc"; }

.b-fa-laptop-house:before {
  content: "\f966"; }

.b-fa-laptop-medical:before {
  content: "\f812"; }

.b-fa-laravel:before {
  content: "\f3bd"; }

.b-fa-lastfm:before {
  content: "\f202"; }

.b-fa-lastfm-square:before {
  content: "\f203"; }

.b-fa-laugh:before {
  content: "\f599"; }

.b-fa-laugh-beam:before {
  content: "\f59a"; }

.b-fa-laugh-squint:before {
  content: "\f59b"; }

.b-fa-laugh-wink:before {
  content: "\f59c"; }

.b-fa-layer-group:before {
  content: "\f5fd"; }

.b-fa-leaf:before {
  content: "\f06c"; }

.b-fa-leanpub:before {
  content: "\f212"; }

.b-fa-lemon:before {
  content: "\f094"; }

.b-fa-less:before {
  content: "\f41d"; }

.b-fa-less-than:before {
  content: "\f536"; }

.b-fa-less-than-equal:before {
  content: "\f537"; }

.b-fa-level-down-alt:before {
  content: "\f3be"; }

.b-fa-level-up-alt:before {
  content: "\f3bf"; }

.b-fa-life-ring:before {
  content: "\f1cd"; }

.b-fa-lightbulb:before {
  content: "\f0eb"; }

.b-fa-line:before {
  content: "\f3c0"; }

.b-fa-link:before {
  content: "\f0c1"; }

.b-fa-linkedin:before {
  content: "\f08c"; }

.b-fa-linkedin-in:before {
  content: "\f0e1"; }

.b-fa-linode:before {
  content: "\f2b8"; }

.b-fa-linux:before {
  content: "\f17c"; }

.b-fa-lira-sign:before {
  content: "\f195"; }

.b-fa-list:before {
  content: "\f03a"; }

.b-fa-list-alt:before {
  content: "\f022"; }

.b-fa-list-ol:before {
  content: "\f0cb"; }

.b-fa-list-ul:before {
  content: "\f0ca"; }

.b-fa-location-arrow:before {
  content: "\f124"; }

.b-fa-lock:before {
  content: "\f023"; }

.b-fa-lock-open:before {
  content: "\f3c1"; }

.b-fa-long-arrow-alt-down:before {
  content: "\f309"; }

.b-fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.b-fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.b-fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.b-fa-low-vision:before {
  content: "\f2a8"; }

.b-fa-luggage-cart:before {
  content: "\f59d"; }

.b-fa-lungs:before {
  content: "\f604"; }

.b-fa-lungs-virus:before {
  content: "\f967"; }

.b-fa-lyft:before {
  content: "\f3c3"; }

.b-fa-magento:before {
  content: "\f3c4"; }

.b-fa-magic:before {
  content: "\f0d0"; }

.b-fa-magnet:before {
  content: "\f076"; }

.b-fa-mail-bulk:before {
  content: "\f674"; }

.b-fa-mailchimp:before {
  content: "\f59e"; }

.b-fa-male:before {
  content: "\f183"; }

.b-fa-mandalorian:before {
  content: "\f50f"; }

.b-fa-map:before {
  content: "\f279"; }

.b-fa-map-marked:before {
  content: "\f59f"; }

.b-fa-map-marked-alt:before {
  content: "\f5a0"; }

.b-fa-map-marker:before {
  content: "\f041"; }

.b-fa-map-marker-alt:before {
  content: "\f3c5"; }

.b-fa-map-pin:before {
  content: "\f276"; }

.b-fa-map-signs:before {
  content: "\f277"; }

.b-fa-markdown:before {
  content: "\f60f"; }

.b-fa-marker:before {
  content: "\f5a1"; }

.b-fa-mars:before {
  content: "\f222"; }

.b-fa-mars-double:before {
  content: "\f227"; }

.b-fa-mars-stroke:before {
  content: "\f229"; }

.b-fa-mars-stroke-h:before {
  content: "\f22b"; }

.b-fa-mars-stroke-v:before {
  content: "\f22a"; }

.b-fa-mask:before {
  content: "\f6fa"; }

.b-fa-mastodon:before {
  content: "\f4f6"; }

.b-fa-maxcdn:before {
  content: "\f136"; }

.b-fa-mdb:before {
  content: "\f8ca"; }

.b-fa-medal:before {
  content: "\f5a2"; }

.b-fa-medapps:before {
  content: "\f3c6"; }

.b-fa-medium:before {
  content: "\f23a"; }

.b-fa-medium-m:before {
  content: "\f3c7"; }

.b-fa-medkit:before {
  content: "\f0fa"; }

.b-fa-medrt:before {
  content: "\f3c8"; }

.b-fa-meetup:before {
  content: "\f2e0"; }

.b-fa-megaport:before {
  content: "\f5a3"; }

.b-fa-meh:before {
  content: "\f11a"; }

.b-fa-meh-blank:before {
  content: "\f5a4"; }

.b-fa-meh-rolling-eyes:before {
  content: "\f5a5"; }

.b-fa-memory:before {
  content: "\f538"; }

.b-fa-mendeley:before {
  content: "\f7b3"; }

.b-fa-menorah:before {
  content: "\f676"; }

.b-fa-mercury:before {
  content: "\f223"; }

.b-fa-meteor:before {
  content: "\f753"; }

.b-fa-microblog:before {
  content: "\f91a"; }

.b-fa-microchip:before {
  content: "\f2db"; }

.b-fa-microphone:before {
  content: "\f130"; }

.b-fa-microphone-alt:before {
  content: "\f3c9"; }

.b-fa-microphone-alt-slash:before {
  content: "\f539"; }

.b-fa-microphone-slash:before {
  content: "\f131"; }

.b-fa-microscope:before {
  content: "\f610"; }

.b-fa-microsoft:before {
  content: "\f3ca"; }

.b-fa-minus:before {
  content: "\f068"; }

.b-fa-minus-circle:before, .b-icon-group-collapse:before, .b-grid-cell.b-group-title:before {
  content: "\f056"; }

.b-fa-minus-square:before {
  content: "\f146"; }

.b-fa-mitten:before {
  content: "\f7b5"; }

.b-fa-mix:before {
  content: "\f3cb"; }

.b-fa-mixcloud:before {
  content: "\f289"; }

.b-fa-mixer:before {
  content: "\f956"; }

.b-fa-mizuni:before {
  content: "\f3cc"; }

.b-fa-mobile:before {
  content: "\f10b"; }

.b-fa-mobile-alt:before {
  content: "\f3cd"; }

.b-fa-modx:before {
  content: "\f285"; }

.b-fa-monero:before {
  content: "\f3d0"; }

.b-fa-money-bill:before {
  content: "\f0d6"; }

.b-fa-money-bill-alt:before {
  content: "\f3d1"; }

.b-fa-money-bill-wave:before {
  content: "\f53a"; }

.b-fa-money-bill-wave-alt:before {
  content: "\f53b"; }

.b-fa-money-check:before {
  content: "\f53c"; }

.b-fa-money-check-alt:before {
  content: "\f53d"; }

.b-fa-monument:before {
  content: "\f5a6"; }

.b-fa-moon:before {
  content: "\f186"; }

.b-fa-mortar-pestle:before {
  content: "\f5a7"; }

.b-fa-mosque:before {
  content: "\f678"; }

.b-fa-motorcycle:before {
  content: "\f21c"; }

.b-fa-mountain:before {
  content: "\f6fc"; }

.b-fa-mouse:before {
  content: "\f8cc"; }

.b-fa-mouse-pointer:before {
  content: "\f245"; }

.b-fa-mug-hot:before {
  content: "\f7b6"; }

.b-fa-music:before {
  content: "\f001"; }

.b-fa-napster:before {
  content: "\f3d2"; }

.b-fa-neos:before {
  content: "\f612"; }

.b-fa-network-wired:before {
  content: "\f6ff"; }

.b-fa-neuter:before {
  content: "\f22c"; }

.b-fa-newspaper:before {
  content: "\f1ea"; }

.b-fa-nimblr:before {
  content: "\f5a8"; }

.b-fa-node:before {
  content: "\f419"; }

.b-fa-node-js:before {
  content: "\f3d3"; }

.b-fa-not-equal:before {
  content: "\f53e"; }

.b-fa-notes-medical:before {
  content: "\f481"; }

.b-fa-npm:before {
  content: "\f3d4"; }

.b-fa-ns8:before {
  content: "\f3d5"; }

.b-fa-nutritionix:before {
  content: "\f3d6"; }

.b-fa-object-group:before {
  content: "\f247"; }

.b-fa-object-ungroup:before {
  content: "\f248"; }

.b-fa-odnoklassniki:before {
  content: "\f263"; }

.b-fa-odnoklassniki-square:before {
  content: "\f264"; }

.b-fa-oil-can:before {
  content: "\f613"; }

.b-fa-old-republic:before {
  content: "\f510"; }

.b-fa-om:before {
  content: "\f679"; }

.b-fa-opencart:before {
  content: "\f23d"; }

.b-fa-openid:before {
  content: "\f19b"; }

.b-fa-opera:before {
  content: "\f26a"; }

.b-fa-optin-monster:before {
  content: "\f23c"; }

.b-fa-orcid:before {
  content: "\f8d2"; }

.b-fa-osi:before {
  content: "\f41a"; }

.b-fa-otter:before {
  content: "\f700"; }

.b-fa-outdent:before {
  content: "\f03b"; }

.b-fa-page4:before {
  content: "\f3d7"; }

.b-fa-pagelines:before {
  content: "\f18c"; }

.b-fa-pager:before {
  content: "\f815"; }

.b-fa-paint-brush:before {
  content: "\f1fc"; }

.b-fa-paint-roller:before {
  content: "\f5aa"; }

.b-fa-palette:before {
  content: "\f53f"; }

.b-fa-palfed:before {
  content: "\f3d8"; }

.b-fa-pallet:before {
  content: "\f482"; }

.b-fa-paper-plane:before {
  content: "\f1d8"; }

.b-fa-paperclip:before {
  content: "\f0c6"; }

.b-fa-parachute-box:before {
  content: "\f4cd"; }

.b-fa-paragraph:before {
  content: "\f1dd"; }

.b-fa-parking:before {
  content: "\f540"; }

.b-fa-passport:before {
  content: "\f5ab"; }

.b-fa-pastafarianism:before {
  content: "\f67b"; }

.b-fa-paste:before {
  content: "\f0ea"; }

.b-fa-patreon:before {
  content: "\f3d9"; }

.b-fa-pause:before {
  content: "\f04c"; }

.b-fa-pause-circle:before {
  content: "\f28b"; }

.b-fa-paw:before {
  content: "\f1b0"; }

.b-fa-paypal:before {
  content: "\f1ed"; }

.b-fa-peace:before {
  content: "\f67c"; }

.b-fa-pen:before {
  content: "\f304"; }

.b-fa-pen-alt:before {
  content: "\f305"; }

.b-fa-pen-fancy:before {
  content: "\f5ac"; }

.b-fa-pen-nib:before {
  content: "\f5ad"; }

.b-fa-pen-square:before {
  content: "\f14b"; }

.b-fa-pencil-alt:before, .b-icon-edit:before {
  content: "\f303"; }

.b-fa-pencil-ruler:before {
  content: "\f5ae"; }

.b-fa-penny-arcade:before {
  content: "\f704"; }

.b-fa-people-arrows:before {
  content: "\f968"; }

.b-fa-people-carry:before {
  content: "\f4ce"; }

.b-fa-pepper-hot:before {
  content: "\f816"; }

.b-fa-percent:before {
  content: "\f295"; }

.b-fa-percentage:before {
  content: "\f541"; }

.b-fa-periscope:before {
  content: "\f3da"; }

.b-fa-person-booth:before {
  content: "\f756"; }

.b-fa-phabricator:before {
  content: "\f3db"; }

.b-fa-phoenix-framework:before {
  content: "\f3dc"; }

.b-fa-phoenix-squadron:before {
  content: "\f511"; }

.b-fa-phone:before {
  content: "\f095"; }

.b-fa-phone-alt:before {
  content: "\f879"; }

.b-fa-phone-slash:before {
  content: "\f3dd"; }

.b-fa-phone-square:before {
  content: "\f098"; }

.b-fa-phone-square-alt:before {
  content: "\f87b"; }

.b-fa-phone-volume:before {
  content: "\f2a0"; }

.b-fa-photo-video:before {
  content: "\f87c"; }

.b-fa-php:before {
  content: "\f457"; }

.b-fa-pied-piper:before {
  content: "\f2ae"; }

.b-fa-pied-piper-alt:before {
  content: "\f1a8"; }

.b-fa-pied-piper-hat:before {
  content: "\f4e5"; }

.b-fa-pied-piper-pp:before {
  content: "\f1a7"; }

.b-fa-pied-piper-square:before {
  content: "\f91e"; }

.b-fa-piggy-bank:before {
  content: "\f4d3"; }

.b-fa-pills:before {
  content: "\f484"; }

.b-fa-pinterest:before {
  content: "\f0d2"; }

.b-fa-pinterest-p:before {
  content: "\f231"; }

.b-fa-pinterest-square:before {
  content: "\f0d3"; }

.b-fa-pizza-slice:before {
  content: "\f818"; }

.b-fa-place-of-worship:before {
  content: "\f67f"; }

.b-fa-plane:before {
  content: "\f072"; }

.b-fa-plane-arrival:before {
  content: "\f5af"; }

.b-fa-plane-departure:before {
  content: "\f5b0"; }

.b-fa-plane-slash:before {
  content: "\f969"; }

.b-fa-play:before {
  content: "\f04b"; }

.b-fa-play-circle:before {
  content: "\f144"; }

.b-fa-playstation:before {
  content: "\f3df"; }

.b-fa-plug:before {
  content: "\f1e6"; }

.b-fa-plus:before, .b-icon-add:before {
  content: "\f067"; }

.b-fa-plus-circle:before, .b-icon-group-expand:before, .b-grid-group-collapsed .b-group-title:before {
  content: "\f055"; }

.b-fa-plus-square:before {
  content: "\f0fe"; }

.b-fa-podcast:before {
  content: "\f2ce"; }

.b-fa-poll:before {
  content: "\f681"; }

.b-fa-poll-h:before {
  content: "\f682"; }

.b-fa-poo:before {
  content: "\f2fe"; }

.b-fa-poo-storm:before {
  content: "\f75a"; }

.b-fa-poop:before {
  content: "\f619"; }

.b-fa-portrait:before {
  content: "\f3e0"; }

.b-fa-pound-sign:before {
  content: "\f154"; }

.b-fa-power-off:before {
  content: "\f011"; }

.b-fa-pray:before {
  content: "\f683"; }

.b-fa-praying-hands:before {
  content: "\f684"; }

.b-fa-prescription:before {
  content: "\f5b1"; }

.b-fa-prescription-bottle:before {
  content: "\f485"; }

.b-fa-prescription-bottle-alt:before {
  content: "\f486"; }

.b-fa-print:before {
  content: "\f02f"; }

.b-fa-procedures:before {
  content: "\f487"; }

.b-fa-product-hunt:before {
  content: "\f288"; }

.b-fa-project-diagram:before {
  content: "\f542"; }

.b-fa-pump-medical:before {
  content: "\f96a"; }

.b-fa-pump-soap:before {
  content: "\f96b"; }

.b-fa-pushed:before {
  content: "\f3e1"; }

.b-fa-puzzle-piece:before {
  content: "\f12e"; }

.b-fa-python:before {
  content: "\f3e2"; }

.b-fa-qq:before {
  content: "\f1d6"; }

.b-fa-qrcode:before {
  content: "\f029"; }

.b-fa-question:before {
  content: "\f128"; }

.b-fa-question-circle:before {
  content: "\f059"; }

.b-fa-quidditch:before {
  content: "\f458"; }

.b-fa-quinscape:before {
  content: "\f459"; }

.b-fa-quora:before {
  content: "\f2c4"; }

.b-fa-quote-left:before {
  content: "\f10d"; }

.b-fa-quote-right:before {
  content: "\f10e"; }

.b-fa-quran:before {
  content: "\f687"; }

.b-fa-r-project:before {
  content: "\f4f7"; }

.b-fa-radiation:before {
  content: "\f7b9"; }

.b-fa-radiation-alt:before {
  content: "\f7ba"; }

.b-fa-rainbow:before {
  content: "\f75b"; }

.b-fa-random:before {
  content: "\f074"; }

.b-fa-raspberry-pi:before {
  content: "\f7bb"; }

.b-fa-ravelry:before {
  content: "\f2d9"; }

.b-fa-react:before {
  content: "\f41b"; }

.b-fa-reacteurope:before {
  content: "\f75d"; }

.b-fa-readme:before {
  content: "\f4d5"; }

.b-fa-rebel:before {
  content: "\f1d0"; }

.b-fa-receipt:before {
  content: "\f543"; }

.b-fa-record-vinyl:before {
  content: "\f8d9"; }

.b-fa-recycle:before {
  content: "\f1b8"; }

.b-fa-red-river:before {
  content: "\f3e3"; }

.b-fa-reddit:before {
  content: "\f1a1"; }

.b-fa-reddit-alien:before {
  content: "\f281"; }

.b-fa-reddit-square:before {
  content: "\f1a2"; }

.b-fa-redhat:before {
  content: "\f7bc"; }

.b-fa-redo:before {
  content: "\f01e"; }

.b-fa-redo-alt:before {
  content: "\f2f9"; }

.b-fa-registered:before {
  content: "\f25d"; }

.b-fa-remove-format:before {
  content: "\f87d"; }

.b-fa-renren:before {
  content: "\f18b"; }

.b-fa-reply:before {
  content: "\f3e5"; }

.b-fa-reply-all:before {
  content: "\f122"; }

.b-fa-replyd:before {
  content: "\f3e6"; }

.b-fa-republican:before {
  content: "\f75e"; }

.b-fa-researchgate:before {
  content: "\f4f8"; }

.b-fa-resolving:before {
  content: "\f3e7"; }

.b-fa-restroom:before {
  content: "\f7bd"; }

.b-fa-retweet:before {
  content: "\f079"; }

.b-fa-rev:before {
  content: "\f5b2"; }

.b-fa-ribbon:before {
  content: "\f4d6"; }

.b-fa-ring:before {
  content: "\f70b"; }

.b-fa-road:before {
  content: "\f018"; }

.b-fa-robot:before {
  content: "\f544"; }

.b-fa-rocket:before {
  content: "\f135"; }

.b-fa-rocketchat:before {
  content: "\f3e8"; }

.b-fa-rockrms:before {
  content: "\f3e9"; }

.b-fa-route:before {
  content: "\f4d7"; }

.b-fa-rss:before {
  content: "\f09e"; }

.b-fa-rss-square:before {
  content: "\f143"; }

.b-fa-ruble-sign:before {
  content: "\f158"; }

.b-fa-ruler:before {
  content: "\f545"; }

.b-fa-ruler-combined:before {
  content: "\f546"; }

.b-fa-ruler-horizontal:before {
  content: "\f547"; }

.b-fa-ruler-vertical:before {
  content: "\f548"; }

.b-fa-running:before {
  content: "\f70c"; }

.b-fa-rupee-sign:before {
  content: "\f156"; }

.b-fa-sad-cry:before {
  content: "\f5b3"; }

.b-fa-sad-tear:before {
  content: "\f5b4"; }

.b-fa-safari:before {
  content: "\f267"; }

.b-fa-salesforce:before {
  content: "\f83b"; }

.b-fa-sass:before {
  content: "\f41e"; }

.b-fa-satellite:before {
  content: "\f7bf"; }

.b-fa-satellite-dish:before {
  content: "\f7c0"; }

.b-fa-save:before {
  content: "\f0c7"; }

.b-fa-schlix:before {
  content: "\f3ea"; }

.b-fa-school:before {
  content: "\f549"; }

.b-fa-screwdriver:before {
  content: "\f54a"; }

.b-fa-scribd:before {
  content: "\f28a"; }

.b-fa-scroll:before {
  content: "\f70e"; }

.b-fa-sd-card:before {
  content: "\f7c2"; }

.b-fa-search:before, .b-icon-search:before {
  content: "\f002"; }

.b-fa-search-dollar:before {
  content: "\f688"; }

.b-fa-search-location:before {
  content: "\f689"; }

.b-fa-search-minus:before, .b-icon-search-minus:before {
  content: "\f010"; }

.b-fa-search-plus:before, .b-icon-search-plus:before {
  content: "\f00e"; }

.b-fa-searchengin:before {
  content: "\f3eb"; }

.b-fa-seedling:before {
  content: "\f4d8"; }

.b-fa-sellcast:before {
  content: "\f2da"; }

.b-fa-sellsy:before {
  content: "\f213"; }

.b-fa-server:before {
  content: "\f233"; }

.b-fa-servicestack:before {
  content: "\f3ec"; }

.b-fa-shapes:before {
  content: "\f61f"; }

.b-fa-share:before {
  content: "\f064"; }

.b-fa-share-alt:before {
  content: "\f1e0"; }

.b-fa-share-alt-square:before {
  content: "\f1e1"; }

.b-fa-share-square:before {
  content: "\f14d"; }

.b-fa-shekel-sign:before {
  content: "\f20b"; }

.b-fa-shield-alt:before {
  content: "\f3ed"; }

.b-fa-shield-virus:before {
  content: "\f96c"; }

.b-fa-ship:before {
  content: "\f21a"; }

.b-fa-shipping-fast:before {
  content: "\f48b"; }

.b-fa-shirtsinbulk:before {
  content: "\f214"; }

.b-fa-shoe-prints:before {
  content: "\f54b"; }

.b-fa-shopify:before {
  content: "\f957"; }

.b-fa-shopping-bag:before {
  content: "\f290"; }

.b-fa-shopping-basket:before {
  content: "\f291"; }

.b-fa-shopping-cart:before {
  content: "\f07a"; }

.b-fa-shopware:before {
  content: "\f5b5"; }

.b-fa-shower:before {
  content: "\f2cc"; }

.b-fa-shuttle-van:before {
  content: "\f5b6"; }

.b-fa-sign:before {
  content: "\f4d9"; }

.b-fa-sign-in-alt:before {
  content: "\f2f6"; }

.b-fa-sign-language:before {
  content: "\f2a7"; }

.b-fa-sign-out-alt:before {
  content: "\f2f5"; }

.b-fa-signal:before, .b-icon-grouped-asc:before, .b-grid-header.b-group .b-grid-header-text::after {
  content: "\f012"; }

.b-fa-signature:before {
  content: "\f5b7"; }

.b-fa-sim-card:before {
  content: "\f7c4"; }

.b-fa-simplybuilt:before {
  content: "\f215"; }

.b-fa-sistrix:before {
  content: "\f3ee"; }

.b-fa-sitemap:before {
  content: "\f0e8"; }

.b-fa-sith:before {
  content: "\f512"; }

.b-fa-skating:before {
  content: "\f7c5"; }

.b-fa-sketch:before {
  content: "\f7c6"; }

.b-fa-skiing:before {
  content: "\f7c9"; }

.b-fa-skiing-nordic:before {
  content: "\f7ca"; }

.b-fa-skull:before {
  content: "\f54c"; }

.b-fa-skull-crossbones:before {
  content: "\f714"; }

.b-fa-skyatlas:before {
  content: "\f216"; }

.b-fa-skype:before {
  content: "\f17e"; }

.b-fa-slack:before {
  content: "\f198"; }

.b-fa-slack-hash:before {
  content: "\f3ef"; }

.b-fa-slash:before {
  content: "\f715"; }

.b-fa-sleigh:before {
  content: "\f7cc"; }

.b-fa-sliders-h:before {
  content: "\f1de"; }

.b-fa-slideshare:before {
  content: "\f1e7"; }

.b-fa-smile:before {
  content: "\f118"; }

.b-fa-smile-beam:before {
  content: "\f5b8"; }

.b-fa-smile-wink:before {
  content: "\f4da"; }

.b-fa-smog:before {
  content: "\f75f"; }

.b-fa-smoking:before {
  content: "\f48d"; }

.b-fa-smoking-ban:before {
  content: "\f54d"; }

.b-fa-sms:before {
  content: "\f7cd"; }

.b-fa-snapchat:before {
  content: "\f2ab"; }

.b-fa-snapchat-ghost:before {
  content: "\f2ac"; }

.b-fa-snapchat-square:before {
  content: "\f2ad"; }

.b-fa-snowboarding:before {
  content: "\f7ce"; }

.b-fa-snowflake:before {
  content: "\f2dc"; }

.b-fa-snowman:before {
  content: "\f7d0"; }

.b-fa-snowplow:before {
  content: "\f7d2"; }

.b-fa-soap:before {
  content: "\f96e"; }

.b-fa-socks:before {
  content: "\f696"; }

.b-fa-solar-panel:before {
  content: "\f5ba"; }

.b-fa-sort:before, .b-icon-sort:before {
  content: "\f0dc"; }

.b-fa-sort-alpha-down:before {
  content: "\f15d"; }

.b-fa-sort-alpha-down-alt:before, .b-icon-sort-desc:before {
  content: "\f881"; }

.b-fa-sort-alpha-up:before, .b-icon-sort-asc:before, .b-gridbase.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header.b-sortable.b-depth-0:hover:not(.b-sort):not(.b-group) .b-grid-header-text::after,
.b-gridbase.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header.b-sortable.b-depth-0:focus:not(.b-sort):not(.b-group) .b-grid-header-text::after {
  content: "\f15e"; }

.b-fa-sort-alpha-up-alt:before {
  content: "\f882"; }

.b-fa-sort-amount-down:before, .b-icon-group-desc:before {
  content: "\f160"; }

.b-fa-sort-amount-down-alt:before {
  content: "\f884"; }

.b-fa-sort-amount-up:before {
  content: "\f161"; }

.b-fa-sort-amount-up-alt:before, .b-icon-group-asc:before {
  content: "\f885"; }

.b-fa-sort-down:before {
  content: "\f0dd"; }

.b-fa-sort-numeric-down:before {
  content: "\f162"; }

.b-fa-sort-numeric-down-alt:before {
  content: "\f886"; }

.b-fa-sort-numeric-up:before {
  content: "\f163"; }

.b-fa-sort-numeric-up-alt:before {
  content: "\f887"; }

.b-fa-sort-up:before {
  content: "\f0de"; }

.b-fa-soundcloud:before {
  content: "\f1be"; }

.b-fa-sourcetree:before {
  content: "\f7d3"; }

.b-fa-spa:before {
  content: "\f5bb"; }

.b-fa-space-shuttle:before {
  content: "\f197"; }

.b-fa-speakap:before {
  content: "\f3f3"; }

.b-fa-speaker-deck:before {
  content: "\f83c"; }

.b-fa-spell-check:before {
  content: "\f891"; }

.b-fa-spider:before {
  content: "\f717"; }

.b-fa-spinner:before, .b-icon-spinner:before, .b-icon-checking:before {
  content: "\f110"; }

.b-fa-splotch:before {
  content: "\f5bc"; }

.b-fa-spotify:before {
  content: "\f1bc"; }

.b-fa-spray-can:before {
  content: "\f5bd"; }

.b-fa-square:before, .b-icon-unchecked:before {
  content: "\f0c8"; }

.b-fa-square-full:before {
  content: "\f45c"; }

.b-fa-square-root-alt:before {
  content: "\f698"; }

.b-fa-squarespace:before {
  content: "\f5be"; }

.b-fa-stack-exchange:before {
  content: "\f18d"; }

.b-fa-stack-overflow:before {
  content: "\f16c"; }

.b-fa-stackpath:before {
  content: "\f842"; }

.b-fa-stamp:before {
  content: "\f5bf"; }

.b-fa-star:before, .b-icon-star:before {
  content: "\f005"; }

.b-fa-star-and-crescent:before {
  content: "\f699"; }

.b-fa-star-half:before {
  content: "\f089"; }

.b-fa-star-half-alt:before {
  content: "\f5c0"; }

.b-fa-star-of-david:before {
  content: "\f69a"; }

.b-fa-star-of-life:before {
  content: "\f621"; }

.b-fa-staylinked:before {
  content: "\f3f5"; }

.b-fa-steam:before {
  content: "\f1b6"; }

.b-fa-steam-square:before {
  content: "\f1b7"; }

.b-fa-steam-symbol:before {
  content: "\f3f6"; }

.b-fa-step-backward:before {
  content: "\f048"; }

.b-fa-step-forward:before {
  content: "\f051"; }

.b-fa-stethoscope:before {
  content: "\f0f1"; }

.b-fa-sticker-mule:before {
  content: "\f3f7"; }

.b-fa-sticky-note:before {
  content: "\f249"; }

.b-fa-stop:before {
  content: "\f04d"; }

.b-fa-stop-circle:before {
  content: "\f28d"; }

.b-fa-stopwatch:before {
  content: "\f2f2"; }

.b-fa-stopwatch-20:before {
  content: "\f96f"; }

.b-fa-store:before {
  content: "\f54e"; }

.b-fa-store-alt:before {
  content: "\f54f"; }

.b-fa-store-alt-slash:before {
  content: "\f970"; }

.b-fa-store-slash:before {
  content: "\f971"; }

.b-fa-strava:before {
  content: "\f428"; }

.b-fa-stream:before {
  content: "\f550"; }

.b-fa-street-view:before {
  content: "\f21d"; }

.b-fa-strikethrough:before {
  content: "\f0cc"; }

.b-fa-stripe:before {
  content: "\f429"; }

.b-fa-stripe-s:before {
  content: "\f42a"; }

.b-fa-stroopwafel:before {
  content: "\f551"; }

.b-fa-studiovinari:before {
  content: "\f3f8"; }

.b-fa-stumbleupon:before {
  content: "\f1a4"; }

.b-fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.b-fa-subscript:before {
  content: "\f12c"; }

.b-fa-subway:before {
  content: "\f239"; }

.b-fa-suitcase:before {
  content: "\f0f2"; }

.b-fa-suitcase-rolling:before {
  content: "\f5c1"; }

.b-fa-sun:before {
  content: "\f185"; }

.b-fa-superpowers:before {
  content: "\f2dd"; }

.b-fa-superscript:before {
  content: "\f12b"; }

.b-fa-supple:before {
  content: "\f3f9"; }

.b-fa-surprise:before {
  content: "\f5c2"; }

.b-fa-suse:before {
  content: "\f7d6"; }

.b-fa-swatchbook:before {
  content: "\f5c3"; }

.b-fa-swift:before {
  content: "\f8e1"; }

.b-fa-swimmer:before {
  content: "\f5c4"; }

.b-fa-swimming-pool:before {
  content: "\f5c5"; }

.b-fa-symfony:before {
  content: "\f83d"; }

.b-fa-synagogue:before {
  content: "\f69b"; }

.b-fa-sync:before {
  content: "\f021"; }

.b-fa-sync-alt:before {
  content: "\f2f1"; }

.b-fa-syringe:before {
  content: "\f48e"; }

.b-fa-table:before {
  content: "\f0ce"; }

.b-fa-table-tennis:before {
  content: "\f45d"; }

.b-fa-tablet:before {
  content: "\f10a"; }

.b-fa-tablet-alt:before {
  content: "\f3fa"; }

.b-fa-tablets:before {
  content: "\f490"; }

.b-fa-tachometer-alt:before {
  content: "\f3fd"; }

.b-fa-tag:before {
  content: "\f02b"; }

.b-fa-tags:before {
  content: "\f02c"; }

.b-fa-tape:before {
  content: "\f4db"; }

.b-fa-tasks:before {
  content: "\f0ae"; }

.b-fa-taxi:before {
  content: "\f1ba"; }

.b-fa-teamspeak:before {
  content: "\f4f9"; }

.b-fa-teeth:before {
  content: "\f62e"; }

.b-fa-teeth-open:before {
  content: "\f62f"; }

.b-fa-telegram:before {
  content: "\f2c6"; }

.b-fa-telegram-plane:before {
  content: "\f3fe"; }

.b-fa-temperature-high:before {
  content: "\f769"; }

.b-fa-temperature-low:before {
  content: "\f76b"; }

.b-fa-tencent-weibo:before {
  content: "\f1d5"; }

.b-fa-tenge:before {
  content: "\f7d7"; }

.b-fa-terminal:before {
  content: "\f120"; }

.b-fa-text-height:before {
  content: "\f034"; }

.b-fa-text-width:before {
  content: "\f035"; }

.b-fa-th:before {
  content: "\f00a"; }

.b-fa-th-large:before {
  content: "\f009"; }

.b-fa-th-list:before {
  content: "\f00b"; }

.b-fa-the-red-yeti:before {
  content: "\f69d"; }

.b-fa-theater-masks:before {
  content: "\f630"; }

.b-fa-themeco:before {
  content: "\f5c6"; }

.b-fa-themeisle:before {
  content: "\f2b2"; }

.b-fa-thermometer:before {
  content: "\f491"; }

.b-fa-thermometer-empty:before {
  content: "\f2cb"; }

.b-fa-thermometer-full:before {
  content: "\f2c7"; }

.b-fa-thermometer-half:before {
  content: "\f2c9"; }

.b-fa-thermometer-quarter:before {
  content: "\f2ca"; }

.b-fa-thermometer-three-quarters:before {
  content: "\f2c8"; }

.b-fa-think-peaks:before {
  content: "\f731"; }

.b-fa-thumbs-down:before {
  content: "\f165"; }

.b-fa-thumbs-up:before {
  content: "\f164"; }

.b-fa-thumbtack:before {
  content: "\f08d"; }

.b-fa-ticket-alt:before {
  content: "\f3ff"; }

.b-fa-times:before, .b-icon-remove:before, .b-icon-clear:before {
  content: "\f00d"; }

.b-fa-times-circle:before, .b-icon-hide:before, .b-icon-hide-column:before {
  content: "\f057"; }

.b-fa-tint:before {
  content: "\f043"; }

.b-fa-tint-slash:before {
  content: "\f5c7"; }

.b-fa-tired:before {
  content: "\f5c8"; }

.b-fa-toggle-off:before {
  content: "\f204"; }

.b-fa-toggle-on:before {
  content: "\f205"; }

.b-fa-toilet:before {
  content: "\f7d8"; }

.b-fa-toilet-paper:before {
  content: "\f71e"; }

.b-fa-toilet-paper-slash:before {
  content: "\f972"; }

.b-fa-toolbox:before {
  content: "\f552"; }

.b-fa-tools:before {
  content: "\f7d9"; }

.b-fa-tooth:before {
  content: "\f5c9"; }

.b-fa-torah:before {
  content: "\f6a0"; }

.b-fa-torii-gate:before {
  content: "\f6a1"; }

.b-fa-tractor:before {
  content: "\f722"; }

.b-fa-trade-federation:before {
  content: "\f513"; }

.b-fa-trademark:before {
  content: "\f25c"; }

.b-fa-traffic-light:before {
  content: "\f637"; }

.b-fa-trailer:before {
  content: "\f941"; }

.b-fa-train:before {
  content: "\f238"; }

.b-fa-tram:before {
  content: "\f7da"; }

.b-fa-transgender:before {
  content: "\f224"; }

.b-fa-transgender-alt:before {
  content: "\f225"; }

.b-fa-trash:before, .b-icon-trash:before {
  content: "\f1f8"; }

.b-fa-trash-alt:before {
  content: "\f2ed"; }

.b-fa-trash-restore:before {
  content: "\f829"; }

.b-fa-trash-restore-alt:before {
  content: "\f82a"; }

.b-fa-tree:before {
  content: "\f1bb"; }

.b-fa-trello:before {
  content: "\f181"; }

.b-fa-tripadvisor:before {
  content: "\f262"; }

.b-fa-trophy:before {
  content: "\f091"; }

.b-fa-truck:before {
  content: "\f0d1"; }

.b-fa-truck-loading:before {
  content: "\f4de"; }

.b-fa-truck-monster:before {
  content: "\f63b"; }

.b-fa-truck-moving:before {
  content: "\f4df"; }

.b-fa-truck-pickup:before {
  content: "\f63c"; }

.b-fa-tshirt:before {
  content: "\f553"; }

.b-fa-tty:before {
  content: "\f1e4"; }

.b-fa-tumblr:before {
  content: "\f173"; }

.b-fa-tumblr-square:before {
  content: "\f174"; }

.b-fa-tv:before {
  content: "\f26c"; }

.b-fa-twitch:before {
  content: "\f1e8"; }

.b-fa-twitter:before {
  content: "\f099"; }

.b-fa-twitter-square:before {
  content: "\f081"; }

.b-fa-typo3:before {
  content: "\f42b"; }

.b-fa-uber:before {
  content: "\f402"; }

.b-fa-ubuntu:before {
  content: "\f7df"; }

.b-fa-uikit:before {
  content: "\f403"; }

.b-fa-umbraco:before {
  content: "\f8e8"; }

.b-fa-umbrella:before {
  content: "\f0e9"; }

.b-fa-umbrella-beach:before {
  content: "\f5ca"; }

.b-fa-underline:before {
  content: "\f0cd"; }

.b-fa-undo:before {
  content: "\f0e2"; }

.b-fa-undo-alt:before {
  content: "\f2ea"; }

.b-fa-uniregistry:before {
  content: "\f404"; }

.b-fa-unity:before {
  content: "\f949"; }

.b-fa-universal-access:before {
  content: "\f29a"; }

.b-fa-university:before {
  content: "\f19c"; }

.b-fa-unlink:before, .b-icon-unassign:before {
  content: "\f127"; }

.b-fa-unlock:before {
  content: "\f09c"; }

.b-fa-unlock-alt:before {
  content: "\f13e"; }

.b-fa-untappd:before {
  content: "\f405"; }

.b-fa-upload:before {
  content: "\f093"; }

.b-fa-ups:before {
  content: "\f7e0"; }

.b-fa-usb:before {
  content: "\f287"; }

.b-fa-user:before {
  content: "\f007"; }

.b-fa-user-alt:before {
  content: "\f406"; }

.b-fa-user-alt-slash:before {
  content: "\f4fa"; }

.b-fa-user-astronaut:before {
  content: "\f4fb"; }

.b-fa-user-check:before {
  content: "\f4fc"; }

.b-fa-user-circle:before {
  content: "\f2bd"; }

.b-fa-user-clock:before {
  content: "\f4fd"; }

.b-fa-user-cog:before {
  content: "\f4fe"; }

.b-fa-user-edit:before {
  content: "\f4ff"; }

.b-fa-user-friends:before {
  content: "\f500"; }

.b-fa-user-graduate:before {
  content: "\f501"; }

.b-fa-user-injured:before {
  content: "\f728"; }

.b-fa-user-lock:before {
  content: "\f502"; }

.b-fa-user-md:before {
  content: "\f0f0"; }

.b-fa-user-minus:before {
  content: "\f503"; }

.b-fa-user-ninja:before {
  content: "\f504"; }

.b-fa-user-nurse:before {
  content: "\f82f"; }

.b-fa-user-plus:before {
  content: "\f234"; }

.b-fa-user-secret:before {
  content: "\f21b"; }

.b-fa-user-shield:before {
  content: "\f505"; }

.b-fa-user-slash:before {
  content: "\f506"; }

.b-fa-user-tag:before {
  content: "\f507"; }

.b-fa-user-tie:before {
  content: "\f508"; }

.b-fa-user-times:before {
  content: "\f235"; }

.b-fa-users:before {
  content: "\f0c0"; }

.b-fa-users-cog:before {
  content: "\f509"; }

.b-fa-usps:before {
  content: "\f7e1"; }

.b-fa-ussunnah:before {
  content: "\f407"; }

.b-fa-utensil-spoon:before {
  content: "\f2e5"; }

.b-fa-utensils:before {
  content: "\f2e7"; }

.b-fa-vaadin:before {
  content: "\f408"; }

.b-fa-vector-square:before {
  content: "\f5cb"; }

.b-fa-venus:before {
  content: "\f221"; }

.b-fa-venus-double:before {
  content: "\f226"; }

.b-fa-venus-mars:before {
  content: "\f228"; }

.b-fa-viacoin:before {
  content: "\f237"; }

.b-fa-viadeo:before {
  content: "\f2a9"; }

.b-fa-viadeo-square:before {
  content: "\f2aa"; }

.b-fa-vial:before {
  content: "\f492"; }

.b-fa-vials:before {
  content: "\f493"; }

.b-fa-viber:before {
  content: "\f409"; }

.b-fa-video:before {
  content: "\f03d"; }

.b-fa-video-slash:before {
  content: "\f4e2"; }

.b-fa-vihara:before {
  content: "\f6a7"; }

.b-fa-vimeo:before {
  content: "\f40a"; }

.b-fa-vimeo-square:before {
  content: "\f194"; }

.b-fa-vimeo-v:before {
  content: "\f27d"; }

.b-fa-vine:before {
  content: "\f1ca"; }

.b-fa-virus:before {
  content: "\f974"; }

.b-fa-virus-slash:before {
  content: "\f975"; }

.b-fa-viruses:before {
  content: "\f976"; }

.b-fa-vk:before {
  content: "\f189"; }

.b-fa-vnv:before {
  content: "\f40b"; }

.b-fa-voicemail:before {
  content: "\f897"; }

.b-fa-volleyball-ball:before {
  content: "\f45f"; }

.b-fa-volume-down:before {
  content: "\f027"; }

.b-fa-volume-mute:before {
  content: "\f6a9"; }

.b-fa-volume-off:before {
  content: "\f026"; }

.b-fa-volume-up:before {
  content: "\f028"; }

.b-fa-vote-yea:before {
  content: "\f772"; }

.b-fa-vr-cardboard:before {
  content: "\f729"; }

.b-fa-vuejs:before {
  content: "\f41f"; }

.b-fa-walking:before {
  content: "\f554"; }

.b-fa-wallet:before {
  content: "\f555"; }

.b-fa-warehouse:before {
  content: "\f494"; }

.b-fa-water:before {
  content: "\f773"; }

.b-fa-wave-square:before {
  content: "\f83e"; }

.b-fa-waze:before {
  content: "\f83f"; }

.b-fa-weebly:before {
  content: "\f5cc"; }

.b-fa-weibo:before {
  content: "\f18a"; }

.b-fa-weight:before {
  content: "\f496"; }

.b-fa-weight-hanging:before {
  content: "\f5cd"; }

.b-fa-weixin:before {
  content: "\f1d7"; }

.b-fa-whatsapp:before {
  content: "\f232"; }

.b-fa-whatsapp-square:before {
  content: "\f40c"; }

.b-fa-wheelchair:before {
  content: "\f193"; }

.b-fa-whmcs:before {
  content: "\f40d"; }

.b-fa-wifi:before {
  content: "\f1eb"; }

.b-fa-wikipedia-w:before {
  content: "\f266"; }

.b-fa-wind:before {
  content: "\f72e"; }

.b-fa-window-close:before {
  content: "\f410"; }

.b-fa-window-maximize:before {
  content: "\f2d0"; }

.b-fa-window-minimize:before {
  content: "\f2d1"; }

.b-fa-window-restore:before {
  content: "\f2d2"; }

.b-fa-windows:before {
  content: "\f17a"; }

.b-fa-wine-bottle:before {
  content: "\f72f"; }

.b-fa-wine-glass:before {
  content: "\f4e3"; }

.b-fa-wine-glass-alt:before {
  content: "\f5ce"; }

.b-fa-wix:before {
  content: "\f5cf"; }

.b-fa-wizards-of-the-coast:before {
  content: "\f730"; }

.b-fa-wolf-pack-battalion:before {
  content: "\f514"; }

.b-fa-won-sign:before {
  content: "\f159"; }

.b-fa-wordpress:before {
  content: "\f19a"; }

.b-fa-wordpress-simple:before {
  content: "\f411"; }

.b-fa-wpbeginner:before {
  content: "\f297"; }

.b-fa-wpexplorer:before {
  content: "\f2de"; }

.b-fa-wpforms:before {
  content: "\f298"; }

.b-fa-wpressr:before {
  content: "\f3e4"; }

.b-fa-wrench:before {
  content: "\f0ad"; }

.b-fa-x-ray:before {
  content: "\f497"; }

.b-fa-xbox:before {
  content: "\f412"; }

.b-fa-xing:before {
  content: "\f168"; }

.b-fa-xing-square:before {
  content: "\f169"; }

.b-fa-y-combinator:before {
  content: "\f23b"; }

.b-fa-yahoo:before {
  content: "\f19e"; }

.b-fa-yammer:before {
  content: "\f840"; }

.b-fa-yandex:before {
  content: "\f413"; }

.b-fa-yandex-international:before {
  content: "\f414"; }

.b-fa-yarn:before {
  content: "\f7e3"; }

.b-fa-yelp:before {
  content: "\f1e9"; }

.b-fa-yen-sign:before {
  content: "\f157"; }

.b-fa-yin-yang:before {
  content: "\f6ad"; }

.b-fa-yoast:before {
  content: "\f2b1"; }

.b-fa-youtube:before {
  content: "\f167"; }

.b-fa-youtube-square:before {
  content: "\f431"; }

.b-fa-zhihu:before {
  content: "\f63f"; }

.b-fa-fw, .b-fw-icon:before {
  text-align: center;
  width: 1.25em; }

.b-fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.b-fa {
  font-family: inherit;
  line-height: inherit;
  -webkit-font-smoothing: inherit; }

.b-fa:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900; }

.b-icon:before, .b-row-reordering .b-row-reordering-target-parent .b-tree-cell::before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: 0; }

.b-fw-icon:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: 0; }

.b-content-icon, .b-checkbox .b-checkbox-label:before, .b-gridbase.b-columnresize.b-touch.b-column-resizing .b-grid-header.b-resizing::before, .b-touch .b-moving .b-grid-splitter-main::before, .b-grid-cell.b-group-title:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: 0; }

.b-icon-clock-live {
  background-color: #b0b1b2;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  position: relative;
  animation-delay: 0s; }
  .b-icon-clock-live:before, .b-icon-clock-live:after {
    position: absolute;
    display: block;
    font-size: 1em !important;
    content: "";
    width: .10em !important;
    left: .44em;
    /* half width of clock - half width of hand */
    background: #fff;
    border-radius: .5em; }
  .b-icon-clock-live:before {
    top: .15em;
    height: .4em !important;
    /* half the width/height of the clock */
    transform-origin: .05em .35em;
    animation: rotate 6s infinite linear;
    animation-play-state: paused;
    animation-delay: inherit; }
  .b-icon-clock-live:after {
    top: .22em;
    height: .33em;
    /* top + height for little hand must = top + height for big hand */
    transform-origin: .05em .3em;
    animation: rotate 72s infinite linear;
    /* time should be big-hand time * 12 */
    animation-play-state: paused;
    animation-delay: inherit; }

/* Animations */
@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.b-icon-spinner:before {
  animation: rotate 2s infinite linear; }

.b-color-blue {
  background-color: #3183fe; }

.b-color-deep-orange {
  background-color: #ff5722; }

.b-color-orange {
  background-color: #feac31; }

.b-color-amber {
  background-color: #ffc107; }

.b-color-indigo {
  background-color: #4531fe; }

.b-color-lime {
  background-color: #83fe31; }

.b-color-teal {
  background-color: #31feac; }

.b-color-green {
  background-color: #27ca37; }

.b-color-light-green {
  background-color: #9efea7; }

.b-color-purple {
  background-color: #fe31ea; }

.b-color-yellow {
  background-color: #ecfe26; }

.b-color-red {
  background-color: #e53f2c; }

.b-color-dark-gray {
  background-color: #474849; }

.b-color-gray {
  background-color: #b0b0b7; }

.b-color-light-gray {
  background-color: #e0e0e7; }

.b-color-white {
  background-color: #fff; }

.b-noselect {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.b-released,
.b-hide-display {
  display: none !important; }

.b-hide-visibility {
  visibility: hidden !important; }

.b-fx-highlight {
  animation: b-fx-highlight-animation 1s ease 0s 1; }

@keyframes b-fx-highlight-animation {
  0% {
    box-shadow: none; }
  50% {
    box-shadow: 0 0 10px 5px rgba(253, 126, 20, 0.5); }
  100% {
    box-shadow: none; } }

/**
 * DomHelper size monitoring polyfill for when ResizeObserver not available
 */
.b-resize-monitored {
  position: relative; }
  .b-resize-monitored .b-resize-monitors {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    overflow: hidden; }
    .b-resize-monitored .b-resize-monitors > * {
      width: 100%;
      height: 100%;
      overflow: hidden; }
    .b-resize-monitored .b-resize-monitors > .b-resize-monitor-expand:after {
      content: '';
      display: block;
      width: 100000px;
      height: 100000px; }
    .b-resize-monitored .b-resize-monitors > .b-resize-monitor-shrink:after {
      content: '';
      display: block;
      width: 200%;
      height: 200%;
      min-width: 1px;
      min-height: 1px; }

.b-float-root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 10;
  contain: strict;
  display: flex;
  justify-content: center;
  align-items: center; }
  .b-float-root > .b-floating {
    position: absolute;
    contain: layout style;
    pointer-events: all;
    transition: opacity 0.2s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
    top: 0;
    left: 0;
    overflow: visible;
    z-index: 11; }
    .b-firefox .b-float-root > .b-floating {
      contain: layout; }
    .b-float-root > .b-floating.b-hidden {
      opacity: 0; }
    .b-float-root > .b-floating.b-measure {
      display: block !important; }
  .b-float-root > .b-floating.b-centered {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.b-dragging {
  position: absolute !important;
  z-index: 10000;
  pointer-events: none;
  opacity: 0.8;
  box-sizing: border-box; }

/*
 Used when Drag code is creating a separate proxy element representing the dragged element,
   as opposed to the 'inline' drag of the actual element
*/
.b-drag-proxy {
  top: 0;
  left: 0;
  pointer-events: none !important;
  box-sizing: border-box; }
  .b-drag-proxy * {
    box-sizing: border-box; }

.b-aborting {
  transition: transform 0.2s; }

.b-drag-original.b-hidden {
  display: none !important; }

.simulated-mouse {
  position: absolute;
  z-index: 100;
  top: 10px;
  left: 10px;
  transition: top .5s, left .5s;
  pointer-events: none;
  font-size: 16px; }
  .simulated-mouse.quick {
    transition: top .05s, left .05s; }
  .simulated-mouse:after {
    position: absolute;
    content: '\f245';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    z-index: 102; }
  .simulated-mouse.drag:before, .simulated-mouse.mousedown:before, .simulated-mouse.dblclick:before, .simulated-mouse.click:before {
    position: absolute;
    content: '';
    border: 2px solid transparent;
    border-radius: 50%;
    animation-name: click;
    animation-duration: .2s;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    z-index: 101;
    opacity: 0.7; }
  .simulated-mouse.drag:after {
    content: '\f25a';
    left: -3px; }
  .simulated-mouse.dblclick:before {
    animation-name: dblclick;
    animation-duration: .3s; }
  .simulated-mouse.mousedown:before, .simulated-mouse.drag:before {
    animation-name: none;
    width: 1.5em;
    height: 1.5em;
    border-color: red; }

@keyframes click {
  0% {
    width: 0;
    height: 0; }
  90% {
    width: 1.5em;
    height: 1.5em;
    border-color: red; }
  100% {
    width: 0;
    height: 0;
    border-color: transparent; } }

@keyframes dblclick {
  0% {
    width: 0;
    height: 0; }
  40% {
    width: 1.5em;
    height: 1.5em;
    border-color: red; }
  50% {
    width: 0;
    height: 0; }
  90% {
    width: 1.5em;
    height: 1.5em;
    border-color: red; }
  100% {
    width: 0;
    height: 0;
    border-color: transparent; } }

.b-widget {
  display: inline-flex;
  overflow: hidden;
  line-height: normal;
  font-weight: 400;
  font-size: 1em;
  box-sizing: border-box; }
  .b-widget * {
    box-sizing: border-box; }
  .b-widget:before, .b-widget:after,
  .b-widget *:before,
  .b-widget *:after {
    box-sizing: border-box; }
  .b-widget.b-positioned {
    position: absolute;
    top: 0;
    left: 0; }
  .b-widget .b-focus-trap {
    position: absolute;
    display: none;
    clip: rect(0, 0, 0, 0); }
  .b-widget.b-focus-trapped.b-contains-focus .b-focus-trap {
    display: inherit; }
  .b-widget.b-hidden {
    display: none !important; }
  .b-widget.b-hiding {
    pointer-events: none; }
  .b-widget .b-widget-inner {
    width: 100%;
    position: relative;
    flex: 1; }
  .b-widget .b-anchor {
    width: 16px;
    height: 8px;
    position: absolute;
    z-index: -1; }
    .b-widget .b-anchor svg {
      position: absolute;
      top: 0;
      overflow: visible; }
    .b-widget .b-anchor.b-anchor-top {
      left: 0;
      bottom: calc(100% - 1px); }
    .b-widget .b-anchor.b-anchor-bottom {
      left: 0;
      top: calc(100% - 1px); }
      .b-widget .b-anchor.b-anchor-bottom svg {
        transform: rotate(180deg); }
    .b-widget .b-anchor.b-anchor-left {
      top: 0;
      width: 8px;
      height: 16px;
      right: calc(100% - 1px); }
      .b-widget .b-anchor.b-anchor-left svg {
        transform: rotate(270deg);
        transform-origin: 8px 8px; }
    .b-widget .b-anchor.b-anchor-right {
      top: 0;
      width: 8px;
      height: 16px;
      left: calc(100% - 1px); }
      .b-widget .b-anchor.b-anchor-right svg {
        transform: rotate(90deg);
        transform-origin: 4px 4px; }

.b-widget-scroller {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-anchor: none;
  /* without this we see weird issues in Mac Chrome <= 63 with no scrollbar, covered in Tree.t.js */ }
  .b-widget-scroller.b-hide-scroll {
    -ms-overflow-style: none; }
    .b-widget-scroller.b-hide-scroll::-webkit-scrollbar {
      display: none; }
    .b-firefox .b-widget-scroller.b-hide-scroll {
      overflow: hidden !important; }

.b-scroller-stretcher {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 1px; }

.b-widget.b-badge {
  overflow: visible; }

.b-badge:before {
  content: attr(data-badge);
  position: absolute;
  min-width: 2.223em;
  min-height: 2.223em;
  right: -1em;
  top: -1em;
  padding: .5em;
  background-color: #e53f2c;
  color: #fff;
  border-radius: 50%;
  font-size: .6em;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: b-badge-show;
  animation-duration: .3s; }

.b-button.b-badge:before {
  font-size: .7em; }

@keyframes b-badge-show {
  0% {
    opacity: 0;
    transform: scale(0.1) rotate(180deg); }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg); } }

.b-button {
  position: relative;
  padding: 0 1em;
  border-radius: 2px;
  min-width: 3em;
  min-height: 2.7em;
  border: 1px solid;
  cursor: pointer;
  font-weight: 400;
  transition: background-color 200ms, color 200ms, border 200ms;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase; }
  .b-button::-moz-focus-inner {
    border: 0; }
  .b-button.b-icon-align-start i {
    order: -100; }
  .b-button.b-icon-align-start label {
    margin-left: 0.5em; }
  .b-button.b-icon-align-end i {
    order: 100; }
  .b-button.b-icon-align-end label {
    margin-right: 0.5em; }
  .b-button i {
    font-size: 1em; }
  .b-button label:empty {
    display: none; }
  .b-button.b-badge {
    overflow: visible !important; }
  .b-button:focus {
    outline: none; }
  .b-button:disabled {
    cursor: default; }
  .b-toolbar .b-button {
    font-size: 0.9em; }

a.b-button {
  text-decoration: none; }

.b-button.b-white {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.03); }
  body.b-using-keyboard .b-button.b-white:focus {
    background-color: rgba(255, 255, 255, 0.3); }
  .b-button.b-white.b-pressed {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.8); }
  .b-button.b-white:hover:not([disabled]) {
    color: #fff;
    background-color: #fff; }
    .b-button.b-white:hover:not([disabled]):not(.b-pressed) {
      border-color: #fff; }
  .b-button.b-white.b-pressed:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.7); }
  .b-button.b-white:active:not([disabled]) {
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .b-button.b-white.b-disabled {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3); }

.b-button.b-blue {
  color: #3183fe;
  border-color: rgba(49, 131, 254, 0.5);
  background-color: rgba(49, 131, 254, 0.03); }
  body.b-using-keyboard .b-button.b-blue:focus {
    background-color: rgba(49, 131, 254, 0.3); }
  .b-button.b-blue.b-pressed {
    color: #fff;
    background-color: rgba(49, 131, 254, 0.8); }
  .b-button.b-blue:hover:not([disabled]) {
    color: #fff;
    background-color: #3183fe; }
    .b-button.b-blue:hover:not([disabled]):not(.b-pressed) {
      border-color: #3183fe; }
  .b-button.b-blue.b-pressed:hover {
    color: #fff;
    background-color: rgba(49, 131, 254, 0.7); }
  .b-button.b-blue:active:not([disabled]) {
    background-color: #1874fe;
    border-color: #1874fe; }
  .b-button.b-blue.b-disabled {
    background-color: transparent;
    color: rgba(49, 131, 254, 0.3);
    border-color: rgba(49, 131, 254, 0.3); }

.b-button.b-deep-orange {
  color: #ff5722;
  border-color: rgba(255, 87, 34, 0.5);
  background-color: rgba(255, 87, 34, 0.03); }
  body.b-using-keyboard .b-button.b-deep-orange:focus {
    background-color: rgba(255, 87, 34, 0.3); }
  .b-button.b-deep-orange.b-pressed {
    color: #fff;
    background-color: rgba(255, 87, 34, 0.8); }
  .b-button.b-deep-orange:hover:not([disabled]) {
    color: #fff;
    background-color: #ff5722; }
    .b-button.b-deep-orange:hover:not([disabled]):not(.b-pressed) {
      border-color: #ff5722; }
  .b-button.b-deep-orange.b-pressed:hover {
    color: #fff;
    background-color: rgba(255, 87, 34, 0.7); }
  .b-button.b-deep-orange:active:not([disabled]) {
    background-color: #ff4409;
    border-color: #ff4409; }
  .b-button.b-deep-orange.b-disabled {
    background-color: transparent;
    color: rgba(255, 87, 34, 0.3);
    border-color: rgba(255, 87, 34, 0.3); }

.b-button.b-orange {
  color: #feac31;
  border-color: rgba(254, 172, 49, 0.5);
  background-color: rgba(254, 172, 49, 0.03); }
  body.b-using-keyboard .b-button.b-orange:focus {
    background-color: rgba(254, 172, 49, 0.3); }
  .b-button.b-orange.b-pressed {
    color: #fff;
    background-color: rgba(254, 172, 49, 0.8); }
  .b-button.b-orange:hover:not([disabled]) {
    color: #fff;
    background-color: #feac31; }
    .b-button.b-orange:hover:not([disabled]):not(.b-pressed) {
      border-color: #feac31; }
  .b-button.b-orange.b-pressed:hover {
    color: #fff;
    background-color: rgba(254, 172, 49, 0.7); }
  .b-button.b-orange:active:not([disabled]) {
    background-color: #fea218;
    border-color: #fea218; }
  .b-button.b-orange.b-disabled {
    background-color: transparent;
    color: rgba(254, 172, 49, 0.3);
    border-color: rgba(254, 172, 49, 0.3); }

.b-button.b-amber {
  color: #ffc107;
  border-color: rgba(255, 193, 7, 0.5);
  background-color: rgba(255, 193, 7, 0.03); }
  body.b-using-keyboard .b-button.b-amber:focus {
    background-color: rgba(255, 193, 7, 0.3); }
  .b-button.b-amber.b-pressed {
    color: #fff;
    background-color: rgba(255, 193, 7, 0.8); }
  .b-button.b-amber:hover:not([disabled]) {
    color: #fff;
    background-color: #ffc107; }
    .b-button.b-amber:hover:not([disabled]):not(.b-pressed) {
      border-color: #ffc107; }
  .b-button.b-amber.b-pressed:hover {
    color: #fff;
    background-color: rgba(255, 193, 7, 0.7); }
  .b-button.b-amber:active:not([disabled]) {
    background-color: #edb100;
    border-color: #edb100; }
  .b-button.b-amber.b-disabled {
    background-color: transparent;
    color: rgba(255, 193, 7, 0.3);
    border-color: rgba(255, 193, 7, 0.3); }

.b-button.b-indigo {
  color: #4531fe;
  border-color: rgba(69, 49, 254, 0.5);
  background-color: rgba(69, 49, 254, 0.03); }
  body.b-using-keyboard .b-button.b-indigo:focus {
    background-color: rgba(69, 49, 254, 0.3); }
  .b-button.b-indigo.b-pressed {
    color: #fff;
    background-color: rgba(69, 49, 254, 0.8); }
  .b-button.b-indigo:hover:not([disabled]) {
    color: #fff;
    background-color: #4531fe; }
    .b-button.b-indigo:hover:not([disabled]):not(.b-pressed) {
      border-color: #4531fe; }
  .b-button.b-indigo.b-pressed:hover {
    color: #fff;
    background-color: rgba(69, 49, 254, 0.7); }
  .b-button.b-indigo:active:not([disabled]) {
    background-color: #2e18fe;
    border-color: #2e18fe; }
  .b-button.b-indigo.b-disabled {
    background-color: transparent;
    color: rgba(69, 49, 254, 0.3);
    border-color: rgba(69, 49, 254, 0.3); }

.b-button.b-lime {
  color: #83fe31;
  border-color: rgba(131, 254, 49, 0.5);
  background-color: rgba(131, 254, 49, 0.03); }
  body.b-using-keyboard .b-button.b-lime:focus {
    background-color: rgba(131, 254, 49, 0.3); }
  .b-button.b-lime.b-pressed {
    color: #fff;
    background-color: rgba(131, 254, 49, 0.8); }
  .b-button.b-lime:hover:not([disabled]) {
    color: #fff;
    background-color: #83fe31; }
    .b-button.b-lime:hover:not([disabled]):not(.b-pressed) {
      border-color: #83fe31; }
  .b-button.b-lime.b-pressed:hover {
    color: #fff;
    background-color: rgba(131, 254, 49, 0.7); }
  .b-button.b-lime:active:not([disabled]) {
    background-color: #74fe18;
    border-color: #74fe18; }
  .b-button.b-lime.b-disabled {
    background-color: transparent;
    color: rgba(131, 254, 49, 0.3);
    border-color: rgba(131, 254, 49, 0.3); }

.b-button.b-teal {
  color: #31feac;
  border-color: rgba(49, 254, 172, 0.5);
  background-color: rgba(49, 254, 172, 0.03); }
  body.b-using-keyboard .b-button.b-teal:focus {
    background-color: rgba(49, 254, 172, 0.3); }
  .b-button.b-teal.b-pressed {
    color: #fff;
    background-color: rgba(49, 254, 172, 0.8); }
  .b-button.b-teal:hover:not([disabled]) {
    color: #fff;
    background-color: #31feac; }
    .b-button.b-teal:hover:not([disabled]):not(.b-pressed) {
      border-color: #31feac; }
  .b-button.b-teal.b-pressed:hover {
    color: #fff;
    background-color: rgba(49, 254, 172, 0.7); }
  .b-button.b-teal:active:not([disabled]) {
    background-color: #18fea2;
    border-color: #18fea2; }
  .b-button.b-teal.b-disabled {
    background-color: transparent;
    color: rgba(49, 254, 172, 0.3);
    border-color: rgba(49, 254, 172, 0.3); }

.b-button.b-green {
  color: #27ca37;
  border-color: rgba(39, 202, 55, 0.5);
  background-color: rgba(39, 202, 55, 0.03); }
  body.b-using-keyboard .b-button.b-green:focus {
    background-color: rgba(39, 202, 55, 0.3); }
  .b-button.b-green.b-pressed {
    color: #fff;
    background-color: rgba(39, 202, 55, 0.8); }
  .b-button.b-green:hover:not([disabled]) {
    color: #fff;
    background-color: #27ca37; }
    .b-button.b-green:hover:not([disabled]):not(.b-pressed) {
      border-color: #27ca37; }
  .b-button.b-green.b-pressed:hover {
    color: #fff;
    background-color: rgba(39, 202, 55, 0.7); }
  .b-button.b-green:active:not([disabled]) {
    background-color: #23b531;
    border-color: #23b531; }
  .b-button.b-green.b-disabled {
    background-color: transparent;
    color: rgba(39, 202, 55, 0.3);
    border-color: rgba(39, 202, 55, 0.3); }

.b-button.b-light-green {
  color: #9efea7;
  border-color: rgba(158, 254, 167, 0.5);
  background-color: rgba(158, 254, 167, 0.03); }
  body.b-using-keyboard .b-button.b-light-green:focus {
    background-color: rgba(158, 254, 167, 0.3); }
  .b-button.b-light-green.b-pressed {
    color: #fff;
    background-color: rgba(158, 254, 167, 0.8); }
  .b-button.b-light-green:hover:not([disabled]) {
    color: #fff;
    background-color: #9efea7; }
    .b-button.b-light-green:hover:not([disabled]):not(.b-pressed) {
      border-color: #9efea7; }
  .b-button.b-light-green.b-pressed:hover {
    color: #fff;
    background-color: rgba(158, 254, 167, 0.7); }
  .b-button.b-light-green:active:not([disabled]) {
    background-color: #85fe90;
    border-color: #85fe90; }
  .b-button.b-light-green.b-disabled {
    background-color: transparent;
    color: rgba(158, 254, 167, 0.3);
    border-color: rgba(158, 254, 167, 0.3); }

.b-button.b-purple {
  color: #fe31ea;
  border-color: rgba(254, 49, 234, 0.5);
  background-color: rgba(254, 49, 234, 0.03); }
  body.b-using-keyboard .b-button.b-purple:focus {
    background-color: rgba(254, 49, 234, 0.3); }
  .b-button.b-purple.b-pressed {
    color: #fff;
    background-color: rgba(254, 49, 234, 0.8); }
  .b-button.b-purple:hover:not([disabled]) {
    color: #fff;
    background-color: #fe31ea; }
    .b-button.b-purple:hover:not([disabled]):not(.b-pressed) {
      border-color: #fe31ea; }
  .b-button.b-purple.b-pressed:hover {
    color: #fff;
    background-color: rgba(254, 49, 234, 0.7); }
  .b-button.b-purple:active:not([disabled]) {
    background-color: #fe18e7;
    border-color: #fe18e7; }
  .b-button.b-purple.b-disabled {
    background-color: transparent;
    color: rgba(254, 49, 234, 0.3);
    border-color: rgba(254, 49, 234, 0.3); }

.b-button.b-yellow {
  color: #ecfe26;
  border-color: rgba(236, 254, 38, 0.5);
  background-color: rgba(236, 254, 38, 0.03); }
  body.b-using-keyboard .b-button.b-yellow:focus {
    background-color: rgba(236, 254, 38, 0.3); }
  .b-button.b-yellow.b-pressed {
    color: #fff;
    background-color: rgba(236, 254, 38, 0.8); }
  .b-button.b-yellow:hover:not([disabled]) {
    color: #fff;
    background-color: #ecfe26; }
    .b-button.b-yellow:hover:not([disabled]):not(.b-pressed) {
      border-color: #ecfe26; }
  .b-button.b-yellow.b-pressed:hover {
    color: #fff;
    background-color: rgba(236, 254, 38, 0.7); }
  .b-button.b-yellow:active:not([disabled]) {
    background-color: #eafe0d;
    border-color: #eafe0d; }
  .b-button.b-yellow.b-disabled {
    background-color: transparent;
    color: rgba(236, 254, 38, 0.3);
    border-color: rgba(236, 254, 38, 0.3); }

.b-button.b-red {
  color: #e53f2c;
  border-color: rgba(229, 63, 44, 0.5);
  background-color: rgba(229, 63, 44, 0.03); }
  body.b-using-keyboard .b-button.b-red:focus {
    background-color: rgba(229, 63, 44, 0.3); }
  .b-button.b-red.b-pressed {
    color: #fff;
    background-color: rgba(229, 63, 44, 0.8); }
  .b-button.b-red:hover:not([disabled]) {
    color: #fff;
    background-color: #e53f2c; }
    .b-button.b-red:hover:not([disabled]):not(.b-pressed) {
      border-color: #e53f2c; }
  .b-button.b-red.b-pressed:hover {
    color: #fff;
    background-color: rgba(229, 63, 44, 0.7); }
  .b-button.b-red:active:not([disabled]) {
    background-color: #dc2f1b;
    border-color: #dc2f1b; }
  .b-button.b-red.b-disabled {
    background-color: transparent;
    color: rgba(229, 63, 44, 0.3);
    border-color: rgba(229, 63, 44, 0.3); }

.b-button.b-dark-gray {
  color: #474849;
  border-color: rgba(71, 72, 73, 0.5);
  background-color: rgba(71, 72, 73, 0.03); }
  body.b-using-keyboard .b-button.b-dark-gray:focus {
    background-color: rgba(71, 72, 73, 0.3); }
  .b-button.b-dark-gray.b-pressed {
    color: #fff;
    background-color: rgba(71, 72, 73, 0.8); }
  .b-button.b-dark-gray:hover:not([disabled]) {
    color: #fff;
    background-color: #474849; }
    .b-button.b-dark-gray:hover:not([disabled]):not(.b-pressed) {
      border-color: #474849; }
  .b-button.b-dark-gray.b-pressed:hover {
    color: #fff;
    background-color: rgba(71, 72, 73, 0.7); }
  .b-button.b-dark-gray:active:not([disabled]) {
    background-color: #3a3b3c;
    border-color: #3a3b3c; }
  .b-button.b-dark-gray.b-disabled {
    background-color: transparent;
    color: rgba(71, 72, 73, 0.3);
    border-color: rgba(71, 72, 73, 0.3); }

.b-button.b-gray {
  color: #b0b0b7;
  border-color: rgba(176, 176, 183, 0.5);
  background-color: rgba(176, 176, 183, 0.03); }
  body.b-using-keyboard .b-button.b-gray:focus {
    background-color: rgba(176, 176, 183, 0.3); }
  .b-button.b-gray.b-pressed {
    color: #fff;
    background-color: rgba(176, 176, 183, 0.8); }
  .b-button.b-gray:hover:not([disabled]) {
    color: #fff;
    background-color: #b0b0b7; }
    .b-button.b-gray:hover:not([disabled]):not(.b-pressed) {
      border-color: #b0b0b7; }
  .b-button.b-gray.b-pressed:hover {
    color: #fff;
    background-color: rgba(176, 176, 183, 0.7); }
  .b-button.b-gray:active:not([disabled]) {
    background-color: #a3a3ab;
    border-color: #a3a3ab; }
  .b-button.b-gray.b-disabled {
    background-color: transparent;
    color: rgba(176, 176, 183, 0.3);
    border-color: rgba(176, 176, 183, 0.3); }

.b-button.b-light-gray {
  color: #e0e0e7;
  border-color: rgba(224, 224, 231, 0.5);
  background-color: rgba(224, 224, 231, 0.03); }
  body.b-using-keyboard .b-button.b-light-gray:focus {
    background-color: rgba(224, 224, 231, 0.3); }
  .b-button.b-light-gray.b-pressed {
    color: #fff;
    background-color: rgba(224, 224, 231, 0.8); }
  .b-button.b-light-gray:hover:not([disabled]) {
    color: #fff;
    background-color: #e0e0e7; }
    .b-button.b-light-gray:hover:not([disabled]):not(.b-pressed) {
      border-color: #e0e0e7; }
  .b-button.b-light-gray.b-pressed:hover {
    color: #fff;
    background-color: rgba(224, 224, 231, 0.7); }
  .b-button.b-light-gray:active:not([disabled]) {
    background-color: #d2d2dc;
    border-color: #d2d2dc; }
  .b-button.b-light-gray.b-disabled {
    background-color: transparent;
    color: rgba(224, 224, 231, 0.3);
    border-color: rgba(224, 224, 231, 0.3); }

.b-button {
  color: #b0b0b7;
  border-color: rgba(176, 176, 183, 0.5);
  background-color: rgba(176, 176, 183, 0.03); }
  body.b-using-keyboard .b-button:focus {
    background-color: rgba(176, 176, 183, 0.3); }
  .b-button.b-pressed {
    color: #fff;
    background-color: rgba(176, 176, 183, 0.8); }
  .b-button:hover:not([disabled]) {
    color: #fff;
    background-color: #b0b0b7; }
    .b-button:hover:not([disabled]):not(.b-pressed) {
      border-color: #b0b0b7; }
  .b-button.b-pressed:hover {
    color: #fff;
    background-color: rgba(176, 176, 183, 0.7); }
  .b-button:active:not([disabled]) {
    background-color: #a3a3ab;
    border-color: #a3a3ab; }
  .b-button.b-disabled {
    background-color: transparent;
    color: rgba(176, 176, 183, 0.3);
    border-color: rgba(176, 176, 183, 0.3); }

.b-ie.b-button,
.b-ie .b-button {
  height: 2.7em; }

.b-button.b-raised.b-blue {
  color: #fff;
  border: none;
  background-color: #3183fe; }
  body.b-using-keyboard .b-button.b-raised.b-blue:focus {
    background-color: #5f9efe; }
  body.b-using-keyboard .b-button.b-raised.b-blue:focus:hover:not([disabled]), .b-button.b-raised.b-blue:hover:not([disabled]) {
    background-color: #468ffe; }
  .b-button.b-raised.b-blue.b-pressed, .b-button.b-raised.b-blue.b-pressed[data-group]:hover {
    background-color: #0468fe;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-blue.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-blue.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-blue.b-pressed:focus {
    background-color: #227afe; }
  .b-button.b-raised.b-blue.b-pressed:active {
    background-color: #014fc4; }
  .b-button.b-raised.b-blue.b-pressed:active:hover {
    background-color: #015be2; }
  .b-button.b-raised.b-blue:active:not([disabled]) {
    background-color: #0161f1;
    border-color: #0161f1;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-blue:active:hover:not([disabled]) {
    background-color: #1371fe;
    border-color: #1371fe;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-blue:disabled:hover, .b-button.b-raised.b-blue:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #0151c8;
    cursor: default; }

.b-button.b-raised.b-deep-orange {
  color: #fff;
  border: none;
  background-color: #ff5722; }
  body.b-using-keyboard .b-button.b-raised.b-deep-orange:focus {
    background-color: #ff7a50; }
  body.b-using-keyboard .b-button.b-raised.b-deep-orange:focus:hover:not([disabled]), .b-button.b-raised.b-deep-orange:hover:not([disabled]) {
    background-color: #ff6838; }
  .b-button.b-raised.b-deep-orange.b-pressed, .b-button.b-raised.b-deep-orange.b-pressed[data-group]:hover {
    background-color: #f63b00;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-deep-orange.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-deep-orange.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-deep-orange.b-pressed:focus {
    background-color: #ff4c14; }
  .b-button.b-raised.b-deep-orange.b-pressed:active {
    background-color: #bc2d00; }
  .b-button.b-raised.b-deep-orange.b-pressed:active:hover {
    background-color: #d93400; }
  .b-button.b-raised.b-deep-orange:active:not([disabled]) {
    background-color: #e73700;
    border-color: #e73700;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-deep-orange:active:hover:not([disabled]) {
    background-color: #ff4105;
    border-color: #ff4105;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-deep-orange:disabled:hover, .b-button.b-raised.b-deep-orange:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #bb2d00;
    cursor: default; }

.b-button.b-raised.b-orange {
  color: #fff;
  border: none;
  background-color: #feac31; }
  body.b-using-keyboard .b-button.b-raised.b-orange:focus {
    background-color: #febe5f; }
  body.b-using-keyboard .b-button.b-raised.b-orange:focus:hover:not([disabled]), .b-button.b-raised.b-orange:hover:not([disabled]) {
    background-color: #feb446; }
  .b-button.b-raised.b-orange.b-pressed, .b-button.b-raised.b-orange.b-pressed[data-group]:hover {
    background-color: #fe9a04;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-orange.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-orange.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-orange.b-pressed:focus {
    background-color: #fea622; }
  .b-button.b-raised.b-orange.b-pressed:active {
    background-color: #c47601; }
  .b-button.b-raised.b-orange.b-pressed:active:hover {
    background-color: #e28801; }
  .b-button.b-raised.b-orange:active:not([disabled]) {
    background-color: #f19101;
    border-color: #f19101;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-orange:active:hover:not([disabled]) {
    background-color: #fea013;
    border-color: #fea013;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-orange:disabled:hover, .b-button.b-raised.b-orange:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #c87801;
    cursor: default; }

.b-button.b-raised.b-amber {
  color: #fff;
  border: none;
  background-color: #ffc107; }
  body.b-using-keyboard .b-button.b-raised.b-amber:focus {
    background-color: #ffcc35; }
  body.b-using-keyboard .b-button.b-raised.b-amber:focus:hover:not([disabled]), .b-button.b-raised.b-amber:hover:not([disabled]) {
    background-color: #ffc720; }
  .b-button.b-raised.b-amber.b-pressed, .b-button.b-raised.b-amber.b-pressed[data-group]:hover {
    background-color: #dfa700;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-amber.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-amber.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-amber.b-pressed:focus {
    background-color: #f9bb00; }
  .b-button.b-raised.b-amber.b-pressed:active {
    background-color: #aa8000; }
  .b-button.b-raised.b-amber.b-pressed:active:hover {
    background-color: #c59300; }
  .b-button.b-raised.b-amber:active:not([disabled]) {
    background-color: #d29d00;
    border-color: #d29d00;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-amber:active:hover:not([disabled]) {
    background-color: #ecb100;
    border-color: #ecb100;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-amber:disabled:hover, .b-button.b-raised.b-amber:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #a07800;
    cursor: default; }

.b-button.b-raised.b-indigo {
  color: #fff;
  border: none;
  background-color: #4531fe; }
  body.b-using-keyboard .b-button.b-raised.b-indigo:focus {
    background-color: #6e5ffe; }
  body.b-using-keyboard .b-button.b-raised.b-indigo:focus:hover:not([disabled]), .b-button.b-raised.b-indigo:hover:not([disabled]) {
    background-color: #5846fe; }
  .b-button.b-raised.b-indigo.b-pressed, .b-button.b-raised.b-indigo.b-pressed[data-group]:hover {
    background-color: #1c04fe;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-indigo.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-indigo.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-indigo.b-pressed:focus {
    background-color: #3722fe; }
  .b-button.b-raised.b-indigo.b-pressed:active {
    background-color: #1401c4; }
  .b-button.b-raised.b-indigo.b-pressed:active:hover {
    background-color: #1701e2; }
  .b-button.b-raised.b-indigo:active:not([disabled]) {
    background-color: #1901f1;
    border-color: #1901f1;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-indigo:active:hover:not([disabled]) {
    background-color: #2a13fe;
    border-color: #2a13fe;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-indigo:disabled:hover, .b-button.b-raised.b-indigo:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #1401c8;
    cursor: default; }

.b-button.b-raised.b-lime {
  color: #fff;
  border: none;
  background-color: #83fe31; }
  body.b-using-keyboard .b-button.b-raised.b-lime:focus {
    background-color: #9efe5f; }
  body.b-using-keyboard .b-button.b-raised.b-lime:focus:hover:not([disabled]), .b-button.b-raised.b-lime:hover:not([disabled]) {
    background-color: #8ffe46; }
  .b-button.b-raised.b-lime.b-pressed, .b-button.b-raised.b-lime.b-pressed[data-group]:hover {
    background-color: #68fe04;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-lime.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-lime.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-lime.b-pressed:focus {
    background-color: #7afe22; }
  .b-button.b-raised.b-lime.b-pressed:active {
    background-color: #4fc401; }
  .b-button.b-raised.b-lime.b-pressed:active:hover {
    background-color: #5be201; }
  .b-button.b-raised.b-lime:active:not([disabled]) {
    background-color: #61f101;
    border-color: #61f101;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-lime:active:hover:not([disabled]) {
    background-color: #71fe13;
    border-color: #71fe13;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-lime:disabled:hover, .b-button.b-raised.b-lime:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #51c801;
    cursor: default; }

.b-button.b-raised.b-teal {
  color: #fff;
  border: none;
  background-color: #31feac; }
  body.b-using-keyboard .b-button.b-raised.b-teal:focus {
    background-color: #5ffebe; }
  body.b-using-keyboard .b-button.b-raised.b-teal:focus:hover:not([disabled]), .b-button.b-raised.b-teal:hover:not([disabled]) {
    background-color: #46feb4; }
  .b-button.b-raised.b-teal.b-pressed, .b-button.b-raised.b-teal.b-pressed[data-group]:hover {
    background-color: #04fe9a;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-teal.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-teal.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-teal.b-pressed:focus {
    background-color: #22fea6; }
  .b-button.b-raised.b-teal.b-pressed:active {
    background-color: #01c476; }
  .b-button.b-raised.b-teal.b-pressed:active:hover {
    background-color: #01e288; }
  .b-button.b-raised.b-teal:active:not([disabled]) {
    background-color: #01f191;
    border-color: #01f191;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-teal:active:hover:not([disabled]) {
    background-color: #13fea0;
    border-color: #13fea0;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-teal:disabled:hover, .b-button.b-raised.b-teal:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #01c878;
    cursor: default; }

.b-button.b-raised.b-green {
  color: #fff;
  border: none;
  background-color: #27ca37; }
  body.b-using-keyboard .b-button.b-raised.b-green:focus {
    background-color: #44db53; }
  body.b-using-keyboard .b-button.b-raised.b-green:focus:hover:not([disabled]), .b-button.b-raised.b-green:hover:not([disabled]) {
    background-color: #34d844; }
  .b-button.b-raised.b-green.b-pressed, .b-button.b-raised.b-green.b-pressed[data-group]:hover {
    background-color: #21ac2f;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-green.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-green.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-green.b-pressed:focus {
    background-color: #25c034; }
  .b-button.b-raised.b-green.b-pressed:active {
    background-color: #198324; }
  .b-button.b-raised.b-green.b-pressed:active:hover {
    background-color: #1d9829; }
  .b-button.b-raised.b-green:active:not([disabled]) {
    background-color: #1fa22c;
    border-color: #1fa22c;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-green:active:hover:not([disabled]) {
    background-color: #23b632;
    border-color: #23b632;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-green:disabled:hover, .b-button.b-raised.b-green:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #167520;
    cursor: default; }

.b-button.b-raised.b-light-green {
  color: #fff;
  border: none;
  background-color: #9efea7; }
  body.b-using-keyboard .b-button.b-raised.b-light-green:focus {
    background-color: #cbfed0; }
  body.b-using-keyboard .b-button.b-raised.b-light-green:focus:hover:not([disabled]), .b-button.b-raised.b-light-green:hover:not([disabled]) {
    background-color: #a8feb0; }
  .b-button.b-raised.b-light-green.b-pressed, .b-button.b-raised.b-light-green.b-pressed[data-group]:hover {
    background-color: #61fd70;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-light-green.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-light-green.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-light-green.b-pressed:focus {
    background-color: #8afe95; }
  .b-button.b-raised.b-light-green.b-pressed:active {
    background-color: #0ffd26; }
  .b-button.b-raised.b-light-green.b-pressed:active:hover {
    background-color: #38fd4b; }
  .b-button.b-raised.b-light-green:active:not([disabled]) {
    background-color: #4cfd5d;
    border-color: #4cfd5d;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-light-green:active:hover:not([disabled]) {
    background-color: #75fe82;
    border-color: #75fe82;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-light-green:disabled:hover, .b-button.b-raised.b-light-green:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #39fd4b;
    cursor: default; }

.b-button.b-raised.b-purple {
  color: #fff;
  border: none;
  background-color: #fe31ea; }
  body.b-using-keyboard .b-button.b-raised.b-purple:focus {
    background-color: #fe5fef; }
  body.b-using-keyboard .b-button.b-raised.b-purple:focus:hover:not([disabled]), .b-button.b-raised.b-purple:hover:not([disabled]) {
    background-color: #fe46ec; }
  .b-button.b-raised.b-purple.b-pressed, .b-button.b-raised.b-purple.b-pressed[data-group]:hover {
    background-color: #fe04e5;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-purple.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-purple.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-purple.b-pressed:focus {
    background-color: #fe22e8; }
  .b-button.b-raised.b-purple.b-pressed:active {
    background-color: #c401b1; }
  .b-button.b-raised.b-purple.b-pressed:active:hover {
    background-color: #e201cc; }
  .b-button.b-raised.b-purple:active:not([disabled]) {
    background-color: #f101da;
    border-color: #f101da;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-purple:active:hover:not([disabled]) {
    background-color: #fe13e7;
    border-color: #fe13e7;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-purple:disabled:hover, .b-button.b-raised.b-purple:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #c801b5;
    cursor: default; }

.b-button.b-raised.b-yellow {
  color: #fff;
  border: none;
  background-color: #ecfe26; }
  body.b-using-keyboard .b-button.b-raised.b-yellow:focus {
    background-color: #f0fe54; }
  body.b-using-keyboard .b-button.b-raised.b-yellow:focus:hover:not([disabled]), .b-button.b-raised.b-yellow:hover:not([disabled]) {
    background-color: #eefe3c; }
  .b-button.b-raised.b-yellow.b-pressed, .b-button.b-raised.b-yellow.b-pressed[data-group]:hover {
    background-color: #e3f701;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-yellow.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-yellow.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-yellow.b-pressed:focus {
    background-color: #ebfe17; }
  .b-button.b-raised.b-yellow.b-pressed:active {
    background-color: #adbd01; }
  .b-button.b-raised.b-yellow.b-pressed:active:hover {
    background-color: #c8da01; }
  .b-button.b-raised.b-yellow:active:not([disabled]) {
    background-color: #d5e901;
    border-color: #d5e901;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-yellow:active:hover:not([disabled]) {
    background-color: #e9fe09;
    border-color: #e9fe09;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-yellow:disabled:hover, .b-button.b-raised.b-yellow:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #adbd01;
    cursor: default; }

.b-button.b-raised.b-red {
  color: #fff;
  border: none;
  background-color: #e53f2c; }
  body.b-using-keyboard .b-button.b-raised.b-red:focus {
    background-color: #ea6455; }
  body.b-using-keyboard .b-button.b-raised.b-red:focus:hover:not([disabled]), .b-button.b-raised.b-red:hover:not([disabled]) {
    background-color: #e85241; }
  .b-button.b-raised.b-red.b-pressed, .b-button.b-raised.b-red.b-pressed[data-group]:hover {
    background-color: #cf2c19;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-red.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-red.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-red.b-pressed:focus {
    background-color: #e43420; }
  .b-button.b-raised.b-red.b-pressed:active {
    background-color: #9e2213; }
  .b-button.b-raised.b-red.b-pressed:active:hover {
    background-color: #b62716; }
  .b-button.b-raised.b-red:active:not([disabled]) {
    background-color: #c22918;
    border-color: #c22918;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-red:active:hover:not([disabled]) {
    background-color: #db2f1b;
    border-color: #db2f1b;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-red:disabled:hover, .b-button.b-raised.b-red:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #982013;
    cursor: default; }

.b-button.b-raised.b-dark-gray {
  color: #fff;
  border: none;
  background-color: #474849; }
  body.b-using-keyboard .b-button.b-raised.b-dark-gray:focus {
    background-color: #5e5f60; }
  body.b-using-keyboard .b-button.b-raised.b-dark-gray:focus:hover:not([disabled]), .b-button.b-raised.b-dark-gray:hover:not([disabled]) {
    background-color: #595a5c; }
  .b-button.b-raised.b-dark-gray.b-pressed, .b-button.b-raised.b-dark-gray.b-pressed[data-group]:hover {
    background-color: #3c3d3e;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-dark-gray.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-dark-gray.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-dark-gray.b-pressed:focus {
    background-color: #434445; }
  .b-button.b-raised.b-dark-gray.b-pressed:active {
    background-color: #2e2f2f; }
  .b-button.b-raised.b-dark-gray.b-pressed:active:hover {
    background-color: #353637; }
  .b-button.b-raised.b-dark-gray:active:not([disabled]) {
    background-color: #393a3a;
    border-color: #393a3a;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-dark-gray:active:hover:not([disabled]) {
    background-color: #404142;
    border-color: #404142;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-dark-gray:disabled:hover, .b-button.b-raised.b-dark-gray:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #151515;
    cursor: default; }

.b-button.b-raised.b-gray {
  color: #fff;
  border: none;
  background-color: #b0b0b7; }
  body.b-using-keyboard .b-button.b-raised.b-gray:focus {
    background-color: #c8c8cd; }
  body.b-using-keyboard .b-button.b-raised.b-gray:focus:hover:not([disabled]), .b-button.b-raised.b-gray:hover:not([disabled]) {
    background-color: #b8b8be; }
  .b-button.b-raised.b-gray.b-pressed, .b-button.b-raised.b-gray.b-pressed[data-group]:hover {
    background-color: #94949d;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-gray.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-gray.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-gray.b-pressed:focus {
    background-color: #a7a7ae; }
  .b-button.b-raised.b-gray.b-pressed:active {
    background-color: #6f6f7a; }
  .b-button.b-raised.b-gray.b-pressed:active:hover {
    background-color: #81818c; }
  .b-button.b-raised.b-gray:active:not([disabled]) {
    background-color: #8a8a95;
    border-color: #8a8a95;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-gray:active:hover:not([disabled]) {
    background-color: #9d9da6;
    border-color: #9d9da6;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-gray:disabled:hover, .b-button.b-raised.b-gray:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #7b7b86;
    cursor: default; }

.b-button.b-raised.b-light-gray {
  color: #fff;
  border: none;
  background-color: #e0e0e7; }
  body.b-using-keyboard .b-button.b-raised.b-light-gray:focus {
    background-color: #fafafb; }
  body.b-using-keyboard .b-button.b-raised.b-light-gray:focus:hover:not([disabled]), .b-button.b-raised.b-light-gray:hover:not([disabled]) {
    background-color: #e3e3e9; }
  .b-button.b-raised.b-light-gray.b-pressed, .b-button.b-raised.b-light-gray.b-pressed[data-group]:hover {
    background-color: #babac9;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-light-gray.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-light-gray.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-light-gray.b-pressed:focus {
    background-color: #d3d3dd; }
  .b-button.b-raised.b-light-gray.b-pressed:active {
    background-color: #8686a2; }
  .b-button.b-raised.b-light-gray.b-pressed:active:hover {
    background-color: #a0a0b5; }
  .b-button.b-raised.b-light-gray:active:not([disabled]) {
    background-color: #adadbf;
    border-color: #adadbf;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-light-gray:active:hover:not([disabled]) {
    background-color: #c6c6d3;
    border-color: #c6c6d3;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-light-gray:disabled:hover, .b-button.b-raised.b-light-gray:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #a7a7ba;
    cursor: default; }

.b-button.b-raised {
  color: #fff;
  border: none;
  background-color: #b0b0b7; }
  body.b-using-keyboard .b-button.b-raised:focus {
    background-color: #c8c8cd; }
  body.b-using-keyboard .b-button.b-raised:focus:hover:not([disabled]), .b-button.b-raised:hover:not([disabled]) {
    background-color: #b8b8be; }
  .b-button.b-raised.b-pressed, .b-button.b-raised.b-pressed[data-group]:hover {
    background-color: #94949d;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised.b-pressed:hover,
  body.b-using-keyboard .b-button.b-raised.b-pressed:focus:hover,
  body.b-using-keyboard .b-button.b-raised.b-pressed:focus {
    background-color: #a7a7ae; }
  .b-button.b-raised.b-pressed:active {
    background-color: #6f6f7a; }
  .b-button.b-raised.b-pressed:active:hover {
    background-color: #81818c; }
  .b-button.b-raised:active:not([disabled]) {
    background-color: #8a8a95;
    border-color: #8a8a95;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised:active:hover:not([disabled]) {
    background-color: #9d9da6;
    border-color: #9d9da6;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3); }
  .b-button.b-raised:disabled:hover, .b-button.b-raised:disabled {
    background-color: #e0e0e7;
    color: #a5a6a7;
    border-color: #7b7b86;
    cursor: default; }

.b-buttongroup {
  overflow: visible; }
  .b-buttongroup.b-content-element {
    display: inline-flex;
    flex-wrap: nowrap; }
  .b-buttongroup .b-button {
    margin: 0; }
    .b-buttongroup .b-button:not(.b-pressed) {
      box-shadow: none !important; }
    .b-buttongroup .b-button.b-pressed, .b-buttongroup .b-button:active {
      z-index: 2; }
    .b-buttongroup .b-button:not(:first-child) {
      margin-left: -1px; }
  .b-buttongroup.b-columned.b-content-element {
    flex: 1;
    flex-wrap: wrap; }
    .b-buttongroup.b-columned.b-content-element .b-button {
      margin-left: 0; }
  .b-buttongroup .b-button:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .b-buttongroup .b-button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .b-buttongroup .b-button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.b-panel.b-calendarpanel.b-widget {
  display: inline-flex; }

.b-panel.b-calendarpanel .b-week-number-cell {
  display: none; }

.b-panel.b-calendarpanel.b-show-week-number .b-week-number-cell {
  display: flex; }

.b-panel.b-calendarpanel .b-calendarpanel-content {
  flex-direction: column;
  align-items: stretch; }
  .b-panel.b-calendarpanel .b-calendarpanel-content .b-calendar-row {
    display: flex; }
  .b-panel.b-calendarpanel .b-calendarpanel-content .b-weeks-container {
    display: flex;
    flex-direction: column; }

.b-container .b-checkbox .b-checkbox-label {
  margin-left: 0; }

.b-container .b-checkbox .b-checkbox-label:before {
  margin: 0.675em;
  margin-right: 0.4em;
  margin-left: 0; }

.b-checkbox {
  position: relative;
  align-items: center; }
  .b-ie .b-checkbox, .b-checkbox.b-ie {
    display: block; }
  .b-checkbox .b-field-inner {
    border: none;
    background-color: transparent; }
  .b-checkbox .b-checkbox-label {
    position: relative;
    font-size: inherit;
    cursor: pointer; }
  .b-checkbox .b-checkbox-label:before {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.4em;
    font-size: inherit;
    width: 1.6em;
    height: 1.6em;
    padding: 0.15em;
    border-radius: 2px;
    transition: color 0.2s, background 0.2s;
    color: rgba(255, 255, 255, 0.2);
    background: #fff;
    border: 1px solid #e0e1e2; }
  .b-checkbox input {
    z-index: 1;
    opacity: 0;
    width: 2.2em;
    height: 2.2em;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    cursor: pointer;
    transition: color 0.5s, background-color 0.5s; }
  .b-checkbox input[type=checkbox]:checked + .b-checkbox-label:before {
    color: #474849;
    background: #fff;
    border-color: #e0e1e2; }
  .b-checkbox.b-disabled .b-checkbox-label {
    color: #b0b0b7; }
  .b-checkbox.b-disabled .b-checkbox-label::before {
    border-color: #e0e1e2;
    background: #e8e9ea; }
  .b-checkbox.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
    background: rgba(255, 255, 255, 0.2);
    border-color: rgba(255, 255, 255, 0.2); }

.b-checkbox.b-blue .b-checkbox-label:before {
  color: rgba(49, 131, 254, 0.1);
  border-color: rgba(49, 131, 254, 0.5); }

.b-checkbox.b-blue input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #3183fe;
  border-color: #3183fe; }

.b-checkbox.b-blue.b-disabled .b-checkbox-label::before {
  border-color: rgba(49, 131, 254, 0.2); }

.b-checkbox.b-blue.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(49, 131, 254, 0.2);
  border-color: rgba(49, 131, 254, 0.2); }

.b-checkbox.b-deep-orange .b-checkbox-label:before {
  color: rgba(255, 87, 34, 0.1);
  border-color: rgba(255, 87, 34, 0.5); }

.b-checkbox.b-deep-orange input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #ff5722;
  border-color: #ff5722; }

.b-checkbox.b-deep-orange.b-disabled .b-checkbox-label::before {
  border-color: rgba(255, 87, 34, 0.2); }

.b-checkbox.b-deep-orange.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(255, 87, 34, 0.2);
  border-color: rgba(255, 87, 34, 0.2); }

.b-checkbox.b-orange .b-checkbox-label:before {
  color: rgba(254, 172, 49, 0.1);
  border-color: rgba(254, 172, 49, 0.5); }

.b-checkbox.b-orange input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #feac31;
  border-color: #feac31; }

.b-checkbox.b-orange.b-disabled .b-checkbox-label::before {
  border-color: rgba(254, 172, 49, 0.2); }

.b-checkbox.b-orange.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(254, 172, 49, 0.2);
  border-color: rgba(254, 172, 49, 0.2); }

.b-checkbox.b-indigo .b-checkbox-label:before {
  color: rgba(69, 49, 254, 0.1);
  border-color: rgba(69, 49, 254, 0.5); }

.b-checkbox.b-indigo input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #4531fe;
  border-color: #4531fe; }

.b-checkbox.b-indigo.b-disabled .b-checkbox-label::before {
  border-color: rgba(69, 49, 254, 0.2); }

.b-checkbox.b-indigo.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(69, 49, 254, 0.2);
  border-color: rgba(69, 49, 254, 0.2); }

.b-checkbox.b-green .b-checkbox-label:before {
  color: rgba(39, 202, 55, 0.1);
  border-color: rgba(39, 202, 55, 0.5); }

.b-checkbox.b-green input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #27ca37;
  border-color: #27ca37; }

.b-checkbox.b-green.b-disabled .b-checkbox-label::before {
  border-color: rgba(39, 202, 55, 0.2); }

.b-checkbox.b-green.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(39, 202, 55, 0.2);
  border-color: rgba(39, 202, 55, 0.2); }

.b-checkbox.b-purple .b-checkbox-label:before {
  color: rgba(254, 49, 234, 0.1);
  border-color: rgba(254, 49, 234, 0.5); }

.b-checkbox.b-purple input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #fe31ea;
  border-color: #fe31ea; }

.b-checkbox.b-purple.b-disabled .b-checkbox-label::before {
  border-color: rgba(254, 49, 234, 0.2); }

.b-checkbox.b-purple.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(254, 49, 234, 0.2);
  border-color: rgba(254, 49, 234, 0.2); }

.b-checkbox.b-yellow .b-checkbox-label:before {
  color: rgba(236, 254, 38, 0.1);
  border-color: rgba(236, 254, 38, 0.5); }

.b-checkbox.b-yellow input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #ecfe26;
  border-color: #ecfe26; }

.b-checkbox.b-yellow.b-disabled .b-checkbox-label::before {
  border-color: rgba(236, 254, 38, 0.2); }

.b-checkbox.b-yellow.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(236, 254, 38, 0.2);
  border-color: rgba(236, 254, 38, 0.2); }

.b-checkbox.b-red .b-checkbox-label:before {
  color: rgba(229, 63, 44, 0.1);
  border-color: rgba(229, 63, 44, 0.5); }

.b-checkbox.b-red input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #e53f2c;
  border-color: #e53f2c; }

.b-checkbox.b-red.b-disabled .b-checkbox-label::before {
  border-color: rgba(229, 63, 44, 0.2); }

.b-checkbox.b-red.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(229, 63, 44, 0.2);
  border-color: rgba(229, 63, 44, 0.2); }

.b-checkbox.b-amber .b-checkbox-label:before {
  color: rgba(255, 193, 7, 0.1);
  border-color: rgba(255, 193, 7, 0.5); }

.b-checkbox.b-amber input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #ffc107;
  border-color: #ffc107; }

.b-checkbox.b-amber.b-disabled .b-checkbox-label::before {
  border-color: rgba(255, 193, 7, 0.2); }

.b-checkbox.b-amber.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(255, 193, 7, 0.2);
  border-color: rgba(255, 193, 7, 0.2); }

.b-checkbox.b-lime .b-checkbox-label:before {
  color: rgba(131, 254, 49, 0.1);
  border-color: rgba(131, 254, 49, 0.5); }

.b-checkbox.b-lime input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #83fe31;
  border-color: #83fe31; }

.b-checkbox.b-lime.b-disabled .b-checkbox-label::before {
  border-color: rgba(131, 254, 49, 0.2); }

.b-checkbox.b-lime.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(131, 254, 49, 0.2);
  border-color: rgba(131, 254, 49, 0.2); }

.b-checkbox.b-teal .b-checkbox-label:before {
  color: rgba(49, 254, 172, 0.1);
  border-color: rgba(49, 254, 172, 0.5); }

.b-checkbox.b-teal input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #31feac;
  border-color: #31feac; }

.b-checkbox.b-teal.b-disabled .b-checkbox-label::before {
  border-color: rgba(49, 254, 172, 0.2); }

.b-checkbox.b-teal.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(49, 254, 172, 0.2);
  border-color: rgba(49, 254, 172, 0.2); }

.b-checkbox.b-light-green .b-checkbox-label:before {
  color: rgba(158, 254, 167, 0.1);
  border-color: rgba(158, 254, 167, 0.5); }

.b-checkbox.b-light-green input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #9efea7;
  border-color: #9efea7; }

.b-checkbox.b-light-green.b-disabled .b-checkbox-label::before {
  border-color: rgba(158, 254, 167, 0.2); }

.b-checkbox.b-light-green.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(158, 254, 167, 0.2);
  border-color: rgba(158, 254, 167, 0.2); }

.b-checkbox.b-purple .b-checkbox-label:before {
  color: rgba(254, 49, 234, 0.1);
  border-color: rgba(254, 49, 234, 0.5); }

.b-checkbox.b-purple input[type=checkbox]:checked + .b-checkbox-label:before {
  color: #474849;
  background: #fe31ea;
  border-color: #fe31ea; }

.b-checkbox.b-purple.b-disabled .b-checkbox-label::before {
  border-color: rgba(254, 49, 234, 0.2); }

.b-checkbox.b-purple.b-disabled input[type=checkbox]:checked + .b-checkbox-label:before {
  background: rgba(254, 49, 234, 0.2);
  border-color: rgba(254, 49, 234, 0.2); }

.b-combo .b-fieldtrigger.b-icon-picker:before {
  transition: transform 0.3s; }

.b-combo.b-open .b-fieldtrigger.b-icon-picker:before {
  transform: rotate(180deg); }

.b-combo .b-field-inner .b-fieldtrigger.b-icon-remove {
  font-size: .8em; }

.b-combo.b-hide-trigger .b-fieldtrigger.b-icon-picker {
  display: none; }

.b-combo.b-multiselect .b-chipview {
  display: flex;
  flex-flow: wrap;
  flex: 1 1 auto;
  align-items: center;
  margin: .3em 0.3em .1em 0; }

.b-combo.b-multiselect:not(.b-empty) input {
  padding-left: 0.2em;
  order: 99999; }

.b-combo.b-multiselect.b-not-editable input {
  order: -1;
  min-width: 0;
  padding-left: 0;
  padding-right: 0;
  flex: 0 1 0px; }

.b-container.b-outer {
  align-self: stretch;
  flex: 1 1 auto; }

body.b-container.b-outer {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; }

.b-container:focus {
  outline: none; }

.b-content-element {
  position: relative;
  display: flex;
  flex-flow: row wrap; }

.b-text-content.b-content-element,
.b-text-content > .b-content-element {
  flex-flow: column nowrap;
  justify-content: normal;
  overflow: auto; }

.b-datepicker:focus {
  outline: 0; }

.b-datepicker .b-editable:hover {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); }

.b-datepicker .b-editor {
  background-color: transparent; }
  .b-datepicker .b-editor .b-field {
    border: 0 none; }
  .b-datepicker .b-editor .b-field-inner {
    border: 0 none;
    background-color: transparent;
    align-self: stretch;
    padding: 0; }
  .b-datepicker .b-editor input {
    padding: 0; }

.b-datepicker .b-datepicker-header.b-dock-top {
  background-color: #fff5e6;
  color: #4f5964;
  border-bottom: none; }
  .b-datepicker .b-datepicker-header.b-dock-top .b-icon {
    color: #4f5964; }
  .b-datepicker .b-datepicker-header.b-dock-top .b-tool {
    color: #4f5964; }

.b-datepicker .b-calendar-weekdays {
  background-color: #fff5e6;
  border-bottom: 1px solid #feac31; }
  .b-datepicker .b-calendar-weekdays .b-calendar-day-header {
    flex: 1 0 0%;
    padding: 6px 0px;
    text-align: center; }

.b-datepicker.b-show-week-number .b-week-number-cell {
  line-height: 2.2em;
  width: 2.2em;
  margin: 4px 5px;
  text-align: center; }

.b-datepicker .b-calendarpanel-content {
  padding: 0; }
  .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week {
    justify-content: space-evenly; }
    .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-week-number-cell {
      color: #76c579; }
    .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell {
      text-align: center;
      height: 2.2em;
      width: 2.2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 4px 5px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      border-radius: 50%;
      transition: background-color .2s, color .2s; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-today {
        border-color: #feac31;
        border-width: 1px; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell:hover {
        background-color: rgba(254, 172, 49, 0.5);
        border-color: rgba(254, 172, 49, 0.5); }
        .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell:hover.b-today {
          background-color: #feac31;
          border-color: #feac31; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-selected-date {
        color: #fff;
        background-color: #feac31;
        border-color: #feac31; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-active-date {
        border-color: #feac31; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-other-month {
        color: #aaa; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell {
        cursor: pointer; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-out-of-range {
        color: #aaa; }
      .b-datepicker .b-calendarpanel-content .b-calendar-row.b-calendar-week .b-calendar-cell.b-disabled {
        color: #aaa; }

.b-datepicker-month {
  text-align: start;
  text-align: left;
  margin-inline-end: 1em;
  margin-right: 1em; }

.b-datepicker-year {
  text-align: start;
  text-align: left; }

.b-year-picker-list.b-empty, .b-month-picker-list.b-empty {
  display: none; }

.b-year-picker-list .b-list-item, .b-month-picker-list .b-list-item {
  min-width: auto; }

.b-editor {
  background-color: #fff;
  border-radius: 2px; }
  .b-editor.b-positioned {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); }
  .b-editor.b-content-element > .b-widget.b-field {
    margin: 0; }

@keyframes b-field-updated {
  0% {
    color: green; }
  50% {
    color: green; }
  100% {
    color: #4f5964; } }

.b-has-label label {
  margin-right: 1em;
  color: #fff;
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis; }

.b-has-label.b-open label, .b-has-label:focus-within label, .b-has-label.b-ie:focus label {
  color: #fff; }

.b-container .b-has-label label {
  color: #616161;
  margin-left: 0.3em; }
  .b-container .b-has-label label.b-align-end {
    margin-left: 1em;
    text-align: left; }

.b-container .b-has-label.b-open label, .b-container .b-has-label:focus-within label, .b-container .b-has-label.b-ie:focus label {
  color: #212121; }

.b-has-label.b-invalid label {
  color: #e53f2c !important; }

.b-has-label.b-disabled label {
  color: #b0b0b7; }

.b-field-updated {
  animation-name: b-field-updated;
  animation-duration: 1.5s; }

.b-content-element:not(.b-toolbar) > .b-field {
  flex: 1 0 100%;
  margin-bottom: .6em; }
  .b-content-element:not(.b-toolbar) > .b-field.b-inline .b-field-inner {
    margin-right: 0.6em; }
  .b-content-element:not(.b-toolbar) > .b-field.b-last-row {
    margin-bottom: 0; }

.b-field-inner {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  background-color: #fff;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e1e2;
  min-width: 0;
  position: relative; }

.b-numberfield,
.b-textfield {
  align-items: center;
  min-width: 3em;
  color: #4f5964;
  border-color: none;
  border-radius: 2px;
  position: relative;
  width: 12em; }
  .b-numberfield.b-contains-focus .b-field-inner,
  .b-textfield.b-contains-focus .b-field-inner {
    border-color: rgba(254, 172, 49, 0.6); }
  .b-numberfield .b-fieldtrigger,
  .b-textfield .b-fieldtrigger {
    color: #b0b1b2;
    font-size: 0.9em;
    cursor: pointer;
    flex: 0 0 auto; }
    .b-numberfield .b-fieldtrigger.b-align-start,
    .b-textfield .b-fieldtrigger.b-align-start {
      padding-left: 0.8em; }
    .b-numberfield .b-fieldtrigger.b-align-end,
    .b-textfield .b-fieldtrigger.b-align-end {
      padding-right: .5em; }
      .b-numberfield .b-fieldtrigger.b-align-end:last-child,
      .b-textfield .b-fieldtrigger.b-align-end:last-child {
        padding-right: 0.8em; }
    .b-numberfield .b-fieldtrigger:before,
    .b-textfield .b-fieldtrigger:before {
      font-size: 1.3em;
      transition: all .3s;
      transform: scale(1); }
  .b-numberfield .b-spintrigger,
  .b-textfield .b-spintrigger {
    flex-direction: column;
    font-size: 1em; }
    .b-numberfield .b-spintrigger .b-spin-up:before,
    .b-textfield .b-spintrigger .b-spin-up:before {
      content: '\F0D8';
      vertical-align: bottom; }
    .b-numberfield .b-spintrigger .b-spin-down:before,
    .b-textfield .b-spintrigger .b-spin-down:before {
      content: '\F0D7';
      vertical-align: top; }
  .b-numberfield.b-hide-spinner .b-spintrigger,
  .b-textfield.b-hide-spinner .b-spintrigger {
    display: none; }
  .b-numberfield input, .b-numberfield textarea,
  .b-textfield input,
  .b-textfield textarea {
    background-color: transparent;
    color: inherit;
    padding: 0.8em;
    font-weight: 400;
    flex: 1 1 0px;
    border: 0 none;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    min-width: 1em; }
    .b-numberfield input:focus, .b-numberfield textarea:focus,
    .b-textfield input:focus,
    .b-textfield textarea:focus {
      outline: none; }
    .b-numberfield input::-ms-clear, .b-numberfield textarea::-ms-clear,
    .b-textfield input::-ms-clear,
    .b-textfield textarea::-ms-clear {
      display: none; }
  .b-numberfield ::-webkit-input-placeholder,
  .b-textfield ::-webkit-input-placeholder {
    color: #b0b0b7; }
  .b-numberfield.b-empty .b-fieldtrigger.b-icon-remove,
  .b-textfield.b-empty .b-fieldtrigger.b-icon-remove {
    display: none; }
  .b-numberfield:focus-within label i,
  .b-textfield:focus-within label i {
    color: rgba(254, 172, 49, 0.6); }
  .b-numberfield:not(.b-disabled):hover label i,
  .b-textfield:not(.b-disabled):hover label i {
    color: rgba(254, 172, 49, 0.6); }
  .b-numberfield:not(.b-disabled):hover .b-field-inner,
  .b-textfield:not(.b-disabled):hover .b-field-inner {
    border-color: rgba(254, 172, 49, 0.6); }
  .b-numberfield.b-invalid .b-field-inner,
  .b-textfield.b-invalid .b-field-inner {
    border-color: #e53f2c !important; }
  .b-numberfield.b-disabled,
  .b-textfield.b-disabled {
    color: #b0b0b7;
    cursor: default; }
    .b-numberfield.b-disabled input,
    .b-textfield.b-disabled input {
      cursor: default; }
    .b-numberfield.b-disabled .b-fieldtrigger,
    .b-textfield.b-disabled .b-fieldtrigger {
      color: #e4e4e4; }
    .b-numberfield.b-disabled .b-fieldtrigger,
    .b-textfield.b-disabled .b-fieldtrigger {
      cursor: default; }
    .b-numberfield.b-disabled .b-field-inner,
    .b-textfield.b-disabled .b-field-inner {
      border-style: solid; }
  .b-numberfield.b-readonly,
  .b-textfield.b-readonly {
    cursor: default; }
    .b-numberfield.b-readonly input,
    .b-textfield.b-readonly input {
      cursor: default; }
    .b-numberfield.b-readonly .b-fieldtrigger,
    .b-textfield.b-readonly .b-fieldtrigger {
      color: #e4e4e4;
      cursor: default; }

.b-numberfield input[type=number]::-webkit-inner-spin-button,
.b-numberfield input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.b-numberfield input {
  -moz-appearance: textfield; }

.b-numberfield .b-field-inner .b-fieldtrigger.b-icon-remove {
  font-size: .8em; }

.b-tooltip.b-field-error-tip {
  border: 1px solid #e53f2c; }
  .b-tooltip.b-field-error-tip .b-popup-content {
    background-color: #fffef6;
    color: #e53f2c;
    font-weight: bold; }

.b-filepicker {
  overflow: visible; }

.b-displayfield {
  color: inherit; }
  .b-displayfield .b-field-inner {
    border: 0;
    background: transparent; }

.b-pickerfield.b-open .b-field-inner {
  border-color: #feac31; }

.b-pickerfield:not(.b-readonly):not(.b-disabled) input[readonly] {
  cursor: pointer; }

.b-datefield.b-open .b-fieldtrigger {
  color: #feac31 !important; }

.b-datefield .b-step-trigger {
  display: none; }

.b-datefield.b-show-steppers .b-step-trigger {
  display: inline-block; }

.b-list {
  display: block;
  background-color: white;
  outline: none; }
  .b-list.b-floating {
    border-radius: 2px; }
  .b-list.b-empty {
    min-height: initial !important; }
    .b-list.b-empty::after {
      content: attr(data-empty-text);
      display: block;
      padding: .8em;
      color: #4f5964; }
  .b-list .b-selected-icon {
    margin-inline-end: 0.5em;
    display: none; }
    .b-ie .b-list .b-selected-icon {
      margin-right: 0.5em; }
  .b-list.b-multiselect .b-list-item .b-selected-icon {
    display: block;
    visibility: hidden; }
  .b-list.b-multiselect .b-list-item.b-selected .b-selected-icon {
    visibility: visible; }

.b-list-item {
  position: relative;
  padding: .8em;
  color: #4f5964;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s, color 0.2s;
  border-top: 1px dotted rgba(224, 224, 231, 0.8);
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .b-list-item.b-hidden {
    display: none; }
  .b-list-item:first-child {
    border-top: none;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px; }
  .b-list-item:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }
  .b-list-item.b-active, .b-list-item:focus {
    outline: none; }
    .b-list-item.b-active:not(.b-disabled), .b-list-item:focus:not(.b-disabled) {
      background-color: rgba(254, 172, 49, 0.3);
      color: #4f5964; }

.b-chipview {
  background-color: transparent; }
  .b-chipview.b-empty::after {
    display: none; }
  .b-chipview input {
    padding-top: 0.6em;
    padding-bottom: calc(0.6em + 1px); }
  .b-chipview .b-chip {
    display: flex;
    align-items: center;
    margin: 0 0 0.3em 0.3em;
    padding: 0.3em 0.3em;
    border-radius: 0.8em;
    background-color: #e0e0e7;
    color: #5a6672;
    transition: background-color .3s, color .3s; }
    .b-chipview .b-chip .b-icon {
      height: 1.5em;
      width: 1.5em;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eeeef2;
      color: #474849; }
      .b-chipview .b-chip .b-icon:first-child:not(.b-close-icon) {
        margin-right: 0.5em; }
    .b-chipview .b-chip:hover {
      background-color: #d2d2dc;
      color: #4f5964; }
      .b-chipview .b-chip:hover .b-icon {
        background-color: #eeeef2;
        color: #474849; }
    .b-chipview .b-chip.b-selected {
      background-color: #feac31;
      color: #fff; }
      .b-chipview .b-chip.b-selected .b-icon {
        background-color: #eeeef2;
        color: #feac31; }
      .b-chipview .b-chip.b-selected:hover {
        background-color: #fea218;
        color: #fff; }
        .b-chipview .b-chip.b-selected:hover .b-icon {
          background-color: #eeeef2;
          color: #fea218; }
    .b-chipview .b-chip .b-close-icon {
      margin-left: 0.5em;
      cursor: pointer; }

@keyframes maskOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.b-masked {
  position: relative; }

.b-mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background-color: transparent; }
  .b-mask:not(.b-prevent-transitions) {
    transition: opacity 1s; }
  .b-mask.b-visible {
    pointer-events: all;
    opacity: 1; }
    .b-mask.b-visible:not(.b-prevent-transitions) {
      animation: maskOpacity .3s ease 0s 1; }
  .b-mask.b-hidden {
    pointer-events: none;
    opacity: 0; }
    .b-mask.b-hidden:not(.b-prevent-transitions) {
      animation: maskOpacity .2s ease 0s 1 reverse; }
  .b-mask.b-mask-bright {
    background-color: rgba(255, 255, 255, 0.7); }
  .b-mask.b-mask-bright-blur {
    background-color: rgba(255, 255, 255, 0.7); }
  .b-mask.b-mask-dark {
    background-color: rgba(0, 0, 0, 0.3); }
  .b-mask.b-mask-dark-blur {
    background-color: rgba(0, 0, 0, 0.3); }

.b-mask-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  color: #fff;
  background-color: #feac31;
  border-radius: 2px;
  white-space: nowrap; }

.b-mask-text {
  padding: 1em;
  display: flex;
  align-items: center; }

.b-mask-progress-bar {
  height: 3px;
  background-color: #3183fe; }

.b-mask-icon {
  margin-right: .5em; }

.b-masked-dark-blur,
.b-masked-bright-blur {
  filter: blur(3px);
  transform: scale(1.01);
  transition: all .5s; }

.b-messagedialog.b-popup {
  min-width: 18em; }
  .b-messagedialog.b-popup .b-messagedialog-header,
  .b-messagedialog.b-popup .b-messagedialog-content {
    padding: 1em; }
  .b-messagedialog.b-popup .b-messagedialog-content.b-panel-content {
    border-radius: 0; }
  .b-messagedialog.b-popup .b-toolbar.b-dock-bottom {
    padding: 0.6em 1em 0; }
  .b-messagedialog.b-popup .b-msgdialog-message,
  .b-messagedialog.b-popup .b-msgdialog-input,
  .b-messagedialog.b-popup .b-msgdialog-yesbutton,
  .b-messagedialog.b-popup .b-msgdialog-nobutton,
  .b-messagedialog.b-popup .b-msgdialog-cancelbutton {
    display: none; }
  .b-messagedialog.b-popup.b-show-message-input-yes-no-cancel .b-msgdialog-message,
  .b-messagedialog.b-popup.b-show-message-input-yes-no-cancel .b-msgdialog-input,
  .b-messagedialog.b-popup.b-show-message-input-yes-no-cancel .b-msgdialog-yesbutton,
  .b-messagedialog.b-popup.b-show-message-input-yes-no-cancel .b-msgdialog-nobutton,
  .b-messagedialog.b-popup.b-show-message-input-yes-no-cancel .b-msgdialog-cancelbutton {
    display: inline-flex; }
  .b-messagedialog.b-popup.b-show-input-yes-no-cancel .b-msgdialog-input,
  .b-messagedialog.b-popup.b-show-input-yes-no-cancel .b-msgdialog-yesbutton,
  .b-messagedialog.b-popup.b-show-input-yes-no-cancel .b-msgdialog-nobutton,
  .b-messagedialog.b-popup.b-show-input-yes-no-cancel .b-msgdialog-cancelbutton {
    display: inline-flex; }
  .b-messagedialog.b-popup.b-show-message-yes-no-cancel .b-msgdialog-message,
  .b-messagedialog.b-popup.b-show-message-yes-no-cancel .b-msgdialog-yesbutton,
  .b-messagedialog.b-popup.b-show-message-yes-no-cancel .b-msgdialog-nobutton,
  .b-messagedialog.b-popup.b-show-message-yes-no-cancel .b-msgdialog-cancelbutton {
    display: inline-flex; }
  .b-messagedialog.b-popup.b-show-message-input-yes-no .b-msgdialog-message,
  .b-messagedialog.b-popup.b-show-message-input-yes-no .b-msgdialog-input,
  .b-messagedialog.b-popup.b-show-message-input-yes-no .b-msgdialog-yesbutton,
  .b-messagedialog.b-popup.b-show-message-input-yes-no .b-msgdialog-nobutton {
    display: inline-flex; }
  .b-messagedialog.b-popup.b-show-message-yes-no .b-msgdialog-message,
  .b-messagedialog.b-popup.b-show-message-yes-no .b-msgdialog-yesbutton,
  .b-messagedialog.b-popup.b-show-message-yes-no .b-msgdialog-nobutton {
    display: inline-flex; }
  .b-messagedialog.b-popup.b-show-input-yes-no .b-msgdialog-input,
  .b-messagedialog.b-popup.b-show-input-yes-no .b-msgdialog-yesbutton,
  .b-messagedialog.b-popup.b-show-input-yes-no .b-msgdialog-nobutton {
    display: inline-flex; }
  .b-messagedialog.b-popup.b-show-yes-no-cancel .b-msgdialog-yesbutton,
  .b-messagedialog.b-popup.b-show-yes-no-cancel .b-msgdialog-nobutton,
  .b-messagedialog.b-popup.b-show-yes-no-cancel .b-msgdialog-cancelbutton {
    display: inline-flex; }
  .b-messagedialog.b-popup.b-show-yes-no .b-msgdialog-yesbutton,
  .b-messagedialog.b-popup.b-show-yes-no .b-msgdialog-nobutton {
    display: inline-flex; }
  .b-messagedialog.b-popup .b-toolbar {
    justify-content: space-evenly; }

.b-pagingtoolbar {
  justify-content: center; }
  .b-pagingtoolbar .b-toolbar-text,
  .b-pagingtoolbar .b-button {
    margin: 0 0.5em; }
  .b-pagingtoolbar .b-numberfield {
    width: unset; }
    .b-pagingtoolbar .b-numberfield input {
      width: 4em;
      text-align: center; }
  .b-pagingtoolbar .b-disabled.b-html {
    color: #b0b0b7; }

.b-panel {
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
  color: #4f5964; }
  .b-panel:not(.b-outer) {
    border-radius: 2px; }
    .b-panel:not(.b-outer) .b-panel-content {
      border-radius: 2px; }
  .b-panel.b-header-dock-top {
    flex-flow: column nowrap; }
    .b-panel.b-header-dock-top .b-panel-content {
      border-top-right-radius: 0;
      border-top-left-radius: 0; }
  .b-panel.b-header-dock-right {
    flex-flow: row nowrap; }
    .b-panel.b-header-dock-right .b-panel-content {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
  .b-panel.b-header-dock-bottom {
    flex-flow: column nowrap; }
    .b-panel.b-header-dock-bottom .b-panel-content {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
  .b-panel.b-header-dock-left {
    flex-flow: row nowrap; }
    .b-panel.b-header-dock-left .b-panel-content {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .b-panel.b-panel-has-header {
    display: flex;
    align-items: stretch; }
  .b-panel.b-panel-has-bottom-toolbar .b-panel-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .b-panel .b-dock-bottom {
    position: static;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; }

.b-panel-content {
  background: #f8f9fa;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: 0.6em; }
  .b-panel-content.b-fit-container {
    padding: 0; }

.b-tabpanel .b-panel-content {
  background-color: transparent; }

.b-panel-header {
  display: flex;
  background-color: #fff5e6;
  color: #4f5964;
  padding: 1em;
  flex: 0 0 auto;
  align-items: center; }
  .b-panel-header.b-dock-top {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    border-bottom: 1px solid #feac31; }
  .b-panel-header.b-dock-right {
    flex-flow: column nowrap;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }
  .b-panel-header.b-dock-bottom {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }
  .b-panel-header.b-dock-left {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    flex-flow: column-reverse nowrap; }
    .b-panel-header.b-dock-left .b-header-title {
      transform: rotate(180deg); }
  .b-panel-header .b-header-title {
    flex: 1 1 0%;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    font-weight: 500; }
    .b-panel-header .b-header-title:not(:last-child) {
      padding-right: 1em; }
    .b-panel-header .b-header-title.b-align-start {
      text-align: start;
      justify-content: flex-start; }
    .b-panel-header .b-header-title.b-align-center {
      text-align: center;
      justify-content: center; }
    .b-panel-header .b-header-title.b-align-end {
      text-align: end;
      justify-content: flex-end; }
      .b-panel-header .b-header-title.b-align-end:not(:last-child) {
        margin-right: 1em; }
  .b-panel-header.b-dock-right .b-header-title, .b-panel-header.b-dock-left .b-header-title {
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr; }
  .b-panel-header.b-dock-right, .b-panel-header.b-dock-bottom {
    order: 100; }

.b-tool {
  cursor: pointer;
  color: #4f5964;
  contain: paint; }
  .b-tool:not(:last-child) {
    margin: 0 0.2em; }
  .b-tool.b-icon:before, .b-row-reordering .b-row-reordering-target-parent .b-tool.b-tree-cell::before {
    width: 1em;
    text-align: center; }
  .b-tool:hover {
    opacity: 0.8; }
  .b-tool a {
    color: #4f5964; }

.b-dock-right .b-header-title:not(:last-child), .b-dock-left .b-header-title:not(:last-child) {
  padding: 0.2em 0; }

.b-dock-right .b-tool:not(:last-child), .b-dock-left .b-tool:not(:last-child) {
  margin: 0.2em 0; }

.b-popup {
  color: #4f5964; }
  .b-popup.b-text-content {
    max-width: 14em; }

.b-popup-content {
  background: #f8f9fa; }
  .b-popup-content a {
    color: #22262b; }

.b-popup-header {
  background-color: #fff5e6;
  color: #4f5964; }

.b-popup-close:before {
  content: '\f410'; }

.b-modal-mask {
  background-color: rgba(100, 100, 100, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: all; }

.b-menu {
  min-height: 20px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* HACK for IE11. Withouth this padding menu may scroll on first/last item hover */
  /* Covered by Menu.t.js */ }
  .b-menu .b-panel-content {
    background-color: #fff; }
  .b-menu.b-empty::after {
    content: attr(data-empty-text);
    display: block;
    padding: .8em;
    color: #4f5964; }
  .b-menu.b-ie .b-menu-content {
    padding-bottom: 1px; }
  .b-menu .b-icon:before, .b-menu .b-row-reordering .b-row-reordering-target-parent .b-tree-cell::before, .b-row-reordering .b-row-reordering-target-parent .b-menu .b-tree-cell::before, .b-menu .b-icon-fw:before {
    min-width: 1.3em; }
  .b-menu.b-menu-with-submenu .b-menuitem:not(.b-has-submenu) .b-menu-text {
    margin-right: 2em; }

.b-sub-menu .b-anchor:before {
  position: absolute;
  height: 2.7em;
  width: 11px;
  left: -2px;
  content: ' ';
  margin-top: -0.8em; }

.b-menu-content {
  flex-flow: column nowrap;
  justify-content: start;
  align-items: stretch;
  padding: 0; }
  .b-menu-content > :not(.b-menuitem) {
    margin: 0.6em;
    display: flex; }

.b-menuitem {
  flex-shrink: 0;
  padding: .8em;
  color: #4f5964;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  border-top: 1px dotted rgba(224, 224, 231, 0.8);
  font-size: 1em;
  min-width: 7em;
  display: flex; }
  .b-menuitem.b-disabled {
    opacity: .5; }
  .b-menuitem:first-child {
    border-top: none;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px; }
  .b-menuitem:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }
  .b-menuitem.b-separator:not(:first-child) {
    border-top: 1px solid #c3c3d1; }
  .b-menuitem.b-contains-focus, .b-menuitem:focus {
    outline: none;
    background-color: rgba(254, 172, 49, 0.3);
    color: #4f5964; }
  .b-menuitem span.b-menu-text {
    flex: 1 1 auto;
    white-space: nowrap;
    margin-left: 0.6em; }
  .b-menuitem.b-has-submenu .b-icon-sub-menu {
    margin-left: .3em; }

a.b-menuitem:hover .b-menu-text {
  text-decoration: underline; }

.b-menu-with-icon .b-menuitem-icon {
  width: 2em; }

.b-menu-with-icon span.b-menu-text {
  margin-left: 2em; }

.b-menu-with-icon .b-menuitem-icon ~ span.b-menu-text {
  margin-left: 0; }

.b-ripple {
  display: none; }

.b-slider {
  background-color: transparent;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .b-slider label {
    font-size: 0.8em;
    transition: color 0.2s; }
  .b-slider [type='range'] {
    align-self: stretch;
    max-width: 100%;
    -webkit-appearance: none;
    margin: 10px 0;
    padding: 0;
    align-self: stretch;
    font-size: inherit;
    background-color: transparent; }
    .b-slider [type='range']:focus {
      outline: 0; }
      .b-slider [type='range']:focus::-webkit-slider-runnable-track {
        background: #c9dfff; }
      .b-slider [type='range']:focus::-moz-range-track {
        background: #c9dfff; }
      .b-slider [type='range']:focus::-ms-fill-lower, .b-slider [type='range']:focus::-ms-fill-upper {
        background: #c9dfff; }
      .b-slider [type='range']:focus::-webkit-slider-thumb {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.2); }
    .b-slider [type='range']::-moz-focus-outer {
      border: 0; }
    .b-slider [type='range']::-ms-tooltip {
      display: none; }
    .b-slider [type='range']::-webkit-slider-runnable-track {
      cursor: pointer;
      height: 8px;
      transition: all .2s ease;
      width: 100%;
      background: #e0e0e7;
      border: none;
      border-radius: 2px; }
    .b-slider [type='range']::-webkit-slider-thumb {
      background: #3183fe;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      width: 20px;
      -webkit-appearance: none;
      margin-top: -6px;
      transition: all 0.5s; }
    .b-slider [type='range']::-moz-range-track {
      cursor: pointer;
      height: 8px;
      transition: all .2s ease;
      width: 100%;
      background: #e0e0e7;
      border: none;
      border-radius: 2px; }
    .b-slider [type='range']::-moz-range-thumb {
      background: #3183fe;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      width: 20px; }
    .b-slider [type='range']::-ms-track {
      cursor: pointer;
      height: 8px;
      transition: all .2s ease;
      width: 100%;
      background: transparent;
      border-color: transparent;
      border-width: 10px 0;
      color: transparent; }
    .b-slider [type='range']::-ms-fill-lower, .b-slider [type='range']::-ms-fill-upper {
      background: #e0e0e7;
      border: none;
      border-radius: 4px; }
    .b-slider [type='range']::-ms-thumb {
      background: #3183fe;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      width: 20px;
      margin-top: 0; }
  .b-slider.b-disabled [type='range']::-ms-thumb, .b-slider.b-disabled [type='range']::-ms-fill-lower, .b-slider.b-disabled [type='range']::-ms-fill-upper {
    background: #d2d2dc;
    cursor: default; }
  .b-slider.b-disabled [type='range']::-moz-range-thumb, .b-slider.b-disabled [type='range']::-moz-range-track {
    background: #d2d2dc;
    cursor: default; }
  .b-slider.b-disabled [type='range']::-webkit-slider-thumb, .b-slider.b-disabled [type='range']::-webkit-slider-runnable-track {
    background: #d2d2dc;
    cursor: default; }

.b-splitter {
  background-color: #cbcbcf;
  flex: 0 0 0.5em; }
  .b-splitter.b-horizontal {
    cursor: ns-resize;
    min-height: 0.5em; }
  .b-splitter.b-vertical {
    cursor: ew-resize;
    min-width: 0.5em; }

.b-tabpanel {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch; }
  .b-tabpanel-tabs {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    margin-bottom: 0;
    border-bottom: 1px solid #feac31; }
    .b-tabpanel-tabs.b-edge {
      margin-bottom: -1px; }
  .b-tabpanel-tab {
    display: flex;
    padding: 0.9em;
    height: auto;
    min-width: 3em;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    font-weight: 500;
    text-transform: uppercase;
    color: #7b7b7b;
    background-color: transparent;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
    .b-tabpanel-tab.b-active {
      background-color: #fff5e6;
      border-color: #e0e1e2;
      color: #616161;
      font-weight: 800;
      z-index: 1; }
    .b-tabpanel-tab.b-hidden {
      display: none !important; }
    .b-tabpanel-tab:focus {
      outline: none; }
    .b-tabpanel-tab:hover:not(.b-active), .b-tabpanel-tab:focus:not(.b-active) {
      background-color: #fff5e6;
      color: #616161; }
    .b-tabpanel-tab-title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
  .b-tabpanel-body.b-card-container {
    background-color: transparent;
    border-radius: 2px;
    border: 0 solid #e0e1e2;
    flex: 1 0 auto;
    padding: 1em 0; }
  .b-tabpanel[data-active-index="0"] .b-tabpanel-body {
    border-top-left-radius: 0; }
  .b-tabpanel .b-html {
    color: #4f5964; }

.b-timefield.b-open .b-icon-clock-live {
  background-color: #feac31 !important; }

.b-timefield.b-empty .b-fieldtrigger {
  animation-delay: -300s; }

.b-timefield.b-disabled .b-icon-clock-live, .b-timefield.b-readonly .b-icon-clock-live {
  background-color: #e4e4e4; }

.b-timefield:not(.b-disabled):not(.b-readonly) .b-icon-angle-left:hover,
.b-timefield:not(.b-disabled):not(.b-readonly) .b-icon-angle-right:hover {
  color: #feac31; }

.b-timepicker {
  width: 20em; }
  .b-timepicker.b-24h {
    width: 12.8em; }
  .b-timepicker .b-panel-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3em; }
    .b-timepicker .b-panel-content .b-widget {
      margin: 0.3em; }
      .b-timepicker .b-panel-content .b-widget.b-html {
        margin: 0; }
      .b-timepicker .b-panel-content .b-widget label {
        font-weight: bold; }
    .b-timepicker .b-panel-content .b-numberfield {
      flex: 0 1 5.3em; }
    .b-timepicker .b-panel-content .b-button {
      flex: 0 0 3em; }

.b-ie.b-timepicker {
  width: 25em; }
  .b-ie.b-timepicker.b-24h {
    width: 13.8em; }
  .b-ie.b-timepicker .b-panel-content .b-button {
    line-height: 3em; }

@keyframes progress {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

.b-float-root > .b-floating.b-toast {
  display: inline-block;
  overflow: hidden;
  top: auto;
  right: 2em;
  left: auto;
  transform: translateY(100%);
  bottom: 0;
  transition: bottom .25s ease-in, transform .25s ease-in;
  padding: 1em;
  background-color: #f8f9fa;
  color: #4f5964;
  border-radius: 2px;
  cursor: pointer; }
  .b-float-root > .b-floating.b-toast.b-toast-hide {
    bottom: 0 !important;
    transform: translateY(100%) !important; }
  .b-float-root > .b-floating.b-toast.b-icon:before, .b-row-reordering .b-row-reordering-target-parent .b-float-root > .b-floating.b-toast.b-tree-cell::before {
    margin-right: .5em; }

.b-toast-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  background: #feac31;
  animation-name: progress;
  animation-timing-function: linear; }

.b-float-root > .b-floating.b-toast.b-white {
  background-color: #fff;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-white .b-toast-progress {
    background: white; }

.b-float-root > .b-floating.b-toast.b-blue {
  background-color: #3183fe;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-blue .b-toast-progress {
    background: #83b5fe; }

.b-float-root > .b-floating.b-toast.b-deep-orange {
  background-color: #ff5722;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-deep-orange .b-toast-progress {
    background: #ff9a7a; }

.b-float-root > .b-floating.b-toast.b-orange {
  background-color: #feac31;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-orange .b-toast-progress {
    background: #fecd83; }

.b-float-root > .b-floating.b-toast.b-amber {
  background-color: #ffc107;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-amber .b-toast-progress {
    background: #ffda6a; }

.b-float-root > .b-floating.b-toast.b-indigo {
  background-color: #4531fe;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-indigo .b-toast-progress {
    background: #8f83fe; }

.b-float-root > .b-floating.b-toast.b-lime {
  background-color: #83fe31;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-lime .b-toast-progress {
    background: #b5fe83; }

.b-float-root > .b-floating.b-toast.b-teal {
  background-color: #31feac;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-teal .b-toast-progress {
    background: #83fecd; }

.b-float-root > .b-floating.b-toast.b-green {
  background-color: #27ca37;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-green .b-toast-progress {
    background: #78e582; }

.b-float-root > .b-floating.b-toast.b-light-green {
  background-color: #9efea7;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-light-green .b-toast-progress {
    background: #c5feca; }

.b-float-root > .b-floating.b-toast.b-purple {
  background-color: #fe31ea;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-purple .b-toast-progress {
    background: #fe83f2; }

.b-float-root > .b-floating.b-toast.b-yellow {
  background-color: #ecfe26;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-yellow .b-toast-progress {
    background: #f4fe7d; }

.b-float-root > .b-floating.b-toast.b-red {
  background-color: #e53f2c;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-red .b-toast-progress {
    background: #ef8c80; }

.b-float-root > .b-floating.b-toast.b-dark-gray {
  background-color: #474849;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-dark-gray .b-toast-progress {
    background: #909193; }

.b-float-root > .b-floating.b-toast.b-gray {
  background-color: #b0b0b7;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-gray .b-toast-progress {
    background: #d0d0d4; }

.b-float-root > .b-floating.b-toast.b-light-gray {
  background-color: #e0e0e7;
  color: #fff; }
  .b-float-root > .b-floating.b-toast.b-light-gray .b-toast-progress {
    background: #ececf1; }

.b-tooltip {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .b-tooltip .b-tooltip-loading .b-icon {
    display: inline-block;
    margin-right: .5em; }

.b-tooltip-content {
  background: white;
  color: #4f5964;
  font-size: 1em;
  line-height: 1.4em;
  padding: 1em; }
  .b-tooltip-content a {
    color: #4f5964; }

.b-tooltip-header.b-dock-right .b-header-title,
.b-tooltip-header.b-dock-left .b-header-title {
  font-size: 1em; }

.b-textareafield textarea {
  padding: 2px;
  align-self: stretch; }

.b-textareafield-picker {
  display: flex; }
  .b-textareafield-picker textarea {
    flex: 1;
    min-height: 10em; }
    .b-textareafield-picker textarea:focus {
      outline: none;
      border-color: rgba(254, 172, 49, 0.6); }

.b-panel .b-toolbar {
  background-color: #f8f9fa;
  color: #4f5964;
  align-items: center; }
  .b-panel .b-toolbar > .b-toolbar-fill {
    flex: 1 1 0%; }
  .b-panel .b-toolbar.b-dock-top, .b-panel .b-toolbar.b-dock-bottom {
    flex-flow: row nowrap;
    padding: 0.6em 0.6em 0 0.6em; }
    .b-panel .b-toolbar.b-dock-top > .b-widget, .b-panel .b-toolbar.b-dock-bottom > .b-widget {
      margin-bottom: 0.6em; }
    .b-panel .b-toolbar.b-dock-top > .b-toolbar-separator, .b-panel .b-toolbar.b-dock-bottom > .b-toolbar-separator {
      margin-left: 0.5em;
      margin-right: 0.5em;
      height: 2.3em;
      border-style: solid;
      border-width: 0 1px 0 1px;
      border-left-color: #dae0e5;
      border-right-color: #e9ecef; }
  .b-panel .b-toolbar.b-dock-left, .b-panel .b-toolbar.b-dock-right {
    flex-flow: column nowrap;
    padding: 0.6em 0 0.6em 0.6em; }
    .b-panel .b-toolbar.b-dock-left > .b-widget, .b-panel .b-toolbar.b-dock-right > .b-widget {
      margin-right: 0.6em; }
    .b-panel .b-toolbar.b-dock-left > .b-toolbar-separator, .b-panel .b-toolbar.b-dock-right > .b-toolbar-separator {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      width: 2.3em;
      border-style: solid;
      border-width: 1px 0 1px 0;
      border-top-color: #dae0e5;
      border-bottom-color: #e9ecef; }

.b-tabpanel .b-panel .b-toolbar {
  background-color: transparent; }

.b-card-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  overflow: hidden;
  padding: 1em;
  position: relative; }
  .b-card-container > .b-card-item {
    display: none;
    flex: 1 0 100%;
    align-items: stretch;
    margin-left: 0;
    max-width: 100%;
    margin-right: 1em; }
    .b-card-container > .b-card-item.b-active {
      display: flex; }

@keyframes b-card-slide-in-left {
  0% {
    transform: translateX(-100%);
    margin-left: -1em; }
  100% {
    transform: translateX(0);
    margin-left: 0; } }

@keyframes b-card-slide-out-right {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }

@keyframes b-card-slide-in-right {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-100%); } }

@keyframes b-card-slide-out-left {
  0% {
    transform: translateX(0);
    margin-left: 0; }
  100% {
    transform: translateX(-100%);
    margin-left: -1em; } }

.b-slide-in-left {
  animation: b-card-slide-in-left 0.3s ease 0s 1; }

.b-slide-out-right {
  animation: b-card-slide-out-right 0.3s ease 0s 1; }

.b-slide-in-right {
  animation: b-card-slide-in-right 0.3s ease 0s 1; }

.b-slide-out-left {
  animation: b-card-slide-out-left 0.3s ease 0s 1; }

.b-fit-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  overflow: hidden;
  position: relative; }
  .b-fit-container > .b-fit-item {
    flex: 1 0 auto;
    margin: 0;
    max-width: 100%; }

.b-edge .b-fit-container > .b-fit-item,
.b-ie .b-fit-container > .b-fit-item {
  flex: 1 0 auto; }

.b-checkbox.b-blue label.b-checkbox-label:before {
  color: transparent;
  border-color: #e0e1e2; }

.b-checkbox.b-blue input[type=checkbox]:checked + label.b-checkbox-label:before {
  color: #3183fe;
  background: #fff;
  border-color: #e0e1e2; }

.b-checkbox.b-blue.b-disabled label::before {
  border-color: #e0e1e2;
  background: #e8e9ea; }

.b-checkbox.b-orange label.b-checkbox-label:before {
  color: transparent;
  border-color: #e0e1e2; }

.b-checkbox.b-orange input[type=checkbox]:checked + label.b-checkbox-label:before {
  color: #feac31;
  background: #fff;
  border-color: #e0e1e2; }

.b-checkbox.b-orange.b-disabled label::before {
  border-color: #e0e1e2;
  background: #e8e9ea; }

header.demo-header {
  background-color: #2667c8; }

.b-icon-group-collapse:before, .b-grid-cell.b-group-title:before {
  content: '\f068' !important; }

.b-icon-group-expand:before, .b-grid-group-collapsed .b-group-title:before {
  content: '\f067' !important; }

.b-ie .b-icon-expand-gridregion,
.b-ie .b-icon-collapse-gridregion {
  margin-left: -0.2em; }

.b-action-cell {
  text-align: end; }

.b-actions-ct {
  width: 100%; }

.b-action-item {
  width: 1.3em;
  margin: 0 0.15em;
  font-size: 1.2em;
  cursor: pointer; }

.b-action-cell.b-grid-cell .b-action-item {
  transition: all 0.15s ease-in-out; }

.b-action-item:hover {
  transform: scale3d(1.2, 1.2, 1); }

.b-check-cell .b-checkbox label.b-checkbox-label:before {
  margin-right: 0; }

.b-check-header-with-checkbox .b-grid-header-text {
  display: none; }

.b-check-header-with-checkbox .b-field-inner {
  justify-content: center; }

.b-check-header-with-checkbox .b-checkbox-label:before {
  margin: 0 !important; }

.b-percent-bar-outer {
  width: calc(100% - 1em);
  height: 1.4em;
  position: absolute;
  top: calc(50% - .7em);
  background: #f1f1f1; }

.b-percent-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 100%;
  padding: 0 .2em;
  background: #27ca37;
  color: #fff;
  font-size: .7em;
  text-align: right;
  transition: width 0.5s, padding .5s, background-color .5s; }
  .b-percent-bar.b-zero {
    padding: 0;
    text-indent: .2em; }
  .b-percent-bar.b-low {
    background: #e53f2c; }

.b-grid-body-container.b-scrolling .b-percent-bar {
  transition: none; }

.b-rating-cell .b-icon {
  font-size: 1.8em; }

.b-rating-cell .b-empty {
  color: rgba(224, 224, 231, 0.3); }

.b-rating-cell .b-filled {
  color: #ffce3a; }

.b-gridbase:not(.b-readonly) .b-rating-cell-inner:not(.b-not-editable) .b-icon {
  cursor: pointer;
  transition: all .2s linear;
  position: relative; }

.b-gridbase:not(.b-readonly) .b-rating-cell-inner:not(.b-not-editable):hover .b-icon {
  color: #ffce3a; }
  .b-gridbase:not(.b-readonly) .b-rating-cell-inner:not(.b-not-editable):hover .b-icon.b-empty::before {
    opacity: 0.4; }

.b-gridbase:not(.b-readonly) .b-rating-cell-inner:not(.b-not-editable) .b-icon:hover {
  transform: scale(1.3); }
  .b-gridbase:not(.b-readonly) .b-rating-cell-inner:not(.b-not-editable) .b-icon:hover.b-filled {
    opacity: 1; }
  .b-gridbase:not(.b-readonly) .b-rating-cell-inner:not(.b-not-editable) .b-icon:hover:hover ~ .b-icon::before {
    color: rgba(224, 224, 231, 0.3);
    opacity: 1; }

.b-grid-body-container.b-scrolling .b-rating-cell-inner .b-icon {
  transition: none; }

.b-grid-cell.b-row-number-cell {
  background-color: #f1f1f1;
  border-right: 1px solid #e9eaeb; }

.b-grid-row.b-hover .b-grid-cell.b-row-number-cell:hover {
  border-right: 1px solid #e9eaeb; }

.b-group-row .b-grid-cell.b-row-number-cell:first-child {
  border-right: 1px solid #e9eaeb; }

.b-gridbase:focus .b-grid-row.b-selected .b-grid-cell.b-row-number-cell.b-selected {
  border-right: 1px solid #e9eaeb; }

.b-tree-expander {
  position: relative;
  top: 1px;
  margin: 0 .7em;
  cursor: pointer;
  width: 0.6em;
  min-width: 0.6em;
  display: flex;
  align-items: center;
  justify-content: center; }

.b-loading-children .b-tree-expander .b-icon:before, .b-loading-children .b-tree-expander .b-row-reordering .b-row-reordering-target-parent .b-tree-cell::before, .b-row-reordering .b-row-reordering-target-parent .b-loading-children .b-tree-expander .b-tree-cell::before {
  content: "\f110";
  animation: spin 2s infinite linear; }

.b-grid-cell.b-tree-cell {
  align-items: stretch; }

.b-tree-leaf-cell .b-tree-cell-inner:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #adaeaf;
  margin-right: .6em;
  width: 1em;
  min-width: 1em;
  text-align: center; }

.b-tree-icon,
.b-icon-tree-leaf,
.b-icon-tree-folder,
.b-icon-tree-folder-open,
.b-icon-tree-expand,
.b-icon-tree-collapse {
  color: #adaeaf;
  width: 1em;
  min-width: 1em;
  text-align: center; }

.b-tree-icon,
.b-icon-tree-leaf,
.b-icon-tree-folder,
.b-icon-tree-folder-open {
  margin-right: .6em; }

.b-icon-tree-leaf:before {
  font-size: .3em;
  vertical-align: middle; }

.b-icon-tree-folder:before,
.b-icon-tree-folder-open:before {
  margin-left: .1em; }

.b-icon-tree-expand:before,
.b-icon-tree-collapse:before {
  font-size: 1.2em; }

.b-tree-cell-inner {
  padding: 0.5em 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1; }

a.b-tree-cell-inner {
  text-decoration: none; }
  a.b-tree-cell-inner:hover .b-tree-cell-value {
    text-decoration: underline; }

.b-tree-parent-cell,
.b-tree-leaf-cell {
  padding-left: 10px; }

.b-tree-cell.b-tree-parent-cell {
  font-weight: 600;
  color: #797b7d; }

.b-tree-cell-value {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center; }

.b-touch .b-tree-expander {
  width: 1.1em; }

.b-touch .b-icon-tree-expand:before,
.b-touch .b-icon-tree-collapse:before {
  font-size: 1.8em; }

.b-touch .b-icon-tree-leaf:before {
  font-size: .6em; }

.b-touch .b-tree-icon,
.b-touch .b-tree-leaf-cell:not(.b-tree-parent-cell):before {
  font-size: 1.2em; }

.b-widget-cell .b-button {
  height: 80%;
  width: 100%; }

.b-cell-editor {
  position: absolute;
  background-color: transparent;
  border-width: 0;
  border-style: solid;
  box-shadow: none !important;
  z-index: 1;
  /*transition: left 0.1s, top 0.1s;*/ }
  .b-cell-editor > .b-widget {
    width: 100%;
    height: 100%; }
  .b-cell-editor .b-numberfield,
  .b-cell-editor .b-textfield {
    align-items: stretch; }
    .b-cell-editor .b-numberfield .b-field-inner,
    .b-cell-editor .b-textfield .b-field-inner {
      padding-left: 0; }
    .b-cell-editor .b-numberfield input,
    .b-cell-editor .b-textfield input {
      padding: 0.8em calc(0.5em - 1px); }

.b-cell-editor-hidden {
  display: none; }

.b-grid-row .b-grid-cell.b-focused.b-editing:after {
  display: none; }

.b-columndragtoolbar {
  position: absolute;
  top: calc(100% - 3em);
  left: 50%;
  z-index: 100;
  animation-name: b-show-columndragtoolbar;
  animation-duration: 0.2s;
  transform: translateX(-50%);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  background: #f8f9fa;
  transition: opacity 0.2s, top 0.2s;
  box-shadow: none;
  padding: 1em 1em 0.5em 1em;
  opacity: 0.4;
  font-size: .8em; }
  .b-columndragtoolbar.b-closer {
    top: 50%;
    transform: translate(-50%, -50%); }
  .b-columndragtoolbar.b-hover {
    opacity: 0.8; }
  .b-columndragtoolbar.b-remove {
    animation-name: b-hide-columndragtoolbar;
    animation-duration: 0.2s;
    top: 100%;
    opacity: 0; }
    .b-columndragtoolbar.b-remove.b-closer {
      animation-name: b-hide-columndragtoolbar-closer; }
  .b-columndragtoolbar > .b-title {
    color: #fff;
    flex-basis: 100%;
    margin-bottom: 1em;
    text-align: center; }
    .b-columndragtoolbar > .b-title:before {
      content: 'Drag header downwards'; }
  .b-columndragtoolbar.b-closer > .b-title:before {
    content: 'Drop header on a button'; }
  .b-columndragtoolbar .b-group {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1.5em; }
    .b-columndragtoolbar .b-group:last-child {
      margin-right: 0; }
    .b-columndragtoolbar .b-group .b-title {
      color: #fff;
      margin-top: .5em;
      margin-bottom: .5em; }
  .b-columndragtoolbar .b-buttons {
    display: inline-flex;
    flex-direction: row; }
  .b-columndragtoolbar .b-target-button {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    transition: all .2s;
    color: #4f5964;
    border: 0.3em solid #feac31;
    border-radius: 1em;
    background: transparent;
    padding: 1em 0;
    width: 7em;
    margin-right: .5em;
    box-shadow: none; }
    .b-columndragtoolbar .b-target-button:last-child {
      margin-right: 0; }
    .b-columndragtoolbar .b-target-button i {
      font-size: 2.5em;
      color: #feac31;
      pointer-events: none;
      transition: all .2s; }
    .b-columndragtoolbar .b-target-button.b-hover:not([data-disabled=true]) {
      background-color: #feac31;
      color: #fff;
      box-shadow: none; }
      .b-columndragtoolbar .b-target-button.b-hover:not([data-disabled=true]) i {
        transform: scale(1.1);
        color: #fff; }
    .b-columndragtoolbar .b-target-button.b-activate i {
      transform: scale(1.1) rotate(180deg) !important; }
    .b-columndragtoolbar .b-target-button[data-button-id^=group] {
      border-color: #3183fe;
      background-color: transparent; }
      .b-columndragtoolbar .b-target-button[data-button-id^=group] i {
        color: #3183fe; }
      .b-columndragtoolbar .b-target-button[data-button-id^=group].b-hover:not([data-disabled=true]) {
        background-color: #3183fe; }
        .b-columndragtoolbar .b-target-button[data-button-id^=group].b-hover:not([data-disabled=true]) i {
          color: #fff; }
    .b-columndragtoolbar .b-target-button[data-button-id^=multisort] {
      border-color: #fb9701; }
      .b-columndragtoolbar .b-target-button[data-button-id^=multisort] i {
        color: #fb9701; }
      .b-columndragtoolbar .b-target-button[data-button-id^=multisort].b-hover:not([data-disabled=true]) {
        background-color: #fb9701; }
        .b-columndragtoolbar .b-target-button[data-button-id^=multisort].b-hover:not([data-disabled=true]) i {
          color: #fff; }
    .b-columndragtoolbar .b-target-button[data-disabled=true] {
      opacity: .5; }

@keyframes b-show-columndragtoolbar {
  from {
    top: 100%;
    opacity: 0; }
  to {
    top: calc(100% - 3em);
    opacity: .4; } }

@keyframes b-hide-columndragtoolbar {
  from {
    top: calc(100% - 3em);
    opacity: .4; }
  to {
    top: 100%;
    opacity: 0; } }

@keyframes b-hide-columndragtoolbar-closer {
  from {
    top: 50%;
    opacity: .4; }
  to {
    top: 100%;
    opacity: 0; } }

.b-grid-header.b-drag-proxy {
  line-height: normal;
  font-weight: 500;
  font-size: 14px;
  background-color: #f3f4f5;
  border: 1px solid #d8d9da;
  transition: background-color 0.3s; }
  .b-grid-header.b-drag-proxy .b-grid-header-text {
    padding: 5px; }

.b-grid-header.b-drop-placeholder {
  opacity: .3; }

.b-grid-header.b-drag-invalid {
  color: #e53f2c;
  border: 1px solid #e53f2c; }

.b-gridbase.b-columnresize .b-grid-header.b-resize-handle {
  cursor: ew-resize !important; }

.b-gridbase.b-columnresize .b-grid-header.b-resizing {
  background: white; }

.b-gridbase.b-columnresize.b-sort {
  /*.b-grid-header.b-resizing:hover:not(.b-sort):after {
                display: none;
            }*/ }
  .b-gridbase.b-columnresize.b-sort .b-grid-header.b-resizing:not(.b-filter) .b-filter-icon,
  .b-gridbase.b-columnresize.b-sort .b-grid-header.b-over-resize-handle:not(.b-filter) .b-filter-icon,
  .b-gridbase.b-columnresize.b-sort .b-grid-header.b-resizing:not(.b-sort):after,
  .b-gridbase.b-columnresize.b-sort .b-grid-header.b-over-resize-handle:hover:not(.b-sort):after {
    display: none; }

.b-gridbase.b-columnresize.b-touch.b-column-resizing .b-grid-header.b-resizing {
  overflow: visible;
  z-index: 100; }
  .b-gridbase.b-columnresize.b-touch.b-column-resizing .b-grid-header.b-resizing::before {
    font-size: 1.5em;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateX(50%) translateY(-50%);
    right: 0;
    z-index: 101;
    border-radius: 100%;
    background-color: #d8d9da;
    pointer-events: none;
    padding: 0.5em; }

.b-gridbase.b-columnresize.b-touch.b-column-resizing .b-grid-header:not(.b-resizing) {
  z-index: 1; }

.b-context-menu {
  min-width: 14em; }

@keyframes b-filter-icon-color {
  0% {
    color: #b0b0b7; }
  50% {
    color: #3183fe; }
  100% {
    color: #b0b0b7; } }

.b-grid-header .b-filter-icon {
  display: none;
  cursor: pointer;
  transition: opacity .2s; }
  .b-grid-header .b-filter-icon::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 0.8em;
    color: #e5e7e9;
    margin-left: .5em;
    transition: transform 0.3s, color 0.2s;
    transform: none;
    background: inherit;
    /* Transparent bg will look bad if there is text under the icon */ }
  .b-grid-header .b-filter-icon:hover::after {
    color: #b0b0b7; }

.b-gridbase:not(.b-filter) .b-filter-icon {
  opacity: 0.2;
  pointer-events: none; }

.b-gridbase:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header:hover .b-filter-icon {
  display: flex; }

.b-grid-header.b-filter .b-grid-header-text {
  font-weight: 700;
  color: #666; }

.b-grid-header.b-filter .b-filter-icon {
  display: flex; }
  .b-grid-header.b-filter .b-filter-icon::after {
    color: #b0b0b7; }
  .b-grid-header.b-filter .b-filter-icon.b-latest::after {
    animation-name: b-filter-icon-color;
    animation-duration: 0.75s; }

.b-filter-popup .b-field {
  width: 15em; }

.b-filter-bar-field {
  margin: 0 0 .5em 0;
  width: 100%; }

.b-group-summary .b-grid-row.b-group-footer .b-grid-cell {
  border-right-color: transparent;
  background-color: #fffbf5; }

.b-group-summary .b-grid-row.b-group-footer td {
  padding: .25em 0; }

.b-group-summary .b-grid-row.b-group-footer .b-grid-summary-label {
  padding-right: 1em; }

.b-group-summary .b-grid-row.b-group-footer .b-grid-summary-value {
  width: 100%; }

.b-export {
  overflow: hidden; }
  .b-export .b-export-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%; }
  .b-export .b-export-body {
    flex: 1; }
  .b-export .b-grid-header-container {
    margin-right: 0 !important;
    border-right: none !important; }
  .b-export .b-grid-body-container.b-widget-scroller {
    overflow-y: hidden !important; }
  .b-export .b-grid-footer-container {
    padding-right: 0 !important; }
  .b-export .b-virtual-scrollers {
    display: none; }
  .b-export.b-multipage .b-export-content {
    width: 100%;
    height: 100%; }
  .b-export.b-multipage .b-export-body {
    overflow: hidden; }

.b-quick-hit-cell {
  background: #f5fe8c; }

.b-quick-hit-text {
  font-weight: 400;
  background: #f5fe8c; }

.b-quick-hit-text {
  padding: .3em 0; }

.b-quick-hit-header {
  position: absolute;
  top: .3em;
  left: .3em;
  bottom: .3em;
  right: .3em;
  z-index: 1; }
  .b-quick-hit-header.b-quick-hit-mode-grid {
    bottom: auto;
    height: 3em;
    z-index: 100;
    opacity: 0.75; }

.b-quick-hit-field {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 7px 0;
  background: #f5fe8c; }

.b-quick-hit-cell-badge,
.b-quick-hit-header .b-quick-hit-badge {
  position: absolute;
  top: .4em;
  right: .4em;
  font-size: .7em;
  line-height: .7em; }

.b-quick-hit-header .b-quick-hit-badge {
  color: #7f8a01; }

.b-quick-hit-cell-badge {
  color: #7f8a01; }

.b-grid-splitter {
  z-index: 5;
  position: relative;
  background: #bec0c1;
  flex: 0 0 1px; }
  .b-grid-splitter .b-grid-splitter-inner {
    display: none;
    transition: left 0.2s, width 0.2s;
    width: 5px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    flex-direction: column;
    align-items: center; }
  .b-grid-splitter:not(.b-grid-splitter-collapsed) .b-grid-splitter-inner {
    cursor: col-resize; }

.b-grid-splitter-buttons {
  position: absolute;
  margin-top: -50%;
  display: flex;
  flex-direction: column;
  align-items: center; }

.b-gridbase.b-split .b-grid-splitter {
  flex: 0 0 5px;
  background: #e9eaeb; }
  .b-gridbase.b-split .b-grid-splitter .b-grid-splitter-inner {
    display: flex; }
  .b-gridbase.b-split .b-grid-splitter.b-grid-splitter-collapsed {
    cursor: initial;
    flex: 0 0 10px; }
    .b-gridbase.b-split .b-grid-splitter.b-grid-splitter-collapsed .b-grid-splitter-inner {
      background: #e9eaeb;
      width: 10px; }
    .b-gridbase.b-split .b-grid-splitter.b-grid-splitter-collapsed:not(.b-hover) .b-icon {
      display: inline;
      opacity: 0.5; }
    .b-gridbase.b-split .b-grid-splitter.b-grid-splitter-collapsed:not(.b-grid-splitter-allow-collapse) .b-icon.b-icon-collapse-gridregion {
      display: none; }
    .b-gridbase.b-split .b-grid-splitter.b-grid-splitter-collapsed.b-grid-splitter-allow-collapse .b-icon.b-icon-expand-gridregion {
      display: none; }
  .b-gridbase.b-split .b-grid-splitter.b-left-only:not(.b-grid-splitter-collapsed) .b-icon.b-icon-expand-gridregion {
    display: none; }
  .b-gridbase.b-split .b-grid-splitter.b-right-only:not(.b-grid-splitter-collapsed) .b-icon.b-icon-collapse-gridregion {
    display: none; }
  .b-gridbase.b-split .b-grid-splitter .b-icon {
    display: none;
    cursor: pointer;
    color: #7b7b86;
    font-size: 1.6em;
    line-height: 0.5; }
    .b-gridbase.b-split .b-grid-splitter .b-icon:hover {
      color: #fff; }
  .b-gridbase.b-split .b-grid-splitter.b-hover, .b-gridbase.b-split .b-grid-splitter.b-moving {
    overflow: visible; }
    .b-gridbase.b-split .b-grid-splitter.b-hover .b-grid-splitter-inner, .b-gridbase.b-split .b-grid-splitter.b-moving .b-grid-splitter-inner {
      background: #e9eaeb;
      left: -4px;
      width: 13px; }
    .b-gridbase.b-split .b-grid-splitter.b-hover .b-icon, .b-gridbase.b-split .b-grid-splitter.b-moving .b-icon {
      display: inline; }
  .b-gridbase.b-split .b-grid-splitter.b-grid-splitter-collapsed:nth-child(2):hover:not(.b-moving) .b-grid-splitter-inner {
    left: 0; }

.b-grid-header-scroller.b-collapsed,
.b-grid-footer-scroller.b-collapsed,
.b-virtual-scroller.b-collapsed {
  width: 0; }

.b-touch .b-splitter-scroller,
.b-touch .b-icon-expand-gridregion,
.b-touch .b-icon-collapse-gridregion {
  display: none !important; }

.b-touch .b-moving .b-grid-splitter-inner {
  width: 25px; }

.b-touch .b-moving .b-grid-splitter-main::before {
  z-index: 6;
  font-size: 1.5em;
  color: #fff;
  position: absolute;
  top: 1em;
  left: 6.25px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  background-color: #b0b0b7;
  padding: 0.5em;
  opacity: 0.6; }

.b-draghelper-active .b-gridbase.b-split .b-grid-splitter {
  cursor: initial;
  flex: 0 0 5px !important;
  transform: none !important;
  -ms-transform: none !important; }
  .b-draghelper-active .b-gridbase.b-split .b-grid-splitter .b-icon {
    display: none !important; }
  .b-draghelper-active .b-gridbase.b-split .b-grid-splitter .b-grid-splitter-inner {
    width: 5px !important; }

.b-row-drop-indicator {
  display: none;
  pointer-events: none; }

.b-row-reordering .b-row-drop-indicator {
  position: absolute;
  display: block;
  left: 0;
  top: -1px;
  width: 100%;
  height: 2px;
  background-color: #feac31;
  z-index: 2000; }

.b-row-reordering .b-drag-proxy.b-dragging {
  transition: margin-top 0.2s, opacity 0.2s;
  margin-top: 5em;
  background: transparent;
  opacity: 1; }
  .b-row-reordering .b-drag-proxy.b-dragging .b-grid-row {
    transition: transform 0.2s, background-color 0.2s;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    transform: scale(0.8);
    background: #fff; }
  .b-row-reordering .b-drag-proxy.b-dragging.b-drag-invalid .b-grid-row {
    border: 2px solid #e53f2c; }

.b-row-reordering .b-grid-body-container {
  z-index: 4; }

.b-row-reordering .b-drag-original {
  opacity: 0.3; }

.b-row-reordering .b-drag-proxy.b-dropping {
  margin-top: 0;
  opacity: 0; }
  .b-row-reordering .b-drag-proxy.b-dropping .b-grid-row {
    transform: scale(1); }

.b-row-reordering .b-row-reordering-target-parent .b-tree-cell {
  color: #feac31;
  overflow: visible;
  contain: none; }
  .b-row-reordering .b-row-reordering-target-parent .b-tree-cell .b-tree-expander::before {
    color: #feac31; }
  .b-row-reordering .b-row-reordering-target-parent .b-tree-cell::before {
    position: absolute;
    left: -.5em; }

.b-search-hit-cell {
  background: #f5fe8c; }

.b-search-hit-text {
  font-weight: 400;
  background: #f5fe8c;
  padding: .3em 0; }

.b-search-hit-field {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: .5em 0;
  background: #f5fe8c; }

.b-search-hit-cell-badge {
  position: absolute;
  top: .4em;
  right: .4em;
  font-size: .7em;
  line-height: .7em;
  color: #7f8a01; }

.b-gridbase.b-sort .b-grid-header.b-sort .b-grid-header-text::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 0.8em;
  color: #b0b0b7;
  margin-left: .5em;
  transition: transform 0.3s, color 0.2s;
  transform: none;
  background: inherit;
  /* Transparent bg will look bad if there is text under the icon */ }

.b-gridbase.b-sort .b-grid-header.b-sort.b-desc .b-grid-header-text::after {
  transform: rotate(180deg); }

.b-gridbase.b-sort .b-grid-header.b-sort .b-grid-header-text[data-sort-index]::before {
  content: attr(data-sort-index);
  position: relative;
  top: 1em;
  color: #7b7b86;
  z-index: 30;
  font-size: .6em;
  order: 1; }

.b-gridbase.b-sort .b-grid-header.b-sort .b-grid-header-text {
  color: #667280; }

.b-gridbase.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header.b-sortable.b-depth-0:hover:not(.b-sort):not(.b-group) .b-grid-header-text::after,
.b-gridbase.b-sort:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-grid-header.b-sortable.b-depth-0:focus:not(.b-sort):not(.b-group) .b-grid-header-text::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 0.8em;
  color: #e5e7e9;
  margin-left: .5em;
  transition: transform 0.3s, color 0.2s;
  transform: none;
  background: inherit;
  /* Transparent bg will look bad if there is text under the icon */ }

.b-grid-header.b-group .b-grid-header-text::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 0.8em;
  color: #3183fe;
  margin-left: .5em;
  transition: transform 0.3s, color 0.2s;
  transform: none;
  background: inherit;
  /* Transparent bg will look bad if there is text under the icon */
  transform: rotate(90deg); }

.b-grid-header.b-group.b-desc .b-grid-header-text:after {
  transform: rotate(270deg) scaleX(-1); }

.b-group-row {
  background: #fafbfc; }
  .b-group-row .b-grid-cell {
    border-right-color: transparent; }
  .b-group-row .b-group-title.b-grid-cell-align-right, .b-group-row .b-group-title.b-grid-cell-align-end {
    justify-content: flex-end; }
  .b-group-row .b-group-title.b-grid-cell-align-center {
    justify-content: start; }

.b-grid-cell.b-group-title {
  color: #4f5964;
  overflow: visible;
  contain: size layout style;
  font-weight: 500;
  text-transform: uppercase; }
  .b-firefox .b-grid-cell.b-group-title {
    contain: size layout; }
  .b-grid-cell.b-group-title:before {
    margin-right: .5em;
    cursor: pointer;
    color: #babbbc; }

.b-stripe .b-grid-row.b-group-row,
.b-grid-row.b-group-row {
  border-bottom: 1px solid #d8d9da;
  cursor: pointer; }

.b-stripe .b-grid-row.b-odd {
  background-color: rgba(250, 250, 250, 0.8); }

.b-stripe .b-grid-row.b-even {
  background-color: rgba(255, 255, 255, 0.8); }

.b-stripe .b-grid-row {
  border-bottom: none; }

.b-stripe .b-grid-row.b-selected {
  background-color: rgba(254, 172, 49, 0.25); }
  .b-stripe .b-grid-row.b-selected .b-grid-cell.b-selected {
    background-color: rgba(254, 172, 49, 0.25); }

.b-stripe:focus .b-grid-row.b-selected {
  background-color: rgba(254, 172, 49, 0.3); }

.b-grid-footer-summary td {
  padding: .25em 0; }

.b-grid-footer-summary .b-grid-summary-label {
  font-size: .7em;
  padding-right: 1em; }

.b-grid-footer-summary .b-grid-summary-value {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.b-summary-disabled .b-grid-footer-container {
  display: none; }

.b-exportdialog.b-popup .b-bottom-toolbar > button {
  flex: 1;
  margin-right: .5em; }
  .b-exportdialog.b-popup .b-bottom-toolbar > button:last-child {
    margin-right: 0; }

.b-grid-footer-container {
  background-color: #f3f4f5;
  outline: 1px solid #d8d9da;
  z-index: 2;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08); }
  .b-grid-footer-container.b-hidden {
    display: none; }

.b-grid-footers {
  display: inline-flex;
  align-items: stretch;
  height: 100%;
  white-space: nowrap;
  line-height: initial; }
  .b-overflowing > .b-grid-footers {
    flex: 1; }
  :not(.b-overflowing) > .b-grid-footers {
    min-width: 100%; }

.b-grid-footer {
  display: flex;
  border-right: 1px solid #d8d9da;
  flex-direction: column;
  flex-shrink: 0;
  align-items: stretch;
  justify-content: center;
  position: relative;
  padding: 0.5em .5em;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
  color: #667280;
  overflow: hidden; }

.b-grid-footer:last-child {
  border-right: none; }

.b-grid-footer-align-left {
  text-align: left; }

.b-grid-footer-align-center {
  text-align: center; }

.b-grid-footer-align-right {
  text-align: right; }

.b-gridbase {
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /*
     * This implements the fillLastColumn config. It causes the last leaf header, and the last cell in
     * each row to flex-grow to consume all available space when there are no configured flexed columns and rows are not
     * wider than their container.
     */ }
  .b-gridbase.b-outer {
    height: 100%; }
  .b-gridbase.b-autoheight {
    height: auto;
    flex: none; }
  .b-gridbase:not(.b-autoheight) .b-grid-body-container {
    flex: 1 1 auto;
    contain: strict; }
  .b-gridbase:not(.b-autoheight) > .b-grid-body-container > .b-grid-vertical-scroller {
    min-height: 100%; }
  .b-gridbase.b-grid-translate .b-grid-row, .b-gridbase.b-grid-translate3d .b-grid-row {
    transform-style: flat; }
  .b-gridbase.b-no-column-lines .b-grid-row .b-grid-cell {
    border-right-color: transparent; }
  .b-gridbase.b-fill-last-column .b-grid-horizontal-scroller:not(.b-has-flex):not(.b-overflowing-horizontally) .b-grid-cell:last-child,
  .b-gridbase.b-fill-last-column .b-grid-footer-scroller:not(.b-has-flex):not(.b-overflowing) .b-grid-footer:last-child,
  .b-gridbase.b-fill-last-column .b-grid-header-scroller:not(.b-has-flex):not(.b-overflowing) .b-last-parent,
  .b-gridbase.b-fill-last-column .b-grid-header-scroller:not(.b-has-flex):not(.b-overflowing) .b-last-leaf {
    flex-grow: 1; }
  .b-gridbase .b-grid-empty .b-grid-subgrid:first-child::before {
    color: #667280;
    content: attr(data-empty-text);
    position: absolute;
    top: 1em;
    left: 1em; }
  .b-gridbase.b-grid-notextselection .b-grid-cell {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .b-gridbase.b-notransition * {
    transition: none !important; }
  .b-gridbase.b-disabled {
    opacity: 0.5; }

.b-grid-row:not(.b-grid-row-updating) * {
  transition: none; }

.b-grid-body-container {
  position: relative; }
  .b-grid-body-container:focus {
    outline: none; }
    .b-grid-body-container:focus .b-grid-row.b-selected {
      background-color: rgba(254, 172, 49, 0.3); }

.b-playing-demo .b-grid-body-container {
  overflow: hidden !important; }

.b-grid-vertical-scroller {
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  width: 100%;
  align-items: stretch; }

.b-grid-row-container {
  display: inline-block;
  min-width: 100%; }

.b-virtual-scrollers {
  display: flex;
  flex-direction: row;
  contain: paint style layout; }
  .b-firefox .b-virtual-scrollers {
    contain: paint layout; }
  .b-virtual-scrollers.b-overlay-scrollbar {
    position: absolute;
    bottom: 0;
    pointer-events: none; }
    .b-virtual-scrollers.b-overlay-scrollbar .b-virtual-scroller {
      height: 20px; }
  .b-visible-scrollbar:not(.b-ie) .b-virtual-scrollers.b-grid-vertical-overflow {
    overflow-y: scroll; }
  .b-virtual-scrollers .b-virtual-scroller {
    overflow-x: scroll;
    overflow-y: hidden; }
    .b-safari .b-virtual-scrollers .b-virtual-scroller {
      overflow-x: scroll !important; }
  .b-virtual-scrollers .b-virtual-width {
    height: 1px; }

/* using tag name to increase specificity, otherwise rule gets overridden by salesforce styles */
.b-grid-footer-container,
header.b-grid-header-container {
  display: flex;
  flex-direction: row; }

.b-grid-header-container {
  border-bottom: 1px solid #d8d9da; }

.b-grid-footer-scroller,
.b-grid-header-scroller {
  overflow: hidden;
  position: relative;
  display: flex; }

.b-grid-footers,
.b-grid-headers {
  z-index: 2;
  contain: paint style layout; }
  .b-firefox .b-grid-footers, .b-firefox
  .b-grid-headers {
    contain: paint layout; }

.b-grid-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: absolute;
  min-width: 100%;
  transition: background-color 0.2s;
  left: 0;
  overflow: hidden;
  border-bottom: 1px solid #e9eaeb;
  height: 45px;
  contain: layout; }

.b-grid-refreshing .b-grid-row {
  transition: none; }

.b-grid-row.b-selected {
  background-color: rgba(254, 172, 49, 0.25); }

.b-grid-row.b-removing {
  left: -100%;
  transition: left .4s;
  pointer-events: none; }

.b-grid-cell {
  position: relative;
  display: flex;
  align-items: center;
  color: #606263;
  font-weight: 400;
  padding: 0 .5em;
  overflow: hidden;
  white-space: nowrap;
  flex-shrink: 0;
  text-overflow: ellipsis;
  border-right: 1px solid #e9eaeb;
  transform-style: flat;
  width: 0;
  contain: strict; }
  .b-grid-cell:last-child {
    border-right-color: transparent; }
  .b-grid-cell > i {
    margin-right: .5em; }
  .b-show-dirty .b-grid-cell.b-cell-dirty:after {
    content: " ";
    position: absolute;
    top: -5px;
    left: -5px;
    background-color: red;
    width: 10px;
    height: 10px;
    transform: rotateZ(45deg); }
  .b-grid-cell.b-focused {
    transition: none !important; }
    .b-grid-cell.b-focused:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      border: 1px solid rgba(49, 131, 254, 0.6) !important;
      border-radius: 0; }
  .b-grid-cell.b-checkbox-selection {
    background-color: #f1f1f1; }

body:not(.b-using-keyboard) .b-group-row .b-grid-cell.b-focused:after {
  content: none; }

.b-grid-cell-align-right, .b-grid-cell-align-end {
  justify-content: flex-end; }

.b-grid-cell-align-center {
  justify-content: center; }

.b-grid-subgrid.b-grid-subgrid-collapsed {
  width: 0; }

.b-grid-subgrid.b-grid-horizontal-scroller {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  -ms-overflow-style: none;
  overscroll-behavior: contain auto; }
  .b-grid-subgrid.b-grid-horizontal-scroller::-webkit-scrollbar {
    display: none; }
  .b-firefox .b-gridbase:not(.b-overlay-scrollbar) .b-grid-subgrid.b-grid-horizontal-scroller,
  .b-gridbase.b-firefox:not(.b-overlay-scrollbar) .b-grid-subgrid.b-grid-horizontal-scroller {
    overflow: hidden !important; }

.b-grid-subgrid.b-grid-subgrid-animate-collapse {
  transition: width .15s; }

.b-playing-demo .b-grid-subgrid {
  overflow: hidden !important; }

.b-grid-header-scroller.b-grid-subgrid-animate-collapse {
  transition: width .15s; }

.b-gridbase:not(.b-moving-splitter) .b-grid-row.b-hover .b-grid-cell {
  background-color: rgba(254, 172, 49, 0.15); }

.b-gridbase:not(.b-moving-splitter) .b-grid-row.b-hover .b-grid-cell:not(.b-focused):hover {
  background-color: rgba(254, 172, 49, 0.1); }

.b-grid-header-container {
  background-color: #f3f4f5;
  z-index: 4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08); }
  .b-grid-header-container.b-hidden {
    display: none; }

.b-grid-headers {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: stretch;
  line-height: initial;
  position: relative; }
  .b-overflowing > .b-grid-headers {
    flex: 1; }
  :not(.b-overflowing) > .b-grid-headers {
    min-width: 100%; }

.b-grid-header-align-right > .b-grid-header-text > .b-grid-header-text-content {
  text-align: right; }

.b-grid-header-align-center > .b-grid-header-text > .b-grid-header-text-content {
  text-align: center; }

.b-gridbase:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-depth-0:hover, .b-gridbase:not(.b-column-resizing) .b-grid-header-container:not(.b-dragging-header) .b-depth-0:focus {
  background-color: white; }

.b-gridbase.b-columnresize .b-grid-header-resizable:not(.b-last-leaf) {
  overflow: visible; }

.b-gridbase.b-columnresize .b-grid-header-resizable .b-grid-header-resize-handle {
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  width: 10px;
  background-color: transparent;
  z-index: 3;
  cursor: col-resize;
  display: block; }

.b-touch-events .b-gridbase.b-columnresize .b-grid-header-resizable .b-grid-header-resize-handle {
  right: -10px;
  width: 20px; }

.b-visible-scrollbar .b-grid-header-container.b-grid-vertical-overflow {
  border-right: 1px solid #d8d9da; }

.b-fill-last-column .b-grid-headers .b-last-parent,
.b-fill-last-column .b-grid-headers .b-last-leaf {
  border-right-color: transparent; }
  .b-fill-last-column .b-grid-headers .b-last-parent > .b-grid-header-text,
  .b-fill-last-column .b-grid-headers .b-last-leaf > .b-grid-header-text {
    border-right-color: transparent; }

.b-ie .b-grid-header.b-depth-1 .b-grid-header-text {
  flex-basis: 1em; }

/* Need some rules to be global to be used in drag proxies*/
.b-grid-header-text {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 1em 0 1em 0;
  white-space: nowrap;
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  overflow: hidden; }
  .b-grid-header-text > .b-grid-header-text-content {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 0;
    width: 0;
    font-size: 0.9em; }
    .b-ie .b-grid-header-text > .b-grid-header-text-content {
      flex: 1 1 0%; }
    .b-grid-header-text > .b-grid-header-text-content > i {
      margin-right: .5em; }

.b-grid-header-children {
  display: flex;
  flex-direction: column; }
  .b-grid-header-children > * {
    width: inherit; }

.b-grid-header-parent > .b-grid-header-children {
  border-top: 1px solid #d8d9da;
  flex-flow: row nowrap; }

.b-grid-header {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  background: #f3f4f5;
  color: #667280;
  outline: none;
  border-right: 1px solid #d8d9da;
  text-transform: uppercase; }
  .b-grid-header:not(.b-depth-0) {
    overflow: visible; }
  .b-grid-header.b-depth-0 {
    padding: 0 0.5em 0 0.5em;
    width: 0;
    cursor: pointer; }
    .b-grid-header.b-depth-0 .b-grid-header-text {
      border-bottom: none;
      transition: background-color 0.2s;
      flex-direction: row; }
  .b-grid-header.b-grid-header-parent {
    border-right: none;
    flex-basis: auto;
    padding-left: 0;
    padding-right: 0; }
    .b-grid-header.b-grid-header-parent > .b-grid-header-text {
      padding-left: 0.5em;
      padding-right: 0.5em;
      border-right: 1px solid #d8d9da; }
  .b-grid-header .b-grid-header-resize-handle {
    display: none; }

.b-gridbase.b-no-column-lines .b-grid-header {
  border-right-color: transparent; }

.b-sch-style-plain {
  border-radius: 2px; }

.b-gridbase.b-no-column-lines .b-sch-header-timeaxis-cell {
  border-right-color: transparent; }

.b-recurrenceeditor.b-popup .b-recurrenceeditor-content.b-popup-content .b-field.b-recurrencepositionscombo {
  width: 10.1em; }

.b-sch-canvas,
.b-sch-foreground-canvas,
.b-sch-background-canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  height: 100%;
  overflow: hidden; }

.b-sch-background-canvas {
  z-index: 1;
  background-repeat: repeat; }

.b-sch-foreground-canvas {
  z-index: 2; }
  .b-row-reordering .b-sch-foreground-canvas * {
    pointer-events: none !important; }

.b-animating .b-timeline-subgrid {
  pointer-events: none !important; }

.b-scheduler > .b-editor {
  z-index: 5; }

.b-sch-event.b-milestone label,
.b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event {
  font-weight: 500;
  font-size: 13px; }
  .b-milestone .b-sch-event.b-milestone label, .b-milestone
  .b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event {
    color: #888; }

.b-sch-event-wrap {
  position: absolute;
  will-change: contents, width, height, transform;
  transform-style: flat;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s;
  contain: layout; }
  .b-animating .b-sch-event-wrap {
    transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, transform 0.2s, width 0.2s, height 0.2s, font-size 0.2s; }
  .b-toggling-node .b-sch-event-wrap {
    transition: none; }
  .b-sch-event-wrap.b-sch-released {
    display: none;
    transition: none; }
  .b-sch-event-wrap.b-active {
    z-index: 15 !important; }
  .b-sch-event-wrap:focus {
    outline: none;
    z-index: 7; }
  .b-scrolling .b-sch-event-wrap {
    transition: none !important;
    pointer-events: none; }
    .b-scrolling .b-sch-event-wrap .b-sch-event {
      transition: none !important; }

.b-sch-event-wrap.b-milestone-wrap {
  min-width: 1em;
  margin-left: -0.5em;
  z-index: 4; }
  .b-sch-event-wrap.b-milestone-wrap .b-sch-event {
    overflow: visible;
    box-shadow: none;
    border-color: #777;
    background-color: transparent; }
    .b-sch-event-wrap.b-milestone-wrap .b-sch-event:not(.b-sch-event-withicon) {
      background-color: transparent;
      width: 1em;
      font-size: inherit;
      min-width: 0; }
      .b-sch-event-wrap.b-milestone-wrap .b-sch-event:not(.b-sch-event-withicon)::before {
        background-color: #777; }
    .b-sch-event-wrap.b-milestone-wrap .b-sch-event.b-sch-event-withicon {
      justify-content: center; }
      .b-sch-event-wrap.b-milestone-wrap .b-sch-event.b-sch-event-withicon .b-fa, .b-sch-event-wrap.b-milestone-wrap .b-sch-event.b-sch-event-withicon .b-icon {
        margin-right: 0; }
    .b-sch-event-wrap.b-milestone-wrap .b-sch-event label {
      position: absolute;
      left: 110%;
      top: 50%;
      transform: translateY(-50%);
      font-size: 13px; }
  .b-sch-event-wrap.b-milestone-wrap.b-sch-style-plain > .b-sch-event {
    background-color: transparent; }
  .b-sch-event-wrap.b-milestone-wrap .b-fa, .b-sch-event-wrap.b-milestone-wrap .b-icon {
    margin-right: 1em;
    color: #777;
    font-size: inherit; }
  .b-labels-topbottom .b-sch-event-wrap.b-milestone-wrap .b-sch-event:not(.b-sch-event-withicon), .b-labels-topbottom
  .b-sch-event-wrap.b-milestone-wrap .b-task-baseline {
    font-size: 40%; }
  .b-sch-event-wrap.b-milestone-wrap .b-sch-event:not(.b-sch-event-withicon):before,
  .b-sch-event-wrap.b-milestone-wrap .b-task-baseline:before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: ' ';
    height: 0.707107em;
    width: 0.707107em;
    transform-origin: 50% 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-image: inherit;
    border-style: inherit;
    border-color: inherit; }
  .b-sch-event-wrap.b-milestone-wrap .b-task-baseline:before {
    background-color: inherit; }
  .b-sch-event-wrap.b-milestone-wrap.b-active .b-sch-event.b-sch-event-withicon {
    outline: 1px solid rgba(49, 131, 254, 0.6);
    outline-offset: 3px; }
  .b-sch-event-wrap.b-milestone-wrap.b-active .b-sch-event:not(.b-sch-event-withicon):before {
    outline: 1px solid rgba(49, 131, 254, 0.6);
    outline-offset: 3px; }
  .b-sch-event-wrap.b-milestone-wrap.b-measure {
    height: 1em;
    position: fixed;
    top: -10000px;
    left: -10000px;
    visibility: hidden; }
    .b-sch-event-wrap.b-milestone-wrap.b-measure label {
      font-weight: 400; }
    .b-ie .b-sch-event-wrap.b-milestone-wrap.b-measure {
      display: block; }
  .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap {
    margin: 0;
    min-width: 0;
    flex-direction: row; }
    .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap.b-active {
      outline: 1px solid rgba(49, 131, 254, 0.6);
      outline-offset: 3px; }
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap.b-active .b-sch-event.b-milestone,
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap.b-active .b-sch-event.b-milestone:before {
        outline: none; }
    .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone {
      font-size: inherit;
      width: inherit;
      min-width: 0;
      padding: 0;
      margin: 0 0.5em;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1; }
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone label {
        position: static;
        padding: 0;
        color: #fff;
        transform: none;
        z-index: 1; }
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone:before {
        top: 50%;
        left: 0;
        transform-origin: 50% 50%;
        transform: translate(-50%, -50%) rotate(45deg); }
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone:after {
        background: inherit;
        position: absolute;
        top: 0;
        right: 0;
        content: ' ';
        height: 0.707107em;
        width: 0.707107em;
        transform: rotate(-45deg);
        transform-origin: 100% 0;
        border: inherit;
        z-index: 0; }
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone:hover {
        background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)); }
      .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone.b-sch-event-selected {
        background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)); }
        .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap div.b-sch-event.b-milestone.b-sch-event-selected:hover {
          background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)); }
    .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap.b-measure .b-sch-event.b-milestone:before, .b-scheduler.b-sch-layout-milestones .b-sch-event-wrap.b-milestone-wrap.b-measure .b-sch-event.b-milestone:after {
      display: none; }
  .b-sch-event-wrap.b-milestone-wrap.b-sch-event-wrap label.b-sch-label-left {
    margin: 0 0 0 0.5em;
    transform: translateX(calc(-100% - 1em)) translateY(0); }
    .b-labels-topbottom .b-sch-event-wrap.b-milestone-wrap.b-sch-event-wrap label.b-sch-label-left {
      transform: translateX(-100%) translateY(0); }
  .b-sch-event-wrap.b-milestone-wrap.b-sch-event-wrap label.b-sch-label-right {
    margin: 0 0 0 -0.5em;
    transform: translateX(calc(100% + 1em)) translateY(0); }
    .b-labels-topbottom .b-sch-event-wrap.b-milestone-wrap.b-sch-event-wrap label.b-sch-label-right {
      transform: translateX(100%) translateY(0); }

.b-sch-event {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  width: 100%;
  text-align: left;
  padding-left: 0.75em;
  min-width: 1px;
  color: #fff;
  background-color: #27ca37;
  transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s;
  border-color: #27ca37;
  position: relative; }
  .b-toggling-node .b-sch-event {
    transition: none; }
  .b-sch-event.b-sch-event-narrow {
    padding-left: 0; }
  .b-sch-event label {
    color: #888; }
  .b-sch-event:not(.b-milestone) .b-fa,
  .b-sch-event:not(.b-milestone) .b-icon {
    margin-right: .4em;
    line-height: inherit; }
  .b-sch-event:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900; }
  .b-sch-event.b-sch-event-resizing, .b-sch-event:hover {
    z-index: 6; }
  .b-sch-event.b-sch-event-selected,
  .b-sch-event.b-sch-event-selected * {
    font-weight: 500;
    z-index: 6; }
  .b-sch-event:not(.b-milestone).b-sch-event-selected,
  .b-sch-event:not(.b-milestone).b-sch-event-selected * {
    color: #fff; }
  .b-sch-event.b-sch-event-assign-selected {
    animation-name: instance-selected;
    animation-duration: .2s;
    animation-iteration-count: 3;
    animation-timing-function: ease-in-out; }
  .b-sch-event.b-milestone {
    transition: background 0s; }

.b-sch-event-wrap:not(.b-milestone-wrap).b-active .b-sch-event {
  outline: 1px solid rgba(49, 131, 254, 0.6);
  outline-offset: 3px; }

.b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event.b-sch-event-selected {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)); }

.b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)); }

.b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event.b-sch-event-selected:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)); }

.b-sch-event-resizing .b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event,
.b-dragproxy .b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event {
  background-color: #73e47e; }

@keyframes instance-selected {
  0% {
    top: -2px; }
  50% {
    top: 2px; }
  100% {
    top: 0; } }

.b-initial-fade-in .b-first-render {
  opacity: 0;
  animation-name: initial-fade-in;
  animation-duration: 2s;
  animation-fill-mode: forwards; }

@keyframes initial-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.b-initial-zoom-in .b-first-render .b-sch-event {
  animation-name: initial-zoom-in;
  animation-duration: .5s;
  animation-timing-function: ease-in; }

@keyframes initial-zoom-in {
  0% {
    transform: scale(0.01); }
  100% {
    transform: scale(1); } }

.b-initial-slide-from-left .b-first-render {
  left: -100%;
  animation-name: initial-slide-from-left;
  animation-duration: .7s;
  animation-fill-mode: forwards; }

@keyframes initial-slide-from-left {
  0% {
    left: -100%; }
  100% {
    left: 0; } }

.b-initial-slide-from-top .b-first-render {
  top: -100%;
  animation-name: initial-slide-from-top;
  animation-duration: .7s;
  animation-fill-mode: forwards; }

@keyframes initial-slide-from-top {
  0% {
    top: -100%; }
  100% {
    top: 0; } }

.b-scheduler-event-parent:not(.b-milestone-wrap) .b-scheduler-event {
  overflow: visible;
  background-color: #8bc34a;
  border-color: #8bc34a;
  flex: 1 1 0%;
  min-height: 9px;
  clip-path: polygon(-20px 0, calc(100% + 20px) 0, calc(100% + 20px) 100%, 100% 100%, calc(100% - 8px) calc(100% - 8px), 8px calc(100% - 8px), 0 100%, -20px 100%); }

.b-scheduler:not(.b-scheduler-resizing-event-percent):not(.b-resizing-event):not(.b-dragging-event) .b-scheduler-event-parent:not(.b-milestone-wrap):hover .b-scheduler-event {
  background-color: #bcdc97; }

.b-sch-vertical .b-sch-event-wrap {
  contain: strict; }

.b-sch-vertical .b-sch-event {
  align-items: flex-start;
  padding-top: .75em;
  padding-right: .75em;
  contain: strict;
  white-space: normal; }

.b-sch-vertical.b-eventlayout-mixed .b-sch-event {
  opacity: .8; }

.b-schedulerbase.b-animating .b-grid-row {
  transition: height 0.2s, transform 0.2s; }

.b-grid-footer-container .b-sch-timeaxiscolumn {
  padding: 0;
  /*flex    : none !important;*/ }

.b-sch-timeaxis-cell {
  padding: 0;
  flex: none !important;
  align-items: baseline;
  border-right: none;
  /* No focus outline in scheduler */ }
  .b-sch-timeaxis-cell.b-focused::after {
    content: none; }

.b-gridbase .b-timeline-subgrid .b-grid-row.b-selected:not(.b-group-row) {
  background-color: transparent; }

.b-gridbase .b-timeline-subgrid .b-grid-row.b-selected .b-grid-cell.b-sch-timeaxis-cell.b-selected {
  background-color: transparent; }

.b-gridbase.b-timelinebase .b-timeline-subgrid .b-grid-row.b-hover .b-grid-cell,
.b-gridbase.b-timelinebase .b-timeline-subgrid .b-grid-row.b-hover .b-grid-cell:hover {
  background-color: transparent; }

.b-stripe .b-timeline-subgrid .b-grid-row.b-selected.b-odd,
.b-stripe .b-timeline-subgrid .b-grid-row.b-odd,
.b-gridbase.b-stripe .b-timeline-subgrid .b-grid-row.b-selected.b-odd,
.b-gridbase.b-stripe .b-timeline-subgrid .b-grid-row.b-odd {
  background-color: rgba(250, 250, 250, 0.8); }

.b-stripe .b-timeline-subgrid .b-grid-row.b-selected.b-even,
.b-stripe .b-timeline-subgrid .b-grid-row.b-even,
.b-gridbase.b-stripe .b-timeline-subgrid .b-grid-row.b-selected.b-even,
.b-gridbase.b-stripe .b-timeline-subgrid .b-grid-row.b-even {
  background-color: rgba(255, 255, 255, 0.8); }

.b-stripe .b-timeline-subgrid .b-grid-row.b-selected .b-grid-cell.b-selected {
  background-color: transparent; }

.b-stripe .b-timeline-subgrid .b-grid-row.b-selected .b-grid-cell.b-selected {
  background-color: transparent; }

.b-gridbase .b-timeline-subgrid .b-group-row.b-selected,
.b-timeline-subgrid .b-group-row.b-selected,
.b-timeline-subgrid .b-group-row {
  z-index: 3;
  background-color: rgba(250, 251, 252, 0.8); }

.b-timeline-subgrid {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.b-resource-info {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .b-resource-info img {
    width: 2.4em;
    height: 2.4em;
    border-radius: 50%;
    margin-right: .6em; }
  .b-resource-info dt {
    font-weight: 400;
    margin-bottom: .2em; }
  .b-resource-info dd {
    margin: 0;
    font-size: .8em;
    color: #474849; }

.b-columnlines .b-grid-cell:not(:last-child) {
  border-right: 1px solid #e9eaeb; }

.b-columnlines .b-group-row .b-grid-cell {
  border-right-color: transparent; }

.b-column-line,
.b-column-line-major {
  height: 100%;
  position: absolute;
  border-left-width: 1px;
  border-left-style: solid; }

.b-column-line {
  border-color: #f4f5f5; }

.b-column-line-major {
  border-color: #d0d3d5; }

.b-sch-vertical .b-column-line,
.b-sch-vertical .b-column-line-major {
  height: auto;
  width: 100%;
  position: absolute;
  border-left: none;
  border-top-width: 1px;
  border-top-style: solid; }

.b-sch-foreground-canvas > svg {
  overflow: visible;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 3; }
  .b-sch-foreground-canvas > svg marker {
    fill: #bbb; }
    .b-sch-foreground-canvas > svg marker path {
      stroke: #bbb;
      fill: inherit;
      stroke-width: 1 !important; }
  .b-sch-foreground-canvas > svg polyline {
    pointer-events: visibleStroke;
    fill: transparent;
    marker-end: url("#arrowEnd");
    stroke: #bbb;
    stroke-width: 1;
    transition: stroke-width 0.2s linear; }
    .b-sch-foreground-canvas > svg polyline.b-sch-bidirectional-line {
      marker-start: url("#arrowEnd"); }
    .b-sch-foreground-canvas > svg polyline.b-sch-dependency-over {
      stroke-width: 2;
      stroke-dasharray: 0; }
    .b-sch-foreground-canvas > svg polyline.b-sch-released {
      display: none; }

.b-edge .b-sch-foreground-canvas polyline.b-sch-bidirectional-line,
.b-ie .b-sch-foreground-canvas polyline.b-sch-bidirectional-line {
  marker-start: url("#arrowStart"); }

.b-edge .b-sch-foreground-canvas .b-no-start-marker,
.b-ie .b-sch-foreground-canvas .b-no-start-marker {
  marker-start: none; }

.b-edge .b-sch-foreground-canvas .b-no-end-marker,
.b-ie .b-sch-foreground-canvas .b-no-end-marker {
  marker-end: none; }

.b-dependencies .b-sch-event.b-sch-terminals-visible,
.b-dependencies .b-gantt-task.b-sch-terminals-visible {
  overflow: visible; }

.b-sch-terminal {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 50%;
  position: absolute;
  z-index: 6;
  cursor: pointer; }
  .b-sch-terminal.b-sch-terminal:hover {
    background-color: #e6e6e6; }
  .b-dragging .b-sch-terminal,
  .b-sch-event-resizing .b-sch-terminal {
    display: none; }

.b-creating-dependency .b-sch-terminal:hover {
  background-color: #e6e6e6; }
  .b-creating-dependency .b-sch-terminal:hover.b-valid {
    background-color: #9eeca5; }
  .b-creating-dependency .b-sch-terminal:hover.b-invalid {
    background-color: #f6bbb4; }

.b-sch-terminal-top {
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-80%); }

.b-sch-terminal-bottom {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(80%); }

.b-sch-terminal-left {
  left: 0;
  top: 50%;
  transform: translateX(-80%) translateY(-50%); }

.b-sch-terminal-right {
  right: 0;
  top: 50%;
  transform: translateX(80%) translateY(-50%); }

.b-labels-topbottom .b-schedulerbase:not(.b-sch-layout-milestones) .b-milestone:not(.b-sch-event-withicon) .b-sch-terminal-left, .b-labels-topbottom
.b-gantt:not(.b-sch-layout-milestones) .b-milestone:not(.b-gantt-task-withicon) .b-sch-terminal-left {
  transform: translate(-0.35em, -50%); }

.b-labels-topbottom .b-schedulerbase:not(.b-sch-layout-milestones) .b-milestone:not(.b-sch-event-withicon) .b-sch-terminal-right, .b-labels-topbottom
.b-gantt:not(.b-sch-layout-milestones) .b-milestone:not(.b-gantt-task-withicon) .b-sch-terminal-right {
  transform: translate(0.35em, -50%); }

.b-sch-dependency-connector {
  position: absolute;
  contain: strict;
  border-top: 2px dashed #bbb;
  z-index: 10;
  transform-origin: 0 0;
  pointer-events: none; }
  .b-sch-dependency-connector.b-removing {
    transition: width 0.2s ease-out; }
  .b-sch-dependency-connector.b-valid {
    border-color: #27ca37;
    border-top-style: solid; }

.b-sch-dependency-creation-tooltip.b-popup {
  max-width: none; }

.b-sch-dependency-creation-tooltip .b-popup-header {
  padding: 0.5em 0.075em 0.5em 0.075em;
  background-color: #27ca37; }
  .b-sch-dependency-creation-tooltip .b-popup-header i {
    margin-bottom: .5em; }

.b-sch-dependency-creation-tooltip.b-invalid .b-popup-header {
  background-color: #e53f2c; }

.b-sch-dependency-creation-tooltip.b-checking .b-popup-header {
  background-color: #b0b0b7; }

.b-sch-dependency-creation-tooltip .b-panel-header,
.b-sch-dependency-tooltip .b-panel-header {
  color: #fff; }

.b-sch-dependency-creation-tooltip td:first-child,
.b-sch-dependency-tooltip td:first-child {
  padding-right: 5px;
  font-size: 12px; }

.b-sch-dependency-creation-tooltip .b-sch-box,
.b-sch-dependency-tooltip .b-sch-box {
  width: 12px;
  height: 8px;
  border: 1px solid #bbb;
  display: none;
  margin-left: 5px;
  position: relative;
  top: 2px; }
  .b-sch-dependency-creation-tooltip .b-sch-box.b-left, .b-sch-dependency-creation-tooltip .b-sch-box.b-right, .b-sch-dependency-creation-tooltip .b-sch-box.b-top, .b-sch-dependency-creation-tooltip .b-sch-box.b-bottom,
  .b-sch-dependency-tooltip .b-sch-box.b-left,
  .b-sch-dependency-tooltip .b-sch-box.b-right,
  .b-sch-dependency-tooltip .b-sch-box.b-top,
  .b-sch-dependency-tooltip .b-sch-box.b-bottom {
    display: block; }
  .b-sch-dependency-creation-tooltip .b-sch-box:after,
  .b-sch-dependency-tooltip .b-sch-box:after {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #888;
    position: absolute; }
  .b-sch-dependency-creation-tooltip .b-sch-box.b-right:after,
  .b-sch-dependency-tooltip .b-sch-box.b-right:after {
    right: -2px;
    top: 50%;
    transform: translateY(-50%); }
  .b-sch-dependency-creation-tooltip .b-sch-box.b-left:after,
  .b-sch-dependency-tooltip .b-sch-box.b-left:after {
    left: -2px;
    top: 50%;
    transform: translateY(-50%); }
  .b-sch-dependency-creation-tooltip .b-sch-box.b-top:after,
  .b-sch-dependency-tooltip .b-sch-box.b-top:after {
    top: -2px;
    left: 50%;
    transform: translateX(-50%); }
  .b-sch-dependency-creation-tooltip .b-sch-box.b-bottom:after,
  .b-sch-dependency-tooltip .b-sch-box.b-bottom:after {
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%); }

.b-predecessor-list {
  display: flex;
  flex-direction: column; }
  .b-predecessor-list > * {
    flex: 0 0 auto;
    align-self: stretch;
    width: auto; }
  .b-predecessor-list > .b-list-item .b-predecessor-item-text {
    flex: 1; }
  .b-predecessor-list > .b-list-item .b-sch-box {
    width: 1.2em;
    height: .9em;
    border: 1px solid #bbb;
    margin-left: .5em;
    position: relative; }
    .b-predecessor-list > .b-list-item .b-sch-box:after {
      content: '';
      width: .5em;
      height: .5em;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      border: 1px solid #bbb;
      display: none; }
  .b-predecessor-list > .b-list-item.b-selected .b-sch-box {
    background-color: #27ca37; }
  .b-predecessor-list > .b-list-item.b-selected.b-fs .b-sch-box.b-from:after {
    right: -.25em;
    top: 50%;
    transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-fs .b-sch-box.b-to:after {
    left: -.25em;
    top: 50%;
    transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-sf .b-sch-box.b-from:after {
    left: -.25em;
    top: 50%;
    transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-sf .b-sch-box.b-to:after {
    right: -.25em;
    top: 50%;
    transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-ss .b-sch-box.b-from:after {
    left: -.25em;
    top: 50%;
    transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-ss .b-sch-box.b-to:after {
    left: -.25em;
    top: 50%;
    transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-ff .b-sch-box.b-from:after {
    right: -.25em;
    top: 50%;
    transform: translateY(-50%);
    display: block; }
  .b-predecessor-list > .b-list-item.b-selected.b-ff .b-sch-box.b-to:after {
    right: -.25em;
    top: 50%;
    transform: translateY(-50%);
    display: block; }

.b-dependencyeditor.b-popup {
  width: 29em; }
  .b-dependencyeditor.b-popup .b-popup-content {
    padding: 1em 1em 0 1em; }
    .b-dependencyeditor.b-popup .b-popup-content .b-textfield label {
      flex: 1 0 4em; }
  .b-dependencyeditor.b-popup .b-bottom-toolbar {
    padding: 1em 1em 0.4em 1em; }
    .b-dependencyeditor.b-popup .b-bottom-toolbar .b-label-filler {
      display: none; }
    .b-dependencyeditor.b-popup .b-bottom-toolbar > button {
      flex: 1;
      margin-right: .5em; }
      .b-dependencyeditor.b-popup .b-bottom-toolbar > button:last-child {
        margin-right: 0; }

.b-dragselect-rect {
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: 0 0;
  pointer-events: none;
  z-index: 100;
  border: 1px dashed #474849; }

.b-schedulerbase.b-dragging-event {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.b-sch-event-wrap.b-dragging {
  z-index: 7;
  pointer-events: none;
  opacity: 0.65; }
  .b-sch-event-wrap.b-dragging.b-drag-main {
    z-index: 10;
    opacity: 1; }

.b-drag-proxy .b-sch-event-wrap {
  opacity: 0.5; }

.b-sch-event-wrap.b-aborting {
  transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, transform 0.2s; }

.b-sch-tip-message {
  margin-top: 0.5em; }
  .b-sch-tip-message:empty {
    display: none; }

.b-schedulerbase.b-dragcreating .b-sch-timeaxis-cell {
  pointer-events: none; }

.b-schedulerbase.b-dragcreating-proxy-sizing {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* prevent other events from reacting to hover during drag create */ }
  .b-schedulerbase.b-dragcreating-proxy-sizing .b-sch-event-wrap {
    pointer-events: none; }

.b-sch-dragcreator-proxy {
  background-color: rgba(94, 224, 117, 0.6);
  z-index: 7;
  position: absolute;
  pointer-events: all;
  font-size: 13px; }

.b-eventeditor.b-popup {
  width: 29em; }
  .b-eventeditor.b-popup .b-eventeditor-content.b-popup-content {
    padding: 1em 1em 0 1em; }
    .b-eventeditor.b-popup .b-eventeditor-content.b-popup-content .b-textfield label {
      flex: 1 0 6em; }
  .b-eventeditor.b-popup .b-bottom-toolbar {
    padding: 1em 1em 0.4em 1em; }
    .b-eventeditor.b-popup .b-bottom-toolbar .b-label-filler {
      width: 6.6em; }
    .b-eventeditor.b-popup .b-bottom-toolbar > button {
      flex: 1;
      margin-right: .5em; }
      .b-eventeditor.b-popup .b-bottom-toolbar > button:last-child {
        margin-right: 0; }

.b-ie .b-eventfilter {
  flex: 0 1 200px; }

.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-start:before, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-end:after, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:before, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:after,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-start:before,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-end:after,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:before,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:after {
  content: '';
  width: 4px;
  height: 50%;
  top: 25%;
  position: absolute;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  pointer-events: none; }

.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:before, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-start:before,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:before,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-start:before {
  margin-left: 3px;
  left: 0;
  border-width: 0 1px; }

.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:after, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-end:after, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:hover:after, .b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-end:hover:after,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:after,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-end:after,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:hover:after,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-end:hover:after {
  margin-right: 3px;
  right: 0;
  border-width: 0 1px; }

.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event.b-resize-handle {
  cursor: ew-resize; }

.b-eventresize:not(.b-sch-vertical):not(.b-readonly).b-resizing-event,
.b-eventresize:not(.b-sch-vertical):not(.b-readonly) .b-sch-event-resizing {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: ew-resize !important; }

.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-start:before, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-end:after, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:before, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:after,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-start:before,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-end:after,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:before,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:after {
  content: '';
  height: 4px;
  width: 2em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.5);
  pointer-events: none; }

.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:before, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-start:before,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:before,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-start:before {
  margin-top: 3px;
  top: 0;
  border-width: 1px 0; }

.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:after, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-end:after, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-true:hover:after, .b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-wrap.b-sch-event-hover .b-sch-event.b-sch-event-resizable-end:hover:after,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:after,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-end:after,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-true:hover:after,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing.b-sch-event-resizable-end:hover:after {
  margin-bottom: 3px;
  bottom: 0;
  border-width: 1px 0; }

.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event.b-resize-handle {
  cursor: ns-resize; }

.b-eventresize.b-sch-vertical:not(.b-readonly).b-resizing-event,
.b-eventresize.b-sch-vertical:not(.b-readonly) .b-sch-event-resizing {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: ns-resize !important; }

.b-sch-event-wrap.b-sch-event-wrap-resizing {
  z-index: 7; }

.b-schedulerbase.b-resizing-event .b-sch-timeaxis-cell,
.b-schedulerbase.b-resizing-event .b-sch-event-wrap {
  /* prevent drag create */
  pointer-events: none; }

.b-popup.b-sch-event-tooltip {
  max-width: 30em; }
  .b-popup.b-sch-event-tooltip .b-sch-clockwrap {
    margin-top: .5em; }
  .b-popup.b-sch-event-tooltip .b-sch-clockwrap:first-child {
    margin-top: 0; }
  .b-popup.b-sch-event-tooltip .b-sch-event-title {
    font-weight: 600; }

.b-timeaxis-group-summary {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%; }
  .b-timeaxis-group-summary .b-timeaxis-tick {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center; }
  .b-timeaxis-group-summary .b-timeaxis-summary-value {
    text-align: center; }

.b-gridbase.b-schedulerbase .b-timeline-subgrid .b-grid-row.b-group-footer.b-hover .b-grid-cell,
.b-gridbase.b-schedulerbase .b-timeline-subgrid .b-grid-row.b-group-footer.b-hover .b-grid-cell:hover {
  background-color: #fffbf5; }

.b-headerzoom-rect {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  transform-origin: 0 0;
  pointer-events: none;
  z-index: 100;
  background: rgba(152, 193, 255, 0.3); }

.b-ie .b-sch-event-wrap .b-sch-label-right, .b-ie .b-gantt-task-wrap .b-sch-label-right {
  right: -5px;
  top: 50%;
  transform: translate3d(100%, -50%, 0); }

.b-ie .b-sch-event-wrap .b-sch-label-left, .b-ie .b-gantt-task-wrap .b-sch-label-left {
  left: -5px;
  top: 50%;
  transform: translate3d(-100%, -50%, 0); }

.b-sch-event-wrap .b-sch-label, .b-gantt-task-wrap .b-sch-label {
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  margin: .2em 0;
  color: #888;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: all; }
  .b-sch-event-wrap .b-sch-label.b-sch-label-top, .b-gantt-task-wrap .b-sch-label.b-sch-label-top {
    order: -2; }
  .b-sch-event-wrap .b-sch-label.b-sch-label-left, .b-gantt-task-wrap .b-sch-label.b-sch-label-left {
    order: -1; }

.b-sch-event-wrap .b-sch-label-left, .b-gantt-task-wrap .b-sch-label-left {
  position: absolute;
  align-self: self-start;
  transform: translate3d(calc(-100% - 1.5em), 0, 0);
  margin: 0; }

.b-sch-event-wrap .b-sch-label-right, .b-gantt-task-wrap .b-sch-label-right {
  position: absolute;
  align-self: flex-end;
  transform: translate3d(calc(100% + 1.5em), 0, 0);
  margin: 0; }

.b-baselines .b-sch-event-wrap.b-has-baselines .b-sch-label-left, .b-baselines .b-sch-event-wrap.b-has-baselines .b-sch-label-right, .b-baselines .b-gantt-task-wrap.b-has-baselines .b-sch-label-left, .b-baselines .b-gantt-task-wrap.b-has-baselines .b-sch-label-right {
  top: 0;
  line-height: 85%; }

.b-pan .b-timeline-subgrid {
  cursor: move; }

.b-sch-resourcetimerange {
  display: flex;
  position: absolute;
  background-color: rgba(255, 235, 59, 0.1);
  color: #ffe60c;
  z-index: 2;
  transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s;
  font-size: 12px;
  justify-content: center;
  align-items: flex-end;
  padding: 3px; }
  .b-animating .b-sch-resourcetimerange {
    transition: background-color 0.2s, opacity 0.2s, font-weight 0.2s, font-size 0.2s, border 0.2s, transform 0.2s, width 0.2s, height 0.2s, font-size 0.2s; }
  .b-sch-resourcetimerange.b-sch-released {
    display: none;
    transition: none; }

.b-sch-vertical .b-sch-resourcetimerange {
  justify-content: center;
  align-items: flex-start; }

.b-sch-color-red.b-sch-resourcetimerange {
  background-color: rgba(229, 63, 44, 0.1);
  color: #d02b18; }

.b-sch-color-pink.b-sch-resourcetimerange {
  background-color: rgba(254, 49, 131, 0.1);
  color: #fe0468; }

.b-sch-color-purple.b-sch-resourcetimerange {
  background-color: rgba(254, 49, 234, 0.1);
  color: #fe04e5; }

.b-sch-color-violet.b-sch-resourcetimerange {
  background-color: rgba(172, 49, 254, 0.1);
  color: #9a04fe; }

.b-sch-color-indigo.b-sch-resourcetimerange {
  background-color: rgba(69, 49, 254, 0.1);
  color: #1c04fe; }

.b-sch-color-blue.b-sch-resourcetimerange {
  background-color: rgba(49, 131, 254, 0.1);
  color: #0468fe; }

.b-sch-color-cyan.b-sch-resourcetimerange {
  background-color: rgba(49, 233, 254, 0.1);
  color: #04e4fe; }

.b-sch-color-teal.b-sch-resourcetimerange {
  background-color: rgba(49, 254, 172, 0.1);
  color: #04fe9a; }

.b-sch-color-green.b-sch-resourcetimerange {
  background-color: rgba(39, 202, 55, 0.1);
  color: #1fad2d; }

.b-sch-color-lime.b-sch-resourcetimerange {
  background-color: rgba(118, 230, 44, 0.1);
  color: #61d117; }

.b-sch-color-yellow.b-sch-resourcetimerange {
  background-color: rgba(255, 235, 59, 0.1);
  color: #ffe60c; }

.b-sch-color-orange.b-sch-resourcetimerange {
  background-color: rgba(254, 172, 49, 0.1);
  color: #fe9a04; }

.b-simpleeventeditor {
  z-index: 10; }
  .b-simpleeventeditor .b-field {
    align-items: stretch; }

.b-grid-footer.b-sch-timeaxiscolumn {
  padding: 0;
  flex-direction: row;
  justify-content: start;
  flex-grow: unset !important; }
  .b-grid-footer.b-sch-timeaxiscolumn .b-timeaxis-tick {
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    justify-content: center;
    align-items: stretch;
    padding: .5em 0; }
  .b-grid-footer.b-sch-timeaxiscolumn .b-timeaxis-tick:not(:last-child) {
    border-right: 1px solid #d8d9da; }

.b-timeaxis-summary-tip {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .b-timeaxis-summary-tip header {
    width: 100%;
    text-align: center;
    font-weight: 700;
    margin-bottom: 1em; }
  .b-timeaxis-summary-tip label {
    flex: 1 1 50%; }
  .b-timeaxis-summary-tip .b-timeaxis-summary-value {
    flex: 0 0 auto; }

.b-ie .b-grid-footer.b-sch-timeaxiscolumn .b-timeaxis-tick {
  flex-shrink: 0; }

.b-float-root > .b-floating.b-interaction-tooltip {
  pointer-events: none; }

.b-sch-timerange {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 0;
  overflow: hidden;
  justify-content: center;
  color: #aaa;
  z-index: 2;
  cursor: pointer; }
  .b-sch-timerange.b-over-resize-handle {
    cursor: ew-resize; }
  .b-schedulerbase:not(.b-dragging-timerange) .b-sch-timerange:hover {
    z-index: 4; }
  .b-sch-timerange label {
    font-size: .8em;
    color: inherit;
    white-space: nowrap;
    cursor: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0.3em; }

.b-sch-current-time {
  pointer-events: none; }

.b-animating .b-sch-timerange {
  transition: transform 0.3s, width 0.3s, height 0.3s; }

.b-timeline-subgrid .b-sch-timerange {
  height: 100%;
  align-items: start;
  min-width: 1px;
  color: #aaa;
  font-size: 14px; }
  .b-timeline-subgrid .b-sch-timerange:not(.b-sch-line) label.b-vertical {
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr; }
  .b-timeline-subgrid .b-sch-timerange.b-sch-line:before {
    display: none; }

.b-timeline-subgrid .b-sch-range {
  background-color: rgba(240, 240, 240, 0.5);
  pointer-events: none; }

.b-timeline-subgrid .b-sch-line {
  border-left: 2px dotted #fab005;
  color: #fff;
  padding: 0;
  z-index: 3; }
  .b-timeline-subgrid .b-sch-line label {
    background-color: #fab005;
    padding: 0.3em;
    white-space: nowrap;
    margin-top: 0.5em; }

.b-timeline-subgrid .b-sch-current-time {
  border-left-color: #fa5252; }

.b-sch-vertical .b-sch-timerange {
  left: auto;
  top: 0; }
  .b-sch-vertical .b-sch-timerange label {
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    transform: rotate(180deg); }
  .b-sch-vertical .b-sch-timerange.b-over-resize-handle {
    cursor: ns-resize; }

.b-sch-vertical .b-timeline-subgrid .b-sch-timerange {
  height: auto;
  width: 100%; }

.b-sch-vertical .b-timeline-subgrid .b-sch-line {
  border-top: 2px dotted #fab005;
  color: #fff;
  padding: 0; }
  .b-sch-vertical .b-timeline-subgrid .b-sch-line label {
    background-color: #fab005;
    padding: 0.3em;
    white-space: nowrap;
    margin-left: 0.5em; }

.b-grid-header .b-sch-timerange {
  flex-direction: row;
  justify-content: center;
  bottom: 0;
  background-color: #98c1ff;
  color: #fff;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .b-grid-header .b-sch-timerange.b-sch-line {
    padding: 0 .5em;
    background-color: #fab005;
    z-index: 3; }
  .b-grid-header .b-sch-timerange.b-sch-current-time {
    background-color: #fa5252; }
  .b-grid-header .b-sch-timerange:before {
    margin-right: 0.3em; }
  .b-grid-header .b-sch-timerange label {
    padding: 0; }

.b-grid-header.b-sch-timeaxiscolumn-levels-1 .b-sch-timerange {
  height: 100%; }

.b-grid-header.b-sch-timeaxiscolumn-levels-2 .b-sch-timerange {
  height: 50%; }

.b-grid-header.b-sch-timeaxiscolumn-levels-3 .b-sch-timerange {
  height: 33.33333%; }

.b-grid-header .b-sch-line {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px; }
  .b-grid-header .b-sch-line.b-sch-timerange-with-headerlabel label {
    left: -2px; }

.b-verticaltimeaxiscolumn .b-sch-timerange {
  flex-direction: column;
  justify-content: center;
  right: 0;
  background-color: #98c1ff;
  color: #fff;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .b-verticaltimeaxiscolumn .b-sch-timerange:before {
    margin-bottom: 0.5em; }

.b-verticaltimeaxiscolumn.b-sch-timeaxiscolumn-levels-1 .b-sch-timerange {
  width: 100%; }

.b-verticaltimeaxiscolumn.b-sch-timeaxiscolumn-levels-2 .b-sch-timerange {
  width: 50%; }

.b-verticaltimeaxiscolumn.b-sch-timeaxiscolumn-levels-3 .b-sch-timerange {
  width: 33.33333%; }

.b-verticaltimeaxiscolumn .b-sch-line {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }
  .b-verticaltimeaxiscolumn .b-sch-line.b-sch-timerange-with-headerlabel label {
    top: -2px; }

.b-sch-timeranges-with-headerelements .b-timeline-subgrid .b-sch-range:before {
  display: none; }

.b-grid-headers .b-sch-nonworkingtime {
  background-color: rgba(240, 213, 213, 0.3);
  z-index: 1;
  pointer-events: none; }

.b-sch-clockwrap {
  display: flex; }
  .b-sch-clockwrap:not(:first-child) {
    margin-top: .3em; }

.b-sch-clock {
  position: relative;
  height: 21px;
  width: 21px;
  min-width: 21px;
  white-space: nowrap; }
  .b-sch-clock .b-sch-hour-indicator {
    border-top: 2px solid transparent;
    border-bottom: 6px solid #777; }
  .b-sch-clock .b-sch-minute-indicator {
    border-top: 8px solid #777; }
  .b-sch-clock .b-sch-minute-indicator,
  .b-sch-clock .b-sch-hour-indicator {
    position: absolute;
    left: 50%;
    top: 1px;
    width: 2px;
    height: 8px;
    overflow: hidden;
    margin-left: -1px;
    transform-origin: 50% 100%; }

.b-sch-clock-hour .b-sch-clock {
  border: 2px solid #feac31;
  background-color: #f8f8f8;
  border-radius: 100%; }

.b-sch-clock-hour .b-sch-clock-dot {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  margin-top: -2px;
  background: #777;
  border-radius: 3px;
  z-index: 2; }

.b-sch-clock-day .b-sch-clock {
  background-color: #f8f8f8;
  border: none;
  border-radius: 2px; }
  .b-sch-clock-day .b-sch-clock .b-sch-hour-indicator {
    width: inherit;
    position: static !important;
    background-color: #feac31;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-size: 7px;
    line-height: 8px;
    text-align: center;
    color: #fff;
    height: 9px;
    text-indent: 0;
    margin-top: -1px;
    margin-left: 0;
    border: 0 none;
    transform: none !important; }
  .b-sch-clock-day .b-sch-clock .b-sch-minute-indicator {
    width: inherit;
    color: #555;
    position: static !important;
    height: 16px;
    background-color: transparent;
    font-size: 10px;
    text-align: center;
    text-indent: 0;
    line-height: 12px;
    border: none;
    margin-left: 0;
    transform: none !important; }

.b-sch-clock-day .b-sch-clock-dot {
  display: none; }

.b-sch-clock-text {
  margin-left: 8px;
  padding-top: 1px;
  white-space: nowrap;
  width: 100%; }

/*.b-rtl .b-sch-clock-text {
    margin-left  : auto;
    margin-right : 25px;
}*/
.b-sch-column.b-grid-header {
  padding: 0; }

.b-grid-header-container .b-sch-timeaxiscolumn {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0;
  flex-grow: unset !important;
  background-color: #f3f4f5;
  border-right: 0 none; }
  .b-grid-header-container .b-sch-timeaxiscolumn.b-depth-0:hover {
    background-color: #f3f4f5 !important; }
  .b-grid-header-container .b-sch-timeaxiscolumn .b-sch-header-timeaxis-cell:hover {
    background-color: white; }

.b-horizontaltimeaxis {
  flex-direction: column;
  flex: 1; }
  .b-horizontaltimeaxis .b-sch-header-row {
    flex: 1; }
  .b-horizontaltimeaxis .b-sch-header-timeaxis-cell {
    height: 100%; }

.b-sch-timeaxiscolumn-levels-1 {
  min-height: 1.5em; }

.b-sch-timeaxiscolumn-levels-2 {
  min-height: 3em; }

.b-sch-timeaxiscolumn-levels-3 {
  min-height: 4.5em; }

.b-sch-header-row {
  position: relative;
  contain: strict; }
  .b-sch-header-row:last-child .b-sch-header-timeaxis-cell {
    border-bottom: none; }

.b-sch-header-timeaxis-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #667280;
  border-bottom: 1px solid #d8d9da;
  border-right: 1px solid #d8d9da;
  overflow: hidden;
  transition: background-color 0.2s;
  font-size: 0.9em;
  font-weight: 500;
  padding: 0.5em 0 0.5em 0;
  white-space: nowrap;
  contain: strict;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .b-sch-header-timeaxis-cell:hover {
    cursor: pointer;
    background-color: white; }
  .b-sch-header-timeaxis-cell.b-align-start, .b-sch-header-timeaxis-cell.b-align-end {
    padding-left: 0.3em;
    padding-right: 0.3em; }
  .b-sch-header-timeaxis-cell.b-align-start {
    justify-content: flex-start; }
  .b-sch-header-timeaxis-cell.b-align-end {
    justify-content: flex-end; }

.b-scheduler.b-fill-last-column .b-sch-timeaxiscolumn:last-child {
  border-right-color: #d8d9da; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .b-sch-timeaxiscolumn {
    height: 100%; } }

.b-verticaltimeaxiscolumn {
  padding: 0;
  border-right: none;
  background-color: #fbfcfc !important; }
  .b-verticaltimeaxiscolumn.b-grid-cell.b-focused:after {
    display: none; }

.b-verticaltimeaxis {
  flex: 1;
  contain: strict; }
  .b-verticaltimeaxis .b-sch-header-row {
    flex-direction: column; }
  .b-verticaltimeaxis .b-sch-header-timeaxis-cell {
    width: 100%;
    text-transform: uppercase;
    align-items: flex-start;
    padding-top: .5em; }
  .b-verticaltimeaxis .b-sch-header-row:not(.b-lowest) {
    flex: unset;
    width: 2em; }
    .b-verticaltimeaxis .b-sch-header-row:not(.b-lowest) .b-sch-header-text {
      writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
      transform: rotate(180deg); }
  .b-verticaltimeaxis .b-lowest {
    flex: 1; }
    .b-verticaltimeaxis .b-lowest .b-sch-header-timeaxis-cell {
      border-right: none;
      border-bottom: none;
      padding: .5em .5em 0 .5em; }
  .b-verticaltimeaxis .b-last {
    border-bottom: none; }
  .b-verticaltimeaxis .b-sch-header-timeaxis-cell:hover {
    background-color: #f3f4f5; }

.b-sch-vertical .b-sch-timeaxiscolumn {
  flex-direction: row; }

.b-resourceheader {
  flex: 1;
  position: relative;
  contain: strict; }
  .b-resourceheader.b-has-images {
    height: 3em; }
  .b-resourceheader.b-animating .b-resourceheader-cell {
    transition: width .2s, left .2s; }

.b-resourceheader-cell {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #667280;
  border-right: 1px solid #d8d9da;
  overflow: hidden;
  transition: background-color 0.2s;
  font-size: 0.9em;
  font-weight: 500;
  padding: 0.5em;
  white-space: nowrap;
  contain: strict;
  height: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .b-resourceheader-cell:hover {
    cursor: pointer;
    background-color: white; }
  .b-resourceheader-cell.b-align-start, .b-resourceheader-cell.b-align-end {
    padding-left: 0.3em;
    padding-right: 0.3em; }
  .b-resourceheader-cell.b-align-start {
    justify-content: flex-start; }
  .b-resourceheader-cell.b-align-end {
    justify-content: flex-end; }
  .b-resourceheader-cell i {
    margin-right: .5em; }
  .b-resourceheader-cell .b-resource-name {
    overflow: hidden; }
  .b-resourceheader-cell .b-resource-image {
    position: relative;
    min-width: 2em;
    min-height: 2em;
    width: 2em;
    height: 2em;
    margin-right: 1em;
    border-radius: 100%; }

.b-recurrenceconfirmationpopup.b-popup {
  width: 29em;
  max-width: none; }
  .b-recurrenceconfirmationpopup.b-popup .b-recurrenceconfirmationpopup-header,
  .b-recurrenceconfirmationpopup.b-popup .b-recurrenceconfirmationpopup-content {
    padding: 1em; }
  .b-recurrenceconfirmationpopup.b-popup .b-toolbar.b-dock-bottom {
    padding: 0.6em 1em 0; }
  .b-recurrenceconfirmationpopup.b-popup .b-bottom-toolbar > button {
    margin-right: .5em; }
    .b-recurrenceconfirmationpopup.b-popup .b-bottom-toolbar > button:last-child {
      margin-right: 0; }

button.b-button.b-recurrencelegendbutton {
  /* allow lines wrapping for the button since recurrence legend might be a long string */
  white-space: normal; }

.b-recurrenceeditor.b-popup .b-recurrenceeditor-content.b-popup-content {
  padding: 1em 1em 0 1em; }
  .b-recurrenceeditor.b-popup .b-recurrenceeditor-content.b-popup-content .b-field label.b-align-start {
    flex: 1 0 10.4em; }
  .b-recurrenceeditor.b-popup .b-recurrenceeditor-content.b-popup-content .b-recurrencepositionscombo {
    flex: none;
    width: 9.6em; }
  .b-recurrenceeditor.b-popup .b-recurrenceeditor-content.b-popup-content .b-recurrencedayscombo {
    margin-left: 0.5em; }

.b-recurrenceeditor.b-popup .b-bottom-toolbar .b-label-filler {
  width: 10.8em; }

.b-recurrenceeditor.b-popup .b-bottom-toolbar > button {
  flex: 1;
  margin-right: .5em; }
  .b-recurrenceeditor.b-popup .b-bottom-toolbar > button:last-child {
    margin-right: 0; }

.b-sch-red {
  background-color: #e53f2c; }

.b-sch-pink {
  background-color: #fe3183; }

.b-sch-purple {
  background-color: #fe31ea; }

.b-sch-violet {
  background-color: #ac31fe; }

.b-sch-indigo {
  background-color: #4531fe; }

.b-sch-blue {
  background-color: #3183fe; }

.b-sch-cyan {
  background-color: #31e9fe; }

.b-sch-teal {
  background-color: #31feac; }

.b-sch-green {
  background-color: #27ca37; }

.b-sch-lime {
  background-color: #76e62c; }

.b-sch-yellow {
  background-color: #FFEB3B; }

.b-sch-orange {
  background-color: #feac31; }

.b-sch-gray {
  background-color: #a0a0a0; }

.b-sch-style-plain .b-sch-event:not(.b-milestone) {
  background-color: #27ca37;
  border-color: #27ca37;
  color: #fff;
  font-weight: 500; }
  .b-sch-style-plain .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #10801b;
    background-image: none; }
  .b-sch-style-plain .b-sch-event:not(.b-milestone).b-sch-event-selected {
    font-weight: 500; }
  .b-sch-style-plain .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #0d7817;
    background-image: none; }

.b-sch-style-plain .b-sch-event.b-milestone:before {
  background-color: #777; }

.b-sch-style-plain.b-sch-color-red .b-sch-event:not(.b-milestone) {
  background-color: #e53f2c;
  border-color: #e53f2c; }
  .b-sch-style-plain.b-sch-color-red .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-red .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #971b0d; }
  .b-sch-style-plain.b-sch-color-red .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-red .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #8c170a; }

.b-sch-style-plain.b-sch-color-red .b-sch-event.b-milestone:before {
  background-color: #e53f2c;
  border-color: #e53f2c; }

.b-sch-style-plain.b-sch-color-red .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-red .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #971b0d; }

.b-sch-style-plain.b-sch-color-red .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-red .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #8c170a; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-red.b-milestone-wrap .b-sch-event {
  background: #e53f2c; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-red.b-milestone-wrap:hover .b-sch-event {
  background: #971b0d; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-red.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #8c170a; }

.b-sch-style-plain.b-sch-color-pink .b-sch-event:not(.b-milestone) {
  background-color: #fe3183;
  border-color: #fe3183; }
  .b-sch-style-plain.b-sch-color-pink .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-pink .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #b50149; }
  .b-sch-style-plain.b-sch-color-pink .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-pink .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #a60043; }

.b-sch-style-plain.b-sch-color-pink .b-sch-event.b-milestone:before {
  background-color: #fe3183;
  border-color: #fe3183; }

.b-sch-style-plain.b-sch-color-pink .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-pink .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #b50149; }

.b-sch-style-plain.b-sch-color-pink .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-pink .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #a60043; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-pink.b-milestone-wrap .b-sch-event {
  background: #fe3183; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-pink.b-milestone-wrap:hover .b-sch-event {
  background: #b50149; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-pink.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #a60043; }

.b-sch-style-plain.b-sch-color-purple .b-sch-event:not(.b-milestone) {
  background-color: #fe31ea;
  border-color: #fe31ea; }
  .b-sch-style-plain.b-sch-color-purple .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-purple .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #b501a4; }
  .b-sch-style-plain.b-sch-color-purple .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-purple .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #a60096; }

.b-sch-style-plain.b-sch-color-purple .b-sch-event.b-milestone:before {
  background-color: #fe31ea;
  border-color: #fe31ea; }

.b-sch-style-plain.b-sch-color-purple .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-purple .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #b501a4; }

.b-sch-style-plain.b-sch-color-purple .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-purple .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #a60096; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-purple.b-milestone-wrap .b-sch-event {
  background: #fe31ea; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-purple.b-milestone-wrap:hover .b-sch-event {
  background: #b501a4; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-purple.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #a60096; }

.b-sch-style-plain.b-sch-color-violet .b-sch-event:not(.b-milestone) {
  background-color: #ac31fe;
  border-color: #ac31fe; }
  .b-sch-style-plain.b-sch-color-violet .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-violet .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #6d01b5; }
  .b-sch-style-plain.b-sch-color-violet .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-violet .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #6400a6; }

.b-sch-style-plain.b-sch-color-violet .b-sch-event.b-milestone:before {
  background-color: #ac31fe;
  border-color: #ac31fe; }

.b-sch-style-plain.b-sch-color-violet .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-violet .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #6d01b5; }

.b-sch-style-plain.b-sch-color-violet .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-violet .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #6400a6; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-violet.b-milestone-wrap .b-sch-event {
  background: #ac31fe; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-violet.b-milestone-wrap:hover .b-sch-event {
  background: #6d01b5; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-violet.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #6400a6; }

.b-sch-style-plain.b-sch-color-indigo .b-sch-event:not(.b-milestone) {
  background-color: #4531fe;
  border-color: #4531fe; }
  .b-sch-style-plain.b-sch-color-indigo .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-indigo .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #1201b5; }
  .b-sch-style-plain.b-sch-color-indigo .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-indigo .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #1100a6; }

.b-sch-style-plain.b-sch-color-indigo .b-sch-event.b-milestone:before {
  background-color: #4531fe;
  border-color: #4531fe; }

.b-sch-style-plain.b-sch-color-indigo .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-indigo .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #1201b5; }

.b-sch-style-plain.b-sch-color-indigo .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-indigo .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #1100a6; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-indigo.b-milestone-wrap .b-sch-event {
  background: #4531fe; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-indigo.b-milestone-wrap:hover .b-sch-event {
  background: #1201b5; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-indigo.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #1100a6; }

.b-sch-style-plain.b-sch-color-blue .b-sch-event:not(.b-milestone) {
  background-color: #3183fe;
  border-color: #3183fe; }
  .b-sch-style-plain.b-sch-color-blue .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-blue .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #0149b5; }
  .b-sch-style-plain.b-sch-color-blue .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-blue .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #0043a6; }

.b-sch-style-plain.b-sch-color-blue .b-sch-event.b-milestone:before {
  background-color: #3183fe;
  border-color: #3183fe; }

.b-sch-style-plain.b-sch-color-blue .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-blue .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #0149b5; }

.b-sch-style-plain.b-sch-color-blue .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-blue .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #0043a6; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-blue.b-milestone-wrap .b-sch-event {
  background: #3183fe; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-blue.b-milestone-wrap:hover .b-sch-event {
  background: #0149b5; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-blue.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #0043a6; }

.b-sch-style-plain.b-sch-color-cyan .b-sch-event:not(.b-milestone) {
  background-color: #31e9fe;
  border-color: #31e9fe; }
  .b-sch-style-plain.b-sch-color-cyan .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-cyan .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #01a3b5; }
  .b-sch-style-plain.b-sch-color-cyan .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-cyan .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #0095a6; }

.b-sch-style-plain.b-sch-color-cyan .b-sch-event.b-milestone:before {
  background-color: #31e9fe;
  border-color: #31e9fe; }

.b-sch-style-plain.b-sch-color-cyan .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-cyan .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #01a3b5; }

.b-sch-style-plain.b-sch-color-cyan .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-cyan .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #0095a6; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-cyan.b-milestone-wrap .b-sch-event {
  background: #31e9fe; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-cyan.b-milestone-wrap:hover .b-sch-event {
  background: #01a3b5; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-cyan.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #0095a6; }

.b-sch-style-plain.b-sch-color-teal .b-sch-event:not(.b-milestone) {
  background-color: #31feac;
  border-color: #31feac; }
  .b-sch-style-plain.b-sch-color-teal .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-teal .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #01b56d; }
  .b-sch-style-plain.b-sch-color-teal .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-teal .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #00a664; }

.b-sch-style-plain.b-sch-color-teal .b-sch-event.b-milestone:before {
  background-color: #31feac;
  border-color: #31feac; }

.b-sch-style-plain.b-sch-color-teal .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-teal .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #01b56d; }

.b-sch-style-plain.b-sch-color-teal .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-teal .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #00a664; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-teal.b-milestone-wrap .b-sch-event {
  background: #31feac; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-teal.b-milestone-wrap:hover .b-sch-event {
  background: #01b56d; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-teal.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #00a664; }

.b-sch-style-plain.b-sch-color-green .b-sch-event:not(.b-milestone) {
  background-color: #27ca37;
  border-color: #27ca37; }
  .b-sch-style-plain.b-sch-color-green .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-green .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #10801b; }
  .b-sch-style-plain.b-sch-color-green .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-green .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #0d7817; }

.b-sch-style-plain.b-sch-color-green .b-sch-event.b-milestone:before {
  background-color: #27ca37;
  border-color: #27ca37; }

.b-sch-style-plain.b-sch-color-green .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-green .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #10801b; }

.b-sch-style-plain.b-sch-color-green .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-green .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #0d7817; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-green.b-milestone-wrap .b-sch-event {
  background: #27ca37; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-green.b-milestone-wrap:hover .b-sch-event {
  background: #10801b; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-green.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #0d7817; }

.b-sch-style-plain.b-sch-color-lime .b-sch-event:not(.b-milestone) {
  background-color: #76e62c;
  border-color: #76e62c; }
  .b-sch-style-plain.b-sch-color-lime .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-lime .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #44980c; }
  .b-sch-style-plain.b-sch-color-lime .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-lime .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #3e8d0a; }

.b-sch-style-plain.b-sch-color-lime .b-sch-event.b-milestone:before {
  background-color: #76e62c;
  border-color: #76e62c; }

.b-sch-style-plain.b-sch-color-lime .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-lime .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #44980c; }

.b-sch-style-plain.b-sch-color-lime .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-lime .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #3e8d0a; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-lime.b-milestone-wrap .b-sch-event {
  background: #76e62c; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-lime.b-milestone-wrap:hover .b-sch-event {
  background: #44980c; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-lime.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #3e8d0a; }

.b-sch-style-plain.b-sch-color-yellow .b-sch-event:not(.b-milestone) {
  background-color: #FFEB3B;
  border-color: #FFEB3B; }
  .b-sch-style-plain.b-sch-color-yellow .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-yellow .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #bca900; }
  .b-sch-style-plain.b-sch-color-yellow .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-yellow .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #ad9b00; }

.b-sch-style-plain.b-sch-color-yellow .b-sch-event.b-milestone:before {
  background-color: #FFEB3B;
  border-color: #FFEB3B; }

.b-sch-style-plain.b-sch-color-yellow .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-yellow .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #bca900; }

.b-sch-style-plain.b-sch-color-yellow .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-yellow .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #ad9b00; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-yellow.b-milestone-wrap .b-sch-event {
  background: #FFEB3B; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-yellow.b-milestone-wrap:hover .b-sch-event {
  background: #bca900; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-yellow.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #ad9b00; }

.b-sch-style-plain.b-sch-color-orange .b-sch-event:not(.b-milestone) {
  background-color: #feac31;
  border-color: #feac31; }
  .b-sch-style-plain.b-sch-color-orange .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-orange .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #b56d01; }
  .b-sch-style-plain.b-sch-color-orange .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-orange .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #a66400; }

.b-sch-style-plain.b-sch-color-orange .b-sch-event.b-milestone:before {
  background-color: #feac31;
  border-color: #feac31; }

.b-sch-style-plain.b-sch-color-orange .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-orange .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #b56d01; }

.b-sch-style-plain.b-sch-color-orange .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-orange .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #a66400; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-orange.b-milestone-wrap .b-sch-event {
  background: #feac31; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-orange.b-milestone-wrap:hover .b-sch-event {
  background: #b56d01; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-orange.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #a66400; }

.b-sch-style-plain.b-sch-color-gray .b-sch-event:not(.b-milestone) {
  background-color: #a0a0a0;
  border-color: #a0a0a0; }
  .b-sch-style-plain.b-sch-color-gray .b-sch-event:not(.b-milestone):hover, .b-sch-style-plain.b-sch-color-gray .b-sch-event:not(.b-milestone).b-sch-event-selected {
    background-color: #606060; }
  .b-sch-style-plain.b-sch-color-gray .b-sch-event:not(.b-milestone).b-sch-event-resizing, .b-sch-style-plain.b-sch-color-gray .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
    background-color: #614f4f; }

.b-sch-style-plain.b-sch-color-gray .b-sch-event.b-milestone:before {
  background-color: #a0a0a0;
  border-color: #a0a0a0; }

.b-sch-style-plain.b-sch-color-gray .b-sch-event.b-milestone:hover:before, .b-sch-style-plain.b-sch-color-gray .b-sch-event.b-milestone.b-sch-event-selected:before {
  background-color: #606060; }

.b-sch-style-plain.b-sch-color-gray .b-sch-event.b-milestone.b-sch-event-resizing:before, .b-sch-style-plain.b-sch-color-gray .b-sch-event.b-milestone.b-sch-event-selected:hover:before {
  background-color: #614f4f; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-gray.b-milestone-wrap .b-sch-event {
  background: #a0a0a0; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-gray.b-milestone-wrap:hover .b-sch-event {
  background: #606060; }

.b-sch-layout-milestones .b-sch-style-plain.b-sch-color-gray.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #614f4f; }

.b-sch-style-border .b-sch-event {
  background-color: #27ca37;
  border-color: #1b8d27;
  border-style: solid;
  border-width: 0;
  font-weight: 600;
  opacity: .8;
  border-radius: 3px; }
  .b-sch-style-border .b-sch-event:not(.b-milestone) {
    color: #fff;
    font-size: 11px;
    border-width: 1px; }
    .b-sch-style-border .b-sch-event:not(.b-milestone).b-sch-event-startsoutside {
      border-left: none; }
    .b-sch-style-border .b-sch-event:not(.b-milestone):hover, .b-sch-style-border .b-sch-event:not(.b-milestone).b-sch-event-resizing {
      background-color: #1b8d27;
      background-image: none;
      opacity: 1; }
    .b-sch-style-border .b-sch-event:not(.b-milestone).b-sch-event-selected {
      font-weight: 600; }
    .b-sch-style-border .b-sch-event:not(.b-milestone).b-sch-event-selected:hover {
      background-color: #198324;
      background-image: none; }
  .b-sch-style-border .b-sch-event.b-milestone {
    background: none; }
    .b-sch-style-border .b-sch-event.b-milestone:before {
      border-width: 1px;
      border-radius: 3px;
      opacity: .8; }
    .b-sch-style-border .b-sch-event.b-milestone:hover, .b-sch-style-border .b-sch-event.b-milestone.b-sch-event-selected {
      opacity: 1; }
      .b-sch-style-border .b-sch-event.b-milestone:hover:before, .b-sch-style-border .b-sch-event.b-milestone.b-sch-event-selected:before {
        opacity: 1; }

.b-sch-style-border.b-sch-color-red .b-sch-event {
  border-color: #aa2415; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-red .b-sch-event {
  background-color: #e53f2c; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-red .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected {
    background-color: #aa2415; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected:hover {
    background-color: #9e2213; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-red.b-dragging .b-sch-event {
  background-color: #9e2213; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-red .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #e53f2c; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-red .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-red .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #aa2415; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-red .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #9e2213; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap .b-sch-event {
  background: #e53f2c;
  border-top: 1px solid #aa2415;
  border-bottom: 1px solid #aa2415; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap .b-sch-event :before {
    border-color: #aa2415;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap .b-sch-event :after {
    border-color: #aa2415;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #aa2415; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-red.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #9e2213; }

.b-sch-style-border.b-sch-color-pink .b-sch-event {
  border-color: #d30155; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event {
  background-color: #fe3183; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected {
    background-color: #d30155; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected:hover {
    background-color: #c4014f; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-pink.b-dragging .b-sch-event {
  background-color: #c4014f; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-pink .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #fe3183; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-pink .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-pink .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #d30155; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-pink .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #c4014f; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap .b-sch-event {
  background: #fe3183;
  border-top: 1px solid #d30155;
  border-bottom: 1px solid #d30155; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap .b-sch-event :before {
    border-color: #d30155;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap .b-sch-event :after {
    border-color: #d30155;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #d30155; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-pink.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #c4014f; }

.b-sch-style-border.b-sch-color-purple .b-sch-event {
  border-color: #d301bf; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event {
  background-color: #fe31ea; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected {
    background-color: #d301bf; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected:hover {
    background-color: #c401b1; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-purple.b-dragging .b-sch-event {
  background-color: #c401b1; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-purple .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #fe31ea; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-purple .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-purple .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #d301bf; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-purple .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #c401b1; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap .b-sch-event {
  background: #fe31ea;
  border-top: 1px solid #d301bf;
  border-bottom: 1px solid #d301bf; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap .b-sch-event :before {
    border-color: #d301bf;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap .b-sch-event :after {
    border-color: #d301bf;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #d301bf; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-purple.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #c401b1; }

.b-sch-style-border.b-sch-color-violet .b-sch-event {
  border-color: #7f01d3; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event {
  background-color: #ac31fe; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected {
    background-color: #7f01d3; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected:hover {
    background-color: #7601c4; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-violet.b-dragging .b-sch-event {
  background-color: #7601c4; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-violet .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #ac31fe; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-violet .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-violet .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #7f01d3; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-violet .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #7601c4; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap .b-sch-event {
  background: #ac31fe;
  border-top: 1px solid #7f01d3;
  border-bottom: 1px solid #7f01d3; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap .b-sch-event :before {
    border-color: #7f01d3;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap .b-sch-event :after {
    border-color: #7f01d3;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #7f01d3; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-violet.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #7601c4; }

.b-sch-style-border.b-sch-color-indigo .b-sch-event {
  border-color: #1601d3; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event {
  background-color: #4531fe; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected {
    background-color: #1601d3; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover {
    background-color: #1401c4; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-indigo.b-dragging .b-sch-event {
  background-color: #1401c4; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-indigo .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #4531fe; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-indigo .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-indigo .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #1601d3; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-indigo .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #1401c4; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap .b-sch-event {
  background: #4531fe;
  border-top: 1px solid #1601d3;
  border-bottom: 1px solid #1601d3; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap .b-sch-event :before {
    border-color: #1601d3;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap .b-sch-event :after {
    border-color: #1601d3;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #1601d3; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-indigo.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #1401c4; }

.b-sch-style-border.b-sch-color-blue .b-sch-event {
  border-color: #0155d3; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event {
  background-color: #3183fe; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected {
    background-color: #0155d3; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected:hover {
    background-color: #014fc4; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-blue.b-dragging .b-sch-event {
  background-color: #014fc4; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-blue .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #3183fe; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-blue .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-blue .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #0155d3; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-blue .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #014fc4; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap .b-sch-event {
  background: #3183fe;
  border-top: 1px solid #0155d3;
  border-bottom: 1px solid #0155d3; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap .b-sch-event :before {
    border-color: #0155d3;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap .b-sch-event :after {
    border-color: #0155d3;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #0155d3; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-blue.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #014fc4; }

.b-sch-style-border.b-sch-color-cyan .b-sch-event {
  border-color: #01bed3; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event {
  background-color: #31e9fe; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected {
    background-color: #01bed3; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover {
    background-color: #01b0c4; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-cyan.b-dragging .b-sch-event {
  background-color: #01b0c4; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-cyan .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #31e9fe; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-cyan .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-cyan .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #01bed3; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-cyan .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #01b0c4; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap .b-sch-event {
  background: #31e9fe;
  border-top: 1px solid #01bed3;
  border-bottom: 1px solid #01bed3; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap .b-sch-event :before {
    border-color: #01bed3;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap .b-sch-event :after {
    border-color: #01bed3;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #01bed3; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-cyan.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #01b0c4; }

.b-sch-style-border.b-sch-color-teal .b-sch-event {
  border-color: #01d37f; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event {
  background-color: #31feac; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected {
    background-color: #01d37f; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected:hover {
    background-color: #01c476; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-teal.b-dragging .b-sch-event {
  background-color: #01c476; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-teal .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #31feac; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-teal .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-teal .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #01d37f; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-teal .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #01c476; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap .b-sch-event {
  background: #31feac;
  border-top: 1px solid #01d37f;
  border-bottom: 1px solid #01d37f; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap .b-sch-event :before {
    border-color: #01d37f;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap .b-sch-event :after {
    border-color: #01d37f;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #01d37f; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-teal.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #01c476; }

.b-sch-style-border.b-sch-color-green .b-sch-event {
  border-color: #1b8d27; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-green .b-sch-event {
  background-color: #27ca37; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-green .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected {
    background-color: #1b8d27; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected:hover {
    background-color: #198324; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-green.b-dragging .b-sch-event {
  background-color: #198324; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-green .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #27ca37; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-green .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-green .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #1b8d27; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-green .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #198324; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap .b-sch-event {
  background: #27ca37;
  border-top: 1px solid #1b8d27;
  border-bottom: 1px solid #1b8d27; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap .b-sch-event :before {
    border-color: #1b8d27;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap .b-sch-event :after {
    border-color: #1b8d27;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #1b8d27; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-green.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #198324; }

.b-sch-style-border.b-sch-color-lime .b-sch-event {
  border-color: #50ab14; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event {
  background-color: #76e62c; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected {
    background-color: #50ab14; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected:hover {
    background-color: #4b9f13; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-lime.b-dragging .b-sch-event {
  background-color: #4b9f13; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-lime .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #76e62c; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-lime .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-lime .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #50ab14; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-lime .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #4b9f13; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap .b-sch-event {
  background: #76e62c;
  border-top: 1px solid #50ab14;
  border-bottom: 1px solid #50ab14; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap .b-sch-event :before {
    border-color: #50ab14;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap .b-sch-event :after {
    border-color: #50ab14;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #50ab14; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-lime.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #4b9f13; }

.b-sch-style-border.b-sch-color-yellow .b-sch-event {
  border-color: #dcc500; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event {
  background-color: #FFEB3B; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected {
    background-color: #dcc500; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover {
    background-color: #ccb700; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-yellow.b-dragging .b-sch-event {
  background-color: #ccb700; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-yellow .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #FFEB3B; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-yellow .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-yellow .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #dcc500; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-yellow .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #ccb700; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap .b-sch-event {
  background: #FFEB3B;
  border-top: 1px solid #dcc500;
  border-bottom: 1px solid #dcc500; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap .b-sch-event :before {
    border-color: #dcc500;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap .b-sch-event :after {
    border-color: #dcc500;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #dcc500; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-yellow.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #ccb700; }

.b-sch-style-border.b-sch-color-orange .b-sch-event {
  border-color: #d37f01; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event {
  background-color: #feac31; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected {
    background-color: #d37f01; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected:hover {
    background-color: #c47601; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-orange.b-dragging .b-sch-event {
  background-color: #c47601; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-orange .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #feac31; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-orange .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-orange .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #d37f01; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-orange .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #c47601; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap .b-sch-event {
  background: #feac31;
  border-top: 1px solid #d37f01;
  border-bottom: 1px solid #d37f01; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap .b-sch-event :before {
    border-color: #d37f01;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap .b-sch-event :after {
    border-color: #d37f01;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #d37f01; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-orange.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #c47601; }

.b-sch-style-border.b-sch-color-gray .b-sch-event {
  border-color: #707070; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event {
  background-color: #a0a0a0; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event:hover, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected {
    background-color: #707070; }
  .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-resizing, .b-sch-style-border:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected:hover {
    background-color: #686868; }

.b-sch-style-border:not(.b-milestone-wrap).b-sch-color-gray.b-dragging .b-sch-event {
  background-color: #686868; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-gray .b-sch-event:not(.b-sch-event-withicon):before {
  background-color: #a0a0a0; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-gray .b-sch-event:not(.b-sch-event-withicon):hover:before, .b-sch-style-border.b-milestone-wrap.b-sch-color-gray .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:before {
  background-color: #707070; }

.b-sch-style-border.b-milestone-wrap.b-sch-color-gray .b-sch-event:not(.b-sch-event-withicon).b-sch-event-selected:hover:before {
  background-color: #686868; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap .b-sch-event {
  background: #a0a0a0;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap .b-sch-event :before, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap .b-sch-event :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap .b-sch-event :before {
    border-color: #707070;
    border-width: 0 0 1px 1px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap .b-sch-event :after {
    border-color: #707070;
    border-width: 0 1px 1px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap:hover .b-sch-event, .b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap.b-sch-event-selected .b-sch-event {
  background: #707070; }

.b-sch-layout-milestones .b-sch-style-border.b-sch-color-gray.b-milestone-wrap.b-sch-event-selected:hover .b-sch-event {
  background: #686868; }

.b-sch-style-hollow.b-sch-event-wrap .b-sch-event {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: #27ca37;
  border-style: solid;
  border-width: 0;
  font-weight: 600;
  opacity: .8;
  border-radius: 3px; }
  .b-sch-style-hollow.b-sch-event-wrap .b-sch-event:not(.b-milestone) {
    color: #27ca37;
    font-size: 11px;
    border-width: 2px; }
    .b-sch-style-hollow.b-sch-event-wrap .b-sch-event:not(.b-milestone).b-sch-event-startsoutside {
      border-left: none; }
    .b-sch-style-hollow.b-sch-event-wrap .b-sch-event:not(.b-milestone):hover, .b-sch-style-hollow.b-sch-event-wrap .b-sch-event:not(.b-milestone).b-sch-event-selected {
      color: #fff;
      background-image: none; }
  .b-sch-style-hollow.b-sch-event-wrap .b-sch-event.b-milestone:before {
    background-color: rgba(255, 255, 255, 0.5);
    border-width: 2px;
    border-radius: 3px;
    opacity: .8; }
  .b-sch-style-hollow.b-sch-event-wrap .b-sch-event.b-milestone:hover, .b-sch-style-hollow.b-sch-event-wrap .b-sch-event.b-milestone.b-sch-event-selected {
    background-color: #27ca37;
    opacity: 1;
    background-image: none; }
  .b-sch-style-hollow.b-sch-event-wrap .b-sch-event.b-milestone.b-sch-event-selected {
    font-weight: 600; }
  .b-sch-style-hollow.b-sch-event-wrap .b-sch-event.b-milestone.b-sch-event-selected:hover {
    background-color: #23b632;
    background-image: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-red .b-sch-event {
  border-color: #e53f2c;
  color: #e53f2c; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-red .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-resizing {
  background-color: #e53f2c;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected:hover {
  background-color: #db2f1b; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-red.b-dragging .b-sch-event {
  background-color: #e53f2c;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-red .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-red .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-red .b-sch-event.b-dragging:before {
  background-color: #e53f2c; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-red .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #db2f1b; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone > * {
  background: none;
  color: #e53f2c;
  border-top: 2px solid #e53f2c;
  border-bottom: 2px solid #e53f2c;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone > * :before {
    border-color: #e53f2c;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone > * :after {
    border-color: #e53f2c;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-pink .b-sch-event {
  border-color: #fe3183;
  color: #fe3183; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-resizing {
  background-color: #fe3183;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected:hover {
  background-color: #fe1371; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-pink.b-dragging .b-sch-event {
  background-color: #fe3183;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-pink .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-pink .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-pink .b-sch-event.b-dragging:before {
  background-color: #fe3183; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-pink .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #fe1371; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone > * {
  background: none;
  color: #fe3183;
  border-top: 2px solid #fe3183;
  border-bottom: 2px solid #fe3183;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone > * :before {
    border-color: #fe3183;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone > * :after {
    border-color: #fe3183;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-purple .b-sch-event {
  border-color: #fe31ea;
  color: #fe31ea; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-resizing {
  background-color: #fe31ea;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected:hover {
  background-color: #fe13e7; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-purple.b-dragging .b-sch-event {
  background-color: #fe31ea;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-purple .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-purple .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-purple .b-sch-event.b-dragging:before {
  background-color: #fe31ea; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-purple .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #fe13e7; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone > * {
  background: none;
  color: #fe31ea;
  border-top: 2px solid #fe31ea;
  border-bottom: 2px solid #fe31ea;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone > * :before {
    border-color: #fe31ea;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone > * :after {
    border-color: #fe31ea;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-violet .b-sch-event {
  border-color: #ac31fe;
  color: #ac31fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-resizing {
  background-color: #ac31fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected:hover {
  background-color: #a013fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-violet.b-dragging .b-sch-event {
  background-color: #ac31fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-violet .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-violet .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-violet .b-sch-event.b-dragging:before {
  background-color: #ac31fe; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-violet .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #a013fe; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone > * {
  background: none;
  color: #ac31fe;
  border-top: 2px solid #ac31fe;
  border-bottom: 2px solid #ac31fe;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone > * :before {
    border-color: #ac31fe;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone > * :after {
    border-color: #ac31fe;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-indigo .b-sch-event {
  border-color: #4531fe;
  color: #4531fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-resizing {
  background-color: #4531fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover {
  background-color: #2a13fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-indigo.b-dragging .b-sch-event {
  background-color: #4531fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-indigo .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-indigo .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-indigo .b-sch-event.b-dragging:before {
  background-color: #4531fe; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #2a13fe; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone > * {
  background: none;
  color: #4531fe;
  border-top: 2px solid #4531fe;
  border-bottom: 2px solid #4531fe;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone > * :before {
    border-color: #4531fe;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone > * :after {
    border-color: #4531fe;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-blue .b-sch-event {
  border-color: #3183fe;
  color: #3183fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-resizing {
  background-color: #3183fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected:hover {
  background-color: #1371fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-blue.b-dragging .b-sch-event {
  background-color: #3183fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-blue .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-blue .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-blue .b-sch-event.b-dragging:before {
  background-color: #3183fe; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-blue .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #1371fe; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone > * {
  background: none;
  color: #3183fe;
  border-top: 2px solid #3183fe;
  border-bottom: 2px solid #3183fe;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone > * :before {
    border-color: #3183fe;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone > * :after {
    border-color: #3183fe;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-cyan .b-sch-event {
  border-color: #31e9fe;
  color: #31e9fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-resizing {
  background-color: #31e9fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover {
  background-color: #13e6fe; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-cyan.b-dragging .b-sch-event {
  background-color: #31e9fe;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-cyan .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-cyan .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-cyan .b-sch-event.b-dragging:before {
  background-color: #31e9fe; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #13e6fe; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone > * {
  background: none;
  color: #31e9fe;
  border-top: 2px solid #31e9fe;
  border-bottom: 2px solid #31e9fe;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone > * :before {
    border-color: #31e9fe;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone > * :after {
    border-color: #31e9fe;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-teal .b-sch-event {
  border-color: #31feac;
  color: #31feac; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-resizing {
  background-color: #31feac;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected:hover {
  background-color: #13fea0; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-teal.b-dragging .b-sch-event {
  background-color: #31feac;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-teal .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-teal .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-teal .b-sch-event.b-dragging:before {
  background-color: #31feac; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-teal .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #13fea0; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone > * {
  background: none;
  color: #31feac;
  border-top: 2px solid #31feac;
  border-bottom: 2px solid #31feac;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone > * :before {
    border-color: #31feac;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone > * :after {
    border-color: #31feac;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-green .b-sch-event {
  border-color: #27ca37;
  color: #27ca37; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-green .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-resizing {
  background-color: #27ca37;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected:hover {
  background-color: #23b632; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-green.b-dragging .b-sch-event {
  background-color: #27ca37;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-green .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-green .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-green .b-sch-event.b-dragging:before {
  background-color: #27ca37; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-green .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #23b632; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone > * {
  background: none;
  color: #27ca37;
  border-top: 2px solid #27ca37;
  border-bottom: 2px solid #27ca37;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone > * :before {
    border-color: #27ca37;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone > * :after {
    border-color: #27ca37;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-lime .b-sch-event {
  border-color: #76e62c;
  color: #76e62c; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-resizing {
  background-color: #76e62c;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected:hover {
  background-color: #67dc1a; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-lime.b-dragging .b-sch-event {
  background-color: #76e62c;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-lime .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-lime .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-lime .b-sch-event.b-dragging:before {
  background-color: #76e62c; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-lime .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #67dc1a; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone > * {
  background: none;
  color: #76e62c;
  border-top: 2px solid #76e62c;
  border-bottom: 2px solid #76e62c;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone > * :before {
    border-color: #76e62c;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone > * :after {
    border-color: #76e62c;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-yellow .b-sch-event {
  border-color: #FFEB3B;
  color: #FFEB3B; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-resizing {
  background-color: #FFEB3B;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover {
  background-color: #ffe81c; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-yellow.b-dragging .b-sch-event {
  background-color: #FFEB3B;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-yellow .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-yellow .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-yellow .b-sch-event.b-dragging:before {
  background-color: #FFEB3B; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #ffe81c; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone > * {
  background: none;
  color: #FFEB3B;
  border-top: 2px solid #FFEB3B;
  border-bottom: 2px solid #FFEB3B;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone > * :before {
    border-color: #FFEB3B;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone > * :after {
    border-color: #FFEB3B;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-orange .b-sch-event {
  border-color: #feac31;
  color: #feac31; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-resizing {
  background-color: #feac31;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected:hover {
  background-color: #fea013; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-orange.b-dragging .b-sch-event {
  background-color: #feac31;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-orange .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-orange .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-orange .b-sch-event.b-dragging:before {
  background-color: #feac31; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-orange .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #fea013; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone > * {
  background: none;
  color: #feac31;
  border-top: 2px solid #feac31;
  border-bottom: 2px solid #feac31;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone > * :before {
    border-color: #feac31;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone > * :after {
    border-color: #feac31;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-hollow.b-sch-event-wrap.b-sch-color-gray .b-sch-event {
  border-color: #a0a0a0;
  color: #a0a0a0; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event:hover, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected, .b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-resizing {
  background-color: #a0a0a0;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected:hover {
  background-color: #909090; }

.b-sch-style-hollow.b-sch-event-wrap:not(.b-milestone-wrap).b-sch-color-gray.b-dragging .b-sch-event {
  background-color: #a0a0a0;
  color: #fff; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-gray .b-sch-event:hover:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-gray .b-sch-event.b-sch-event-selected:before, .b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-gray .b-sch-event.b-dragging:before {
  background-color: #a0a0a0; }

.b-sch-style-hollow.b-sch-event-wrap.b-milestone-wrap.b-sch-color-gray .b-sch-event.b-sch-event-selected:hover:before {
  background-color: #909090; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone > * {
  background: none;
  color: #a0a0a0;
  border-top: 2px solid #a0a0a0;
  border-bottom: 2px solid #a0a0a0;
  font-size: 11px;
  font-weight: 600;
  width: 100%; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone > * :before,
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone > * :after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone > * :before {
    border-color: #a0a0a0;
    border-width: 0 0 2px 2px;
    border-style: solid; }
  .b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone > * :after {
    border-color: #a0a0a0;
    border-width: 0 2px 2px 0;
    border-style: solid; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone:hover > * {
  background: none; }

.b-sch-layout-milestones .b-sch-style-hollow.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  background: none; }

.b-sch-style-colored.b-sch-event-wrap .b-sch-event {
  background-color: #d2f6d5;
  border-color: #23b632;
  border-left-width: 3px;
  border-left-style: solid;
  font-weight: 500;
  opacity: .8; }
  .b-sch-style-colored.b-sch-event-wrap .b-sch-event:not(.b-milestone) {
    color: #1b8d27; }
  .b-sch-style-colored.b-sch-event-wrap .b-sch-event:hover, .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-resizing, .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-selected {
    background-color: #27ca37;
    background-image: none;
    opacity: 1; }
    .b-sch-style-colored.b-sch-event-wrap .b-sch-event:hover:not(.b-milestone), .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-selected {
    font-weight: 500;
    opacity: 1; }
  .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-selected:hover {
    background-color: #1b8d27;
    background-image: none; }
  .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-sch-event-startsoutside {
    border-left: none; }
  .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone {
    border-width: 0; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap .b-sch-event,
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap::after {
    background: #d2f6d5; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap::after {
    height: 0.74em;
    width: 0.74em; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap::before {
    border-top: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 3px solid #23b632;
    border-left: 3px solid #23b632; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap:hover::after {
    background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-event-wrap .b-sch-event.b-milestone-wrap.b-sch-event-selected:hover::after {
    background: none; }
  .b-sch-vertical .b-sch-style-colored.b-sch-event-wrap .b-sch-event {
    border-top-width: 3px;
    border-top-style: solid;
    border-left-width: 0;
    border-left-style: none; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event {
  background-color: #fad9d5;
  border-color: #db2f1b; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event:not(.b-milestone) {
    color: #aa2415; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event:hover {
    background-color: #f5b2ab; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected {
    background-color: #e53f2c; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-red .b-sch-event.b-sch-event-selected:hover {
    background-color: #db2f1b; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-red:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-red:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #fad9d5;
    border: 3px solid #db2f1b; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-red:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #f5b2ab; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-red:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #e53f2c; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-red:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #db2f1b; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone::after {
  background: #fad9d5; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone > * {
  width: 100%;
  color: #aa2415;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone::before {
  border-bottom-color: #db2f1b;
  border-left-color: #db2f1b; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone:hover::after {
  background: #f5b2ab; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #e53f2c; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-red.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #db2f1b; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event {
  background-color: #ffd6e6;
  border-color: #fe1371; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event:not(.b-milestone) {
    color: #d30155; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event:hover {
    background-color: #ffadcd; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected {
    background-color: #fe3183; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-pink .b-sch-event.b-sch-event-selected:hover {
    background-color: #fe1371; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-pink:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-pink:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #ffd6e6;
    border: 3px solid #fe1371; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-pink:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #ffadcd; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-pink:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #fe3183; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-pink:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #fe1371; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone::after {
  background: #ffd6e6; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone > * {
  width: 100%;
  color: #d30155;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone::before {
  border-bottom-color: #fe1371;
  border-left-color: #fe1371; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone:hover::after {
  background: #ffadcd; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #fe3183; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-pink.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #fe1371; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event {
  background-color: #ffd6fb;
  border-color: #fe13e7; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event:not(.b-milestone) {
    color: #d301bf; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event:hover {
    background-color: #ffadf7; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected {
    background-color: #fe31ea; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-purple .b-sch-event.b-sch-event-selected:hover {
    background-color: #fe13e7; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-purple:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-purple:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #ffd6fb;
    border: 3px solid #fe13e7; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-purple:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #ffadf7; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-purple:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #fe31ea; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-purple:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #fe13e7; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone::after {
  background: #ffd6fb; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone > * {
  width: 100%;
  color: #d301bf;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone::before {
  border-bottom-color: #fe13e7;
  border-left-color: #fe13e7; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone:hover::after {
  background: #ffadf7; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #fe31ea; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-purple.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #fe13e7; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event {
  background-color: #eed6ff;
  border-color: #a013fe; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event:not(.b-milestone) {
    color: #7f01d3; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event:hover {
    background-color: #deadff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected {
    background-color: #ac31fe; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-violet .b-sch-event.b-sch-event-selected:hover {
    background-color: #a013fe; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-violet:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-violet:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #eed6ff;
    border: 3px solid #a013fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-violet:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #deadff; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-violet:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #ac31fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-violet:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #a013fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone::after {
  background: #eed6ff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone > * {
  width: 100%;
  color: #7f01d3;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone::before {
  border-bottom-color: #a013fe;
  border-left-color: #a013fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone:hover::after {
  background: #deadff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #ac31fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-violet.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #a013fe; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event {
  background-color: #dad6ff;
  border-color: #2a13fe; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event:not(.b-milestone) {
    color: #1601d3; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event:hover {
    background-color: #b5adff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected {
    background-color: #4531fe; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover {
    background-color: #2a13fe; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-indigo:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-indigo:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #dad6ff;
    border: 3px solid #2a13fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-indigo:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #b5adff; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-indigo:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #4531fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-indigo:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #2a13fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone::after {
  background: #dad6ff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone > * {
  width: 100%;
  color: #1601d3;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone::before {
  border-bottom-color: #2a13fe;
  border-left-color: #2a13fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone:hover::after {
  background: #b5adff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #4531fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-indigo.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #2a13fe; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event {
  background-color: #d6e6ff;
  border-color: #1371fe; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event:not(.b-milestone) {
    color: #0155d3; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event:hover {
    background-color: #adcdff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected {
    background-color: #3183fe; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-blue .b-sch-event.b-sch-event-selected:hover {
    background-color: #1371fe; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-blue:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-blue:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #d6e6ff;
    border: 3px solid #1371fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-blue:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #adcdff; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-blue:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #3183fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-blue:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #1371fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone::after {
  background: #d6e6ff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone > * {
  width: 100%;
  color: #0155d3;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone::before {
  border-bottom-color: #1371fe;
  border-left-color: #1371fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone:hover::after {
  background: #adcdff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #3183fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-blue.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #1371fe; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event {
  background-color: #d6fbff;
  border-color: #13e6fe; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event:not(.b-milestone) {
    color: #01bed3; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event:hover {
    background-color: #adf6ff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected {
    background-color: #31e9fe; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover {
    background-color: #13e6fe; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-cyan:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-cyan:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #d6fbff;
    border: 3px solid #13e6fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-cyan:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #adf6ff; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-cyan:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #31e9fe; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-cyan:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #13e6fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone::after {
  background: #d6fbff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone > * {
  width: 100%;
  color: #01bed3;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone::before {
  border-bottom-color: #13e6fe;
  border-left-color: #13e6fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone:hover::after {
  background: #adf6ff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #31e9fe; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-cyan.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #13e6fe; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event {
  background-color: #d6ffee;
  border-color: #13fea0; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event:not(.b-milestone) {
    color: #01d37f; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event:hover {
    background-color: #adffde; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected {
    background-color: #31feac; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-teal .b-sch-event.b-sch-event-selected:hover {
    background-color: #13fea0; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-teal:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-teal:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #d6ffee;
    border: 3px solid #13fea0; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-teal:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #adffde; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-teal:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #31feac; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-teal:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #13fea0; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone::after {
  background: #d6ffee; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone > * {
  width: 100%;
  color: #01d37f;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone::before {
  border-bottom-color: #13fea0;
  border-left-color: #13fea0; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone:hover::after {
  background: #adffde; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #31feac; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-teal.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #13fea0; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event {
  background-color: #d2f6d5;
  border-color: #23b632; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event:not(.b-milestone) {
    color: #1b8d27; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event:hover {
    background-color: #a5eeac; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected {
    background-color: #27ca37; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-green .b-sch-event.b-sch-event-selected:hover {
    background-color: #23b632; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-green:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-green:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #d2f6d5;
    border: 3px solid #23b632; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-green:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #a5eeac; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-green:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #27ca37; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-green:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #23b632; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone::after {
  background: #d2f6d5; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone > * {
  width: 100%;
  color: #1b8d27;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone::before {
  border-bottom-color: #23b632;
  border-left-color: #23b632; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone:hover::after {
  background: #a5eeac; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #27ca37; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-green.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #23b632; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event {
  background-color: #e4fad5;
  border-color: #67dc1a; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event:not(.b-milestone) {
    color: #50ab14; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event:hover {
    background-color: #c8f5ab; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected {
    background-color: #76e62c; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-lime .b-sch-event.b-sch-event-selected:hover {
    background-color: #67dc1a; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-lime:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-lime:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #e4fad5;
    border: 3px solid #67dc1a; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-lime:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #c8f5ab; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-lime:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #76e62c; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-lime:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #67dc1a; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone::after {
  background: #e4fad5; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone > * {
  width: 100%;
  color: #50ab14;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone::before {
  border-bottom-color: #67dc1a;
  border-left-color: #67dc1a; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone:hover::after {
  background: #c8f5ab; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #76e62c; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-lime.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #67dc1a; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event {
  background-color: #fffbd8;
  border-color: #ffe81c; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:not(.b-milestone) {
    color: #dcc500; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event:hover {
    background-color: #fff7b1; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected {
    background-color: #FFEB3B; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover {
    background-color: #ffe81c; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-yellow:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-yellow:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #fffbd8;
    border: 3px solid #ffe81c; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-yellow:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #fff7b1; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-yellow:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #FFEB3B; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-yellow:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #ffe81c; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone::after {
  background: #fffbd8; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone > * {
  width: 100%;
  color: #dcc500;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone::before {
  border-bottom-color: #ffe81c;
  border-left-color: #ffe81c; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone:hover::after {
  background: #fff7b1; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #FFEB3B; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-yellow.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #ffe81c; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event {
  background-color: #ffeed6;
  border-color: #fea013; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event:not(.b-milestone) {
    color: #d37f01; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event:hover {
    background-color: navajowhite; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected {
    background-color: #feac31; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-orange .b-sch-event.b-sch-event-selected:hover {
    background-color: #fea013; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-orange:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-orange:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #ffeed6;
    border: 3px solid #fea013; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-orange:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: navajowhite; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-orange:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #feac31; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-orange:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #fea013; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone::after {
  background: #ffeed6; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone > * {
  width: 100%;
  color: #d37f01;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone::before {
  border-bottom-color: #fea013;
  border-left-color: #fea013; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone:hover::after {
  background: navajowhite; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #feac31; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-orange.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #fea013; }

.b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event {
  background-color: #ececec;
  border-color: #909090; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event:not(.b-milestone) {
    color: #707070; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event:hover {
    background-color: #d9d9d9; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-resizing, .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected {
    background-color: #a0a0a0; }
    .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-resizing:not(.b-milestone), .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected:not(.b-milestone) {
      color: #fff; }
  .b-sch-style-colored:not(.b-milestone-wrap).b-sch-color-gray .b-sch-event.b-sch-event-selected:hover {
    background-color: #909090; }

.b-sch-style-colored.b-milestone-wrap.b-sch-color-gray:not(.b-sch-event-withicon) .b-sch-event {
  background: none; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-gray:not(.b-sch-event-withicon) .b-sch-event:before {
    background-color: #ececec;
    border: 3px solid #909090; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-gray:not(.b-sch-event-withicon) .b-sch-event:hover:before {
    background-color: #d9d9d9; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-gray:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:before {
    background-color: #a0a0a0; }
  .b-sch-style-colored.b-milestone-wrap.b-sch-color-gray:not(.b-sch-event-withicon) .b-sch-event.b-sch-event-selected:hover:before {
    background-color: #909090; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone .b-sch-event,
.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone .b-gantt-task, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone::after {
  background: #ececec; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone > * {
  width: 100%;
  color: #707070;
  font-weight: 500; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone::before {
  border-bottom-color: #909090;
  border-left-color: #909090; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone:hover::after {
  background: #d9d9d9; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected {
  background: none; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected > * {
    color: #fff; }
  .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected::after {
    background: #a0a0a0; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected:hover > * {
  color: #fff; }

.b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected:hover > *, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected:hover::before, .b-sch-layout-milestones .b-sch-style-colored.b-sch-color-gray.b-sch-event.b-milestone.b-sch-event-selected:hover::after {
  background: #909090; }

.b-sch-event-wrap.b-sch-style-line .b-sch-event {
  background-color: transparent;
  border-color: #27ca37;
  border-width: 5px 0 0 0;
  border-style: solid;
  padding: 5px 0 0 0;
  color: #777;
  font-weight: 500;
  justify-content: center;
  overflow: visible;
  top: 5px;
  height: auto !important; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-active {
    outline-offset: 5px; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event:not(.b-milestone)::before, .b-sch-event-wrap.b-sch-style-line .b-sch-event:not(.b-milestone)::after {
    content: '';
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    height: 13px;
    position: absolute;
    top: -9px;
    border-radius: 2px; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event:not(.b-milestone)::before {
    border-left-width: 5px;
    left: 0; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event:not(.b-milestone)::after {
    border-right-width: 5px;
    right: 0; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-milestone {
    top: initial;
    border-width: 0; }
    .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-milestone:before {
      background-color: transparent;
      border-width: 5px;
      border-style: solid; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-sch-event-selected, .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-sch-event-resizing {
    background-color: transparent;
    border-color: #1d9829;
    background-image: none; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-sch-event-selected {
    font-weight: 500; }
  .b-sch-event-wrap.b-sch-style-line .b-sch-event.b-sch-event-selected:hover {
    background-color: transparent;
    border-color: #1b8d27;
    background-image: none; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-red .b-sch-event {
  border-color: #e53f2c;
  color: #e53f2c; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-red .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-red .b-sch-event.b-sch-event-selected {
    border-color: #b62716; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-red .b-sch-event.b-sch-event-selected:hover {
    border-color: #aa2415; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-pink .b-sch-event {
  border-color: #fe3183;
  color: #fe3183; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-pink .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-pink .b-sch-event.b-sch-event-selected {
    border-color: #e2015b; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-pink .b-sch-event.b-sch-event-selected:hover {
    border-color: #d30155; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-purple .b-sch-event {
  border-color: #fe31ea;
  color: #fe31ea; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-purple .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-purple .b-sch-event.b-sch-event-selected {
    border-color: #e201cc; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-purple .b-sch-event.b-sch-event-selected:hover {
    border-color: #d301bf; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-violet .b-sch-event {
  border-color: #ac31fe;
  color: #ac31fe; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-violet .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-violet .b-sch-event.b-sch-event-selected {
    border-color: #8801e2; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-violet .b-sch-event.b-sch-event-selected:hover {
    border-color: #7f01d3; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-indigo .b-sch-event {
  border-color: #4531fe;
  color: #4531fe; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-indigo .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-indigo .b-sch-event.b-sch-event-selected {
    border-color: #1701e2; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover {
    border-color: #1601d3; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-blue .b-sch-event {
  border-color: #3183fe;
  color: #3183fe; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-blue .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-blue .b-sch-event.b-sch-event-selected {
    border-color: #015be2; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-blue .b-sch-event.b-sch-event-selected:hover {
    border-color: #0155d3; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-cyan .b-sch-event {
  border-color: #31e9fe;
  color: #31e9fe; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-cyan .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-cyan .b-sch-event.b-sch-event-selected {
    border-color: #01cbe2; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover {
    border-color: #01bed3; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-teal .b-sch-event {
  border-color: #31feac;
  color: #31feac; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-teal .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-teal .b-sch-event.b-sch-event-selected {
    border-color: #01e288; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-teal .b-sch-event.b-sch-event-selected:hover {
    border-color: #01d37f; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-green .b-sch-event {
  border-color: #27ca37;
  color: #27ca37; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-green .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-green .b-sch-event.b-sch-event-selected {
    border-color: #1d9829; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-green .b-sch-event.b-sch-event-selected:hover {
    border-color: #1b8d27; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-lime .b-sch-event {
  border-color: #76e62c;
  color: #76e62c; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-lime .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-lime .b-sch-event.b-sch-event-selected {
    border-color: #56b816; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-lime .b-sch-event.b-sch-event-selected:hover {
    border-color: #50ab14; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-yellow .b-sch-event {
  border-color: #FFEB3B;
  color: #FFEB3B; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-yellow .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-yellow .b-sch-event.b-sch-event-selected {
    border-color: #ecd300; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover {
    border-color: #dcc500; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-orange .b-sch-event {
  border-color: #feac31;
  color: #feac31; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-orange .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-orange .b-sch-event.b-sch-event-selected {
    border-color: #e28801; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-orange .b-sch-event.b-sch-event-selected:hover {
    border-color: #d37f01; }

.b-sch-event-wrap.b-sch-style-line.b-sch-color-gray .b-sch-event {
  border-color: #a0a0a0;
  color: #a0a0a0; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-gray .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-line.b-sch-color-gray .b-sch-event.b-sch-event-selected {
    border-color: #787878; }
  .b-sch-event-wrap.b-sch-style-line.b-sch-color-gray .b-sch-event.b-sch-event-selected:hover {
    border-color: #707070; }

.b-sch-event-wrap.b-sch-style-dashed .b-sch-event {
  background-color: transparent;
  border-color: #27ca37;
  border-width: 5px 0 0 0;
  border-style: dashed;
  padding: 5px 0 3px 0;
  color: #777;
  font-weight: 500;
  justify-content: center;
  overflow: visible;
  top: 5px;
  height: auto !important; }
  .b-sch-event-wrap.b-sch-style-dashed .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed .b-sch-event.b-sch-event-selected, .b-sch-event-wrap.b-sch-style-dashed .b-sch-event.b-sch-event-resizing {
    background-color: transparent;
    border-color: #1d9829;
    background-image: none; }
  .b-sch-event-wrap.b-sch-style-dashed .b-sch-event.b-sch-event-selected {
    font-weight: 500; }
  .b-sch-event-wrap.b-sch-style-dashed .b-sch-event.b-sch-event-selected:hover {
    background-color: transparent;
    border-color: #1b8d27;
    background-image: none; }
  .b-sch-event-wrap.b-sch-style-dashed .b-sch-event.b-milestone {
    top: initial;
    border-width: 0; }
    .b-sch-event-wrap.b-sch-style-dashed .b-sch-event.b-milestone:before {
      background-color: transparent;
      border-width: 5px;
      border-style: dashed; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-red .b-sch-event {
  border-color: #e53f2c;
  color: #e53f2c; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-red .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-red .b-sch-event.b-sch-event-selected {
    border-color: #b62716; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-red .b-sch-event.b-sch-event-selected:hover {
    border-color: #aa2415; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-pink .b-sch-event {
  border-color: #fe3183;
  color: #fe3183; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-pink .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-pink .b-sch-event.b-sch-event-selected {
    border-color: #e2015b; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-pink .b-sch-event.b-sch-event-selected:hover {
    border-color: #d30155; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-purple .b-sch-event {
  border-color: #fe31ea;
  color: #fe31ea; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-purple .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-purple .b-sch-event.b-sch-event-selected {
    border-color: #e201cc; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-purple .b-sch-event.b-sch-event-selected:hover {
    border-color: #d301bf; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-violet .b-sch-event {
  border-color: #ac31fe;
  color: #ac31fe; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-violet .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-violet .b-sch-event.b-sch-event-selected {
    border-color: #8801e2; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-violet .b-sch-event.b-sch-event-selected:hover {
    border-color: #7f01d3; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-indigo .b-sch-event {
  border-color: #4531fe;
  color: #4531fe; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-indigo .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-indigo .b-sch-event.b-sch-event-selected {
    border-color: #1701e2; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover {
    border-color: #1601d3; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-blue .b-sch-event {
  border-color: #3183fe;
  color: #3183fe; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-blue .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-blue .b-sch-event.b-sch-event-selected {
    border-color: #015be2; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-blue .b-sch-event.b-sch-event-selected:hover {
    border-color: #0155d3; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-cyan .b-sch-event {
  border-color: #31e9fe;
  color: #31e9fe; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-cyan .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-cyan .b-sch-event.b-sch-event-selected {
    border-color: #01cbe2; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover {
    border-color: #01bed3; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-teal .b-sch-event {
  border-color: #31feac;
  color: #31feac; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-teal .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-teal .b-sch-event.b-sch-event-selected {
    border-color: #01e288; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-teal .b-sch-event.b-sch-event-selected:hover {
    border-color: #01d37f; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-green .b-sch-event {
  border-color: #27ca37;
  color: #27ca37; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-green .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-green .b-sch-event.b-sch-event-selected {
    border-color: #1d9829; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-green .b-sch-event.b-sch-event-selected:hover {
    border-color: #1b8d27; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-lime .b-sch-event {
  border-color: #76e62c;
  color: #76e62c; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-lime .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-lime .b-sch-event.b-sch-event-selected {
    border-color: #56b816; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-lime .b-sch-event.b-sch-event-selected:hover {
    border-color: #50ab14; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-yellow .b-sch-event {
  border-color: #FFEB3B;
  color: #FFEB3B; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-yellow .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-yellow .b-sch-event.b-sch-event-selected {
    border-color: #ecd300; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover {
    border-color: #dcc500; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-orange .b-sch-event {
  border-color: #feac31;
  color: #feac31; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-orange .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-orange .b-sch-event.b-sch-event-selected {
    border-color: #e28801; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-orange .b-sch-event.b-sch-event-selected:hover {
    border-color: #d37f01; }

.b-sch-event-wrap.b-sch-style-dashed.b-sch-color-gray .b-sch-event {
  border-color: #a0a0a0;
  color: #a0a0a0; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-gray .b-sch-event:hover, .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-gray .b-sch-event.b-sch-event-selected {
    border-color: #787878; }
  .b-sch-event-wrap.b-sch-style-dashed.b-sch-color-gray .b-sch-event.b-sch-event-selected:hover {
    border-color: #707070; }

.b-sch-style-minimal:not(.b-milestone) .b-sch-event {
  background-color: transparent;
  border-color: #27ca37;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 0;
  color: #777;
  font-weight: 500;
  overflow: visible;
  font-size: 10px;
  align-items: flex-end; }
  .b-sch-style-minimal:not(.b-milestone) .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-sch-event-selected, .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-sch-event-resizing {
    background-color: transparent;
    border-color: #1d9829;
    background-image: none; }
  .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-sch-event-selected {
    font-weight: 500; }
  .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-sch-event-selected:hover {
    background-color: transparent;
    border-color: #1b8d27;
    background-image: none; }
  .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-milestone {
    border-width: 0; }
    .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-milestone:before {
      border-width: 1px;
      border-style: solid; }
    .b-sch-style-minimal:not(.b-milestone) .b-sch-event.b-milestone label {
      font-size: 10px; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-red .b-sch-event {
  border-color: #e53f2c; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-red .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-red .b-sch-event.b-sch-event-selected {
    border-color: #b62716; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-red .b-sch-event.b-sch-event-selected:hover {
    border-color: #aa2415; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-pink .b-sch-event {
  border-color: #fe3183; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-pink .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-pink .b-sch-event.b-sch-event-selected {
    border-color: #e2015b; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-pink .b-sch-event.b-sch-event-selected:hover {
    border-color: #d30155; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-purple .b-sch-event {
  border-color: #fe31ea; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-purple .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-purple .b-sch-event.b-sch-event-selected {
    border-color: #e201cc; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-purple .b-sch-event.b-sch-event-selected:hover {
    border-color: #d301bf; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-violet .b-sch-event {
  border-color: #ac31fe; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-violet .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-violet .b-sch-event.b-sch-event-selected {
    border-color: #8801e2; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-violet .b-sch-event.b-sch-event-selected:hover {
    border-color: #7f01d3; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-indigo .b-sch-event {
  border-color: #4531fe; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-indigo .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-indigo .b-sch-event.b-sch-event-selected {
    border-color: #1701e2; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-indigo .b-sch-event.b-sch-event-selected:hover {
    border-color: #1601d3; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-blue .b-sch-event {
  border-color: #3183fe; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-blue .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-blue .b-sch-event.b-sch-event-selected {
    border-color: #015be2; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-blue .b-sch-event.b-sch-event-selected:hover {
    border-color: #0155d3; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-cyan .b-sch-event {
  border-color: #31e9fe; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-cyan .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-cyan .b-sch-event.b-sch-event-selected {
    border-color: #01cbe2; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-cyan .b-sch-event.b-sch-event-selected:hover {
    border-color: #01bed3; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-teal .b-sch-event {
  border-color: #31feac; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-teal .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-teal .b-sch-event.b-sch-event-selected {
    border-color: #01e288; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-teal .b-sch-event.b-sch-event-selected:hover {
    border-color: #01d37f; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-green .b-sch-event {
  border-color: #27ca37; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-green .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-green .b-sch-event.b-sch-event-selected {
    border-color: #1d9829; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-green .b-sch-event.b-sch-event-selected:hover {
    border-color: #1b8d27; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-lime .b-sch-event {
  border-color: #76e62c; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-lime .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-lime .b-sch-event.b-sch-event-selected {
    border-color: #56b816; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-lime .b-sch-event.b-sch-event-selected:hover {
    border-color: #50ab14; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-yellow .b-sch-event {
  border-color: #FFEB3B; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-yellow .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-yellow .b-sch-event.b-sch-event-selected {
    border-color: #ecd300; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-yellow .b-sch-event.b-sch-event-selected:hover {
    border-color: #dcc500; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-orange .b-sch-event {
  border-color: #feac31; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-orange .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-orange .b-sch-event.b-sch-event-selected {
    border-color: #e28801; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-orange .b-sch-event.b-sch-event-selected:hover {
    border-color: #d37f01; }

.b-sch-style-minimal:not(.b-milestone).b-sch-color-gray .b-sch-event {
  border-color: #a0a0a0; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-gray .b-sch-event:hover, .b-sch-style-minimal:not(.b-milestone).b-sch-color-gray .b-sch-event.b-sch-event-selected {
    border-color: #787878; }
  .b-sch-style-minimal:not(.b-milestone).b-sch-color-gray .b-sch-event.b-sch-event-selected:hover {
    border-color: #707070; }
